import React from 'react'

import PT from 'prop-types'

import moment from 'moment'
import { get, isEmpty, upperCase, toLower } from 'lodash'

import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  DialogTitle,
  Typography,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'

import i18n from 'I18N'

import carriersData from 'Constants/carriers'

import { MediaPlayer } from '../MediaPlayer'

import { classes } from './styles'

const DeliveryDetailsModal = ({ isOpen, delivery, onClose }) => {
  if (!delivery) return null

  const {
    carrier,
    trackingCode,
    notes,
    delivered,
    createdBy,
    created,
  } = delivery
  const deliveredEvent = get(delivery, 'deliveredEvent', {})

  const isCarrierShown = carrier && toLower(carrier) !== 'unknown'
  const carrierName = carriersData[carrier]
    ? carriersData[carrier].title
    : upperCase(carrier)
  const isMediaUrl = deliveredEvent && !isEmpty(deliveredEvent.mediaUrls)

  const getDate = date => moment(new Date(date)).format('MMMM D, YYYY')
  const getTime = date => moment(new Date(date)).format('h:mm A')

  return (
    <Dialog aria-labelledby="form-dialog-title" open={isOpen} onClose={onClose}>
      <DialogTitle id="form-dialog-title">{notes || trackingCode}</DialogTitle>
      <DialogContent style={{ overflowX: 'auto' }}>
        <DialogContentText>
          {trackingCode}
          {isCarrierShown && <br />}
          {isCarrierShown && carrierName}
        </DialogContentText>
        {isMediaUrl && (
          <Box display="flex" alignItems="center" justifyContent="center">
            <MediaPlayer url={deliveredEvent.mediaUrls[0]} />
          </Box>
        )}
        <Typography variant="body2" color="textSecondary">
          {i18n.t('trackCodesScreen.details.statuses.taken', {
            date: moment(deliveredEvent.eventTime).format('M/D/YY'),
            time: moment(deliveredEvent.eventTime).format('h:mm A'),
          })}
        </Typography>
        <Box style={classes.sectionHeader}>
          {i18n.t('trackCodesScreen.details.history.sectionTitle')}
        </Box>
        <List>
          {deliveredEvent && (
            <ListItem>
              <ListItemText
                primary={getDate(delivered)}
                secondary={
                  <>
                    <Typography
                      component="span"
                      variant="body2"
                      color="textSecondary"
                    >
                      {i18n.t('trackCodesScreen.details.history.delivered', {
                        time: getTime(delivered),
                      })}
                    </Typography>
                  </>
                }
              />
            </ListItem>
          )}
          {createdBy !== 'server' && (
            <ListItem>
              <ListItemText
                primary={getDate(created)}
                secondary={
                  <>
                    <Typography
                      component="span"
                      variant="body2"
                      color="textSecondary"
                    >
                      {i18n.t('trackCodesScreen.details.history.added')}
                    </Typography>
                  </>
                }
              />
            </ListItem>
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {i18n.t('activityScreen.details.closeBtnLabel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DeliveryDetailsModal.propTypes = {
  isOpen: PT.bool.isRequired,
  delivery: PT.object,
  onClose: PT.func.isRequired,
}

export { DeliveryDetailsModal }
