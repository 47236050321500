import React from 'react'
import { connect } from 'react-redux'

import { useTheme } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Icon from '@mdi/react'
import IconButton from '@material-ui/core/IconButton'
import { mdiInbox, mdiLogoutVariant, mdiMenu } from '@mdi/js'

import { Link as RouterLink } from 'react-router-dom'

import { signOut } from 'Redux/actions/auth'

import * as routes from 'Constants/routes'

import { useStyles } from './styles'

function AppLayout(props) {
  const { container, children, onSignOut } = props

  const classes = useStyles()
  const theme = useTheme()

  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleLogoutClick = () => {
    onSignOut()
  }

  const drawer = (
    <div className={classes.drawerContainer}>
      <RouterLink className={classes.logoContainer} to={routes.home}>
        <img alt="" src="logo_full_white.svg" className={classes.logo_full} />
      </RouterLink>

      <List className={classes.listContainer}>
        <ListItem button component={RouterLink} to={routes.home}>
          <ListItemIcon>
            <Icon path={mdiInbox} size="24px" color="white" />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
      </List>

      <List className={classes.logoutContainer}>
        <ListItem
          button
          component={RouterLink}
          to={routes.login}
          onClick={handleLogoutClick}
        >
          <ListItemIcon>
            <Icon path={mdiLogoutVariant} size="24px" color="white" />
          </ListItemIcon>
          <ListItemText primary="Sign out" />
        </ListItem>
      </List>
    </div>
  )

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Hidden mdUp implementation="css">
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <Icon path={mdiMenu} size="1.5rem" />
            </IconButton>

            <RouterLink className={classes.smallLogoContainer} to={routes.home}>
              <img alt="" src="logo.svg" className={classes.logo} />
            </RouterLink>

            <Typography variant="h6" noWrap>
              BenchSentry
            </Typography>
          </Toolbar>
        </AppBar>
      </Hidden>

      <nav className={classes.drawer}>
        <Hidden mdUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>

      <main className={classes.content}>
        <Hidden mdUp implementation="css">
          <div className={classes.toolbar} />
        </Hidden>

        {children}
      </main>
    </div>
  )
}

const actions = {
  onSignOut: signOut,
}

export default connect(
  null,
  actions,
)(AppLayout)
