import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  sectionHeader: {
    fontSize: 18,
    color: '#363B46',
    borderBottom: '1px solid #D6D6D6',
    margin: '32px 0 16px',
    paddingBottom: 8,
  },
}))
