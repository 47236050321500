/* eslint-disable max-len */

import React from 'react'
import PT from 'prop-types'
import { Formik } from 'formik'

import {
  Box,
  Button,
  TextField,
  Grid,
  Typography,
  MenuItem,
} from '@material-ui/core'

import i18n from 'I18N'

import { validationSchema, yearOptions, monthOptions } from './config'
import { useStyles } from './styles'

const StepThree = ({ onSubmit, isRequest, preorderConfig }) => {
  const classes = useStyles()

  const handleConfirm = values => {
    const cardExpiryMonth =
      values.cardExpiryMonth < 10
        ? `0${values.cardExpiryMonth}`
        : values.cardExpiryMonth.toString()

    const cardExpiry = cardExpiryMonth + values.cardExpiryYear

    const cardData = {
      ...values,
      cardExpiry,
    }

    onSubmit(cardData)
    window.scrollTo(0, 0)
  }

  return (
    <Grid
      container
      display="flex"
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
    >
      <Grid item xs={9} md={5} lg={4} xl={3}>
        <Formik
          initialValues={{}}
          validationSchema={validationSchema}
          onSubmit={handleConfirm}
        >
          {({
            values = {},
            errors = {},
            touched = {},
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => {
            const handleInputKeyDown = e => {
              if (e.key === 'Enter') {
                handleSubmit()
              }
            }

            return (
              <>
                <>
                  <Typography variant="h6">
                    {i18n.t(
                      'preOrderScreen.sections.creditCardDetails.dueTodayLabel',
                      { sum: 0 },
                    )}
                  </Typography>
                  {i18n
                    .t('preOrderScreen.sections.creditCardDetails.infoLines')
                    .map(line => (
                      <Typography>{line}</Typography>
                    ))}
                  <Typography>
                    {i18n.t(
                      'preOrderScreen.sections.creditCardDetails.estimatedDeliveryLabel',
                      { estDelivery: preorderConfig.estimatedDelivery },
                    )}
                  </Typography>
                  <Box className={classes.sectionHeader}>
                    {i18n.t('preOrderScreen.sections.creditCardDetails.title')}
                  </Box>
                  <TextField
                    error={errors.cardHolderName && touched.cardHolderName}
                    fullWidth
                    helperText={errors.cardHolderName}
                    margin="dense"
                    id="cardHolderName"
                    label={i18n.t(
                      'preOrderScreen.sections.creditCardDetails.cardHolderName.label',
                    )}
                    type="text"
                    variant="outlined"
                    value={values.cardHolderName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onKeyDown={handleInputKeyDown}
                  />
                  <TextField
                    error={errors.cardNumber && touched.cardNumber}
                    fullWidth
                    helperText={errors.cardNumber}
                    margin="dense"
                    id="cardNumber"
                    label={i18n.t(
                      'preOrderScreen.sections.creditCardDetails.cardNumber.label',
                    )}
                    type="text"
                    variant="outlined"
                    value={values.cardNumber}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onKeyDown={handleInputKeyDown}
                  />

                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignContent="flex-start"
                  >
                    <Grid
                      item
                      xs
                      style={{
                        marginRight: '8px',
                      }}
                    >
                      <TextField
                        error={
                          errors.cardExpiryMonth && touched.cardExpiryMonth
                        }
                        fullWidth
                        helperText={errors.cardExpiryMonth}
                        margin="dense"
                        id="cardExpiryMonth"
                        label={i18n.t(
                          'preOrderScreen.sections.creditCardDetails.cardExpiryMonth.label',
                        )}
                        select
                        variant="outlined"
                        value={values.cardExpiryMonth}
                        onBlur={handleBlur}
                        onChange={({ target }) =>
                          setFieldValue('cardExpiryMonth', target.value)
                        }
                        onKeyDown={handleInputKeyDown}
                      >
                        {monthOptions.map(opt => (
                          <MenuItem value={opt} key={opt}>
                            {opt}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid
                      item
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Typography component="div">/</Typography>
                    </Grid>
                    <Grid
                      item
                      xs
                      style={{
                        marginLeft: '8px',
                      }}
                    >
                      <TextField
                        error={errors.cardExpiryYear && touched.cardExpiryYear}
                        fullWidth
                        helperText={errors.cardCVV}
                        margin="dense"
                        id="cardExpiryYear"
                        label={i18n.t(
                          'preOrderScreen.sections.creditCardDetails.cardExpiryYear.label',
                        )}
                        select
                        variant="outlined"
                        value={values.cardExpiryYear}
                        onBlur={handleBlur}
                        onChange={({ target }) =>
                          setFieldValue('cardExpiryYear', target.value)
                        }
                        onKeyDown={handleInputKeyDown}
                      >
                        {yearOptions.map(opt => (
                          <MenuItem value={opt} key={opt}>
                            {opt}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                  <TextField
                    error={errors.cardCVV && touched.cardCVV}
                    fullWidth
                    helperText={errors.cardCVV}
                    margin="dense"
                    id="cardCVV"
                    inputProps={{
                      maxlength: 3,
                    }}
                    label={i18n.t(
                      'preOrderScreen.sections.creditCardDetails.cardCVV.label',
                    )}
                    type="password"
                    variant="outlined"
                    value={values.cardCVV}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onKeyDown={handleInputKeyDown}
                  />
                </>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  marginTop="16px"
                >
                  <Button
                    color="secondary"
                    disabled={isRequest}
                    variant="contained"
                    onClick={handleSubmit}
                  >
                    {i18n.t('preOrderScreen.submitBtnLabel')}
                  </Button>
                </Box>
              </>
            )
          }}
        </Formik>
      </Grid>
    </Grid>
  )
}

StepThree.propTypes = {
  preorderConfig: PT.object,
  isRequest: PT.bool,
  onSubmit: PT.func,
}

export { StepThree }
