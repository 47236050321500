import React, { useState, useEffect } from 'react'

import PT from 'prop-types'

import { toast } from 'react-toastify'

import { Link as RouterLink } from 'react-router-dom'

import {
  Typography,
  Box,
  Container,
  Grid,
  Card,
  CardContent,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Button,
} from '@material-ui/core'
import Vimeo from '@u-wave/react-vimeo'

import Icon from '@mdi/react'
import { mdiPencil } from '@mdi/js'

import { get, orderBy, isArray, find } from 'lodash'

import i18n from 'I18N'

import PreorderState from 'Constants/PreorderStatus'
import { preOrder as preorderLink } from 'Constants/routes'

import {
  EditBenchPreorderDetailsModal,
  EditCardInfoModal,
  CancelPreorderModal,
} from 'Components/Blocks'

import { useStyles } from './styles'

const Dashboard = ({
  user,
  preOrder,
  helcimUser,
  onUpdatePreorderDetails,
  onUpdateCreditCard,
  onFetchHelcimUser,
  onCancelPreorder,
}) => {
  const classes = useStyles()

  const [
    isEditPreorderDetailsModalOpen,
    setIsEditPreorderDetailsModalOpen,
  ] = useState(false)
  const [
    isEditCreditCardInfoModalOpen,
    setIsEditCreditCardModalOpen,
  ] = useState(false)
  const [isCancelPreorderModalOpen, setIsCancelPreorderModalOpen] = useState(
    false,
  )
  const [isRequest, setIsRequest] = useState(false)

  useEffect(() => {
    if (preOrder.orderStatus === PreorderState.pendingCreditCard) {
      setIsEditCreditCardModalOpen(true)
    }
  }, [preOrder])

  const slateBenchesAmount = get(preOrder, 'orderDetails.slateBenchesAmount', 0)
  const slateBenchPrice = get(preOrder, 'orderDetails.slateBenchPrice')
  const tanBenchesAmount = get(preOrder, 'orderDetails.tanBenchesAmount', 0)
  const tanBenchPrice = get(preOrder, 'orderDetails.tanBenchPrice')
  const estimatedDelivery = get(preOrder, 'orderDetails.estimatedDelivery')

  const customerCode = get(helcimUser, 'customerCode._text')
  const cardToken = get(helcimUser, 'cards.card.cardToken._text')
  const cardInfo = get(helcimUser, 'cards.card', {})

  let defaultCard = get(helcimUser, 'cards.card', {})

  if (isArray(defaultCard)) {
    const defaultCardId = get(helcimUser, 'defaultCard._text', '')

    defaultCard = find(defaultCard, card => card.id._text === defaultCardId)
  }

  const cardNumber = get(defaultCard, 'cardNumber._text', '')
  const cardNumberLast4 =
    cardNumber.length > 0 ? cardNumber.substring(cardNumber.length - 4) : ''

  const isPreorderCanceled = preOrder.orderStatus === PreorderState.canceled

  const handleEditCreditCardInfoBtnClick = () => {
    setIsEditCreditCardModalOpen(true)
  }

  const handleEditCreditCardInfoModalCancel = () => {
    setIsEditCreditCardModalOpen(false)
  }

  const handleEditCreditCardInfoModalConfirm = creditCardData => {
    setIsRequest(true)

    onUpdateCreditCard(
      {
        ...creditCardData,
        customerCode,
        cardToken,
      },
      data => {
        if (data) {
          if (data.message.response._text === '0') {
            toast.error(data.message.responseMessage._text)
          } else {
            setIsEditCreditCardModalOpen(false)
            onFetchHelcimUser(customerCode)
          }
        }

        setIsRequest(false)
      },
    )
  }

  const handleEditPreorderDetailsBtnClick = () => {
    setIsEditPreorderDetailsModalOpen(true)
  }

  const handleEditPreorderDetailsModalClose = () => {
    setIsEditPreorderDetailsModalOpen(false)
  }

  const handleEditPreorderDetailsModalConfirm = nextDetails => {
    setIsRequest(true)

    onUpdatePreorderDetails(preOrder.preorderId, nextDetails, isSuccess => {
      setIsRequest(false)

      if (isSuccess) {
        setIsEditPreorderDetailsModalOpen(false)
      }
    })
  }

  const handleCancelPreorderBtnClick = () => {
    setIsCancelPreorderModalOpen(true)
  }

  const handleCancelPreorderModalCancel = () => {
    setIsCancelPreorderModalOpen(false)
  }

  const handleCancelPreorderModalSubmit = () => {
    setIsRequest(true)

    onCancelPreorder(preOrder.preorderId, customerCode, user.uid, isSuccess => {
      setIsRequest(false)

      if (isSuccess) {
        toast.success(
          i18n.t('preOrderDashboard.cancelPreorderModal.successMessage'),
        )
        setIsCancelPreorderModalOpen(false)
      }
    })
  }

  const renderOrderDetails = () => {
    const orderBenches = []

    if (tanBenchesAmount > 0) {
      orderBenches.push({
        amount: tanBenchesAmount,
        key: 'tan',
        color: '#C5B9AC',
        label: i18n.t('preOrderDashboard.orderSummary.tanBenchesAmount', {
          amount: tanBenchesAmount,
          price: tanBenchPrice,
        }),
      })
    }
    if (slateBenchesAmount > 0) {
      orderBenches.push({
        amount: slateBenchesAmount,
        key: 'slate',
        color: '#545859',
        label: i18n.t('preOrderDashboard.orderSummary.slateBenchesAmount', {
          amount: slateBenchesAmount,
          price: slateBenchPrice,
        }),
      })
    }

    const orderedBenches = orderBy(orderBenches, item => item.amount, 'desc')

    return (
      <>
        <List disableGutters>
          {orderedBenches.map(orderItem => (
            <ListItem key={orderItem.key}>
              <ListItemAvatar>
                <Avatar style={{ backgroundColor: orderItem.color }} />
              </ListItemAvatar>
              <ListItemText primary={orderItem.label} />
            </ListItem>
          ))}
        </List>
        <Typography>
          {i18n.t('preOrderDashboard.orderSummary.cardNumber', {
            cardNumber: cardNumberLast4,
          })}

          <Button
            size="small"
            style={{
              marginLeft: 'auto',
            }}
            startIcon={
              <Icon path={mdiPencil} size="16px" className={classes.icon} />
            }
            onClick={handleEditCreditCardInfoBtnClick}
          />
        </Typography>
        <Typography className={classes.breakLine}>
          {i18n.t('preOrderDashboard.orderSummary.footer', {
            estimatedDelivery,
          })}
        </Typography>
      </>
    )
  }

  return (
    <>
      <EditBenchPreorderDetailsModal
        isOpen={isEditPreorderDetailsModalOpen}
        isRequest={isRequest}
        preorderDetails={preOrder.orderDetails || {}}
        onClose={handleEditPreorderDetailsModalClose}
        onSubmit={handleEditPreorderDetailsModalConfirm}
      />
      <EditCardInfoModal
        isOpen={isEditCreditCardInfoModalOpen}
        isRequest={isRequest}
        cardInfo={cardInfo}
        onClose={handleEditCreditCardInfoModalCancel}
        onSubmit={handleEditCreditCardInfoModalConfirm}
      />
      <CancelPreorderModal
        isOpen={isCancelPreorderModalOpen}
        isRequest={isRequest}
        onClose={handleCancelPreorderModalCancel}
        onSubmit={handleCancelPreorderModalSubmit}
      />

      <Container style={{ minHeight: '100vh' }}>
        <Box display="flex" alignItems="center" className={classes.topBar}>
          <Typography variant="h5" style={{ marginTop: '16px' }}>
            {i18n.t('preOrderDashboard.breadcrumbs')}
          </Typography>
        </Box>

        {isPreorderCanceled ? (
          <Typography variant="h2">
            {i18n.t('preOrderDashboard.canceledTitle')}
          </Typography>
        ) : (
          <>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignContent: 'center',
              }}
            >
              <Typography variant="h2">
                {i18n.t('preOrderDashboard.title', {
                  firstName: user.firstName || 'Hi',
                })}
              </Typography>

              <Button
                variant="contained"
                onClick={handleCancelPreorderBtnClick}
              >
                {i18n.t('preOrderDashboard.cancelPreorderBtnLabel')}
              </Button>
            </Box>
            <Typography className={classes.breakLine} variant="subtitle1">
              {i18n.t('preOrderDashboard.subtitle', { estimatedDelivery })}
            </Typography>
          </>
        )}
        <Box style={{ marginTop: '16px' }}>
          <Box>
            <Grid container spacing={3}>
              <Grid item lg={8} xs={24}>
                <Typography className={classes.breakLine}>
                  {i18n.t('preOrderDashboard.videoTitle')}
                </Typography>
                <Vimeo
                  video="https://vimeo.com/384120350"
                  width={480}
                  height={360}
                />
              </Grid>

              <Grid item lg={4} xs={12}>
                <Card className={classes.root}>
                  <Toolbar
                    disableGutters
                    className={classes.tableActionBar}
                    display="flex"
                    alignItems="center"
                  >
                    <Typography className={classes.title} variant="h6">
                      {i18n.t('preOrderDashboard.orderSummary.title')}
                    </Typography>

                    {!isPreorderCanceled && (
                      <Button
                        size="small"
                        style={{
                          marginLeft: 'auto',
                        }}
                        startIcon={
                          <Icon
                            path={mdiPencil}
                            size="16px"
                            className={classes.icon}
                          />
                        }
                        onClick={handleEditPreorderDetailsBtnClick}
                      />
                    )}
                  </Toolbar>
                  <CardContent>
                    {isPreorderCanceled ? (
                      <RouterLink
                        style={{ textAlign: 'center' }}
                        to={preorderLink}
                      >
                        <Typography>
                          {i18n.t('preOrderDashboard.reserveBenchLinkLabel')}
                        </Typography>
                      </RouterLink>
                    ) : (
                      renderOrderDetails()
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  )
}

Dashboard.propTypes = {
  user: PT.object,
  onUpdatePreorderDetails: PT.func,
  onUpdateCreditCard: PT.func,
  onFetchHelcimUser: PT.func,
  onCancelPreorder: PT.func,
}

export default Dashboard
