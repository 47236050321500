import * as Yup from 'yup'

import i18n from 'I18N'

const errorMessages = i18n.t('preOrderSignUpScreen.validation.errorMessages')

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email(errorMessages.email.valid)
    .required(errorMessages.email.required),
  password: Yup.string()
    .min(6, errorMessages.pass.tooShort)
    .max(12, errorMessages.pass.tooLong)
    .required(errorMessages.pass.required),
})
