import React, { useState, useEffect, useMemo } from 'react'
import PT from 'prop-types'

import { Link as RouterLink } from 'react-router-dom'

import Typography from '@material-ui/core/Typography'

import Card from '@material-ui/core/Card'

import Icon from '@mdi/react'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import Button from '@material-ui/core/Button'
import { mdiChevronRight, mdiPlus } from '@mdi/js'

import Grid from '@material-ui/core/Grid'

import Toolbar from '@material-ui/core/Toolbar'

import { upperCase, take, orderBy } from 'lodash'

import { trackCodes as trackCodesRoute } from 'Constants/routes'

import carriersData from 'Constants/carriers'

import i18n from 'I18N'

import { useStyles } from './styles'

const DeliveryBlock = ({ item, classes }) => {
  const [showPendingText, setShowPendingText] = useState(
    item.updatedAt + 5000 < Date.now(),
  )

  useEffect(() => {
    if (!showPendingText) {
      setTimeout(() => {
        setShowPendingText(true)
      }, 5000)
    }
  }, [])

  const deliveryName =
    item.notes && item.notes !== '' ? item.notes : item.trackingCode
  const carrierName = carriersData[item.carrier]
    ? carriersData[item.carrier].title
    : upperCase(item.carrier)

  return (
    <List className={classes.list} key={item.trackCode}>
      <ListItem alignItems="flex-start">
        <ListItemText
          primary={deliveryName}
          secondary={
            <>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textSecondary"
              >
                {carrierName}
              </Typography>
              {showPendingText && item.pendingUpdate && (
                <Typography
                  className={classes.pendingText}
                  component="p"
                  variant="body2"
                >
                  {i18n.t('trackCodesScreen.labels.pendingBench')}
                </Typography>
              )}
            </>
          }
        />
      </ListItem>
    </List>
  )
}

const UpcomingDeliveries = ({ deliveries, onAddTrackCodeBtnClick }) => {
  const classes = useStyles()

  const orderedUpcomintDeliveries = useMemo(() => {
    return take(orderBy(deliveries || [], 'created', 'desc'), 3)
  }, [deliveries])

  return (
    <Grid item lg={4} xs={12}>
      <Card className={classes.root}>
        <Toolbar
          disableGutters
          className={classes.tableActionBar}
          display="flex"
          alignItems="center"
        >
          <Typography className={classes.title} variant="h6">
            {i18n.t('homeScreen.labels.deliveries.header')}
          </Typography>
        </Toolbar>

        <>
          {orderedUpcomintDeliveries.length > 0 ? (
            orderedUpcomintDeliveries.map(item => (
              <DeliveryBlock
                key={item.packageId}
                item={item}
                classes={classes}
              />
            ))
          ) : (
            <ListItem alignItems="flex-start">
              <Typography variant="subtitle1">
                {i18n.t('trackCodesScreen.emptyBlock.text')}
              </Typography>
            </ListItem>
          )}
        </>

        <Toolbar
          disableGutters
          className={classes.tableFooterBar}
          display="flex"
          alignItems="center"
        >
          <Button
            size="small"
            endIcon={<Icon path={mdiPlus} size="24px" />}
            onClick={onAddTrackCodeBtnClick}
          >
            {i18n.t('homeScreen.labels.deliveries.addDeliveryBtn')}
          </Button>
          <RouterLink
            to={trackCodesRoute}
            style={{ color: '#000', textDecoration: 'none' }}
          >
            <Button
              size="small"
              endIcon={<Icon path={mdiChevronRight} size="24px" />}
            >
              {i18n.t('homeScreen.labels.deliveries.viewAllBtn')}
            </Button>
          </RouterLink>
        </Toolbar>
      </Card>
    </Grid>
  )
}

UpcomingDeliveries.propTypes = {
  deliveries: PT.array,
  onAddTrackCodeBtnClick: PT.func,
}

export { UpcomingDeliveries }
