import { createReducer } from 'Helpers/redux'

import {
  FETCH_USERS_PREORDERS,
  FETCH_HELCIM_USER,
  FETCH_PREORDERS_CONFIG,
  UPDATE_PREORDER_DETAILS,
  UPDATE_PREORDER,
  CANCEL_PREORDER,
  ADD_PREORDER_TO_DB,
} from 'Redux/actions/preOrder'

const defaultConfig = {
  estimatedDelivery: 'Month',
  slateBenchPrice: 123,
  tanBenchPrice: 321,
  maxBenchesAmountInPreorder: 3,
}

const initialState = {
  preOrder: {},
  helcimUser: {},
  config: defaultConfig,
}

const handlers = {
  [ADD_PREORDER_TO_DB.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      preOrder: payload.data,
    }
  },
  [FETCH_USERS_PREORDERS.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      preOrder: payload.data,
    }
  },
  [UPDATE_PREORDER_DETAILS.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      preOrder: {
        ...state.preOrder,
        ...payload.data,
      },
    }
  },
  [UPDATE_PREORDER.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      preOrder: {
        ...state.preOrder,
        ...payload.data,
      },
    }
  },
  [CANCEL_PREORDER.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      preOrder: {
        ...state.preOrder,
        ...payload.data,
      },
    }
  },
  [FETCH_HELCIM_USER.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      helcimUser: payload.customers.customer,
    }
  },
  [FETCH_PREORDERS_CONFIG.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      config: payload.data || defaultConfig,
    }
  },
}

export default createReducer(initialState, handlers)
