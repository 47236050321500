import React from 'react'

import PT from 'prop-types'

import { Dialog, DialogTitle, DialogActions, Button } from '@material-ui/core'

import i18n from 'I18N'

const CancelPreorderModal = ({ isOpen, isRequest, onClose, onSubmit }) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        {i18n.t('preOrderDashboard.cancelPreorderModal.title')}
      </DialogTitle>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {i18n.t('preOrderDashboard.cancelPreorderModal.cancelBtnLabel')}
        </Button>
        <Button
          color="secondary"
          disabled={isRequest}
          variant="contained"
          onClick={onSubmit}
        >
          {i18n.t('preOrderDashboard.cancelPreorderModal.submitBtnLabel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

CancelPreorderModal.propTypes = {
  isOpen: PT.bool,
  isRequest: PT.bool,
  onClose: PT.func,
  onSubmit: PT.func,
}

export { CancelPreorderModal }
