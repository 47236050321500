import { createSelector } from 'reselect'

const preOrderData = store => store.preOrder

export const getPreorder = createSelector(
  preOrderData,
  data => data.preOrder || {},
)

export const getHelcimUser = createSelector(
  preOrderData,
  data => data.helcimUser || {},
)

export const getPreordersConfig = createSelector(
  preOrderData,
  data => data.config,
)
