import React from 'react'

import PT from 'prop-types'

import Button from '@material-ui/core/Button'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'

import DialogTitle from '@material-ui/core/DialogTitle'

const ConfirmModal = ({
  isOpen,
  title,
  description,
  confirmBtnText,
  cancelBtnText,
  onClose,
  onConfirm,
  isRequest,
}) => {
  const handleConfirm = () => {
    if (!isRequest) {
      onConfirm()
    }
  }

  const handleCancel = () => {
    onClose()
  }

  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      {description && (
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          {cancelBtnText}
        </Button>
        <Button
          color="secondary"
          disabled={isRequest}
          variant="contained"
          onClick={handleConfirm}
        >
          {confirmBtnText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmModal.propTypes = {
  isOpen: PT.bool.isRequired,
  isRequest: PT.bool,
  title: PT.string,
  description: PT.string,
  confirmBtnText: PT.string,
  cancelBtnText: PT.string,
  onClose: PT.func.isRequired,
  onConfirm: PT.func.isRequired,
}

export { ConfirmModal }
