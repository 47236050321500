import React, { useState, useMemo, useEffect } from 'react'

import PT from 'prop-types'
import moment from 'moment'

import {
  Typography,
  Checkbox,
  Box,
  Card,
  CardContent,
  Container,
  Button,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core'

import Icon from '@mdi/react'
import {
  mdiPencil,
  mdiDelete,
  mdiPlus,
  mdiPencilBoxMultiple,
  mdiCancel,
  mdiExpandAll,
  mdiImage,
  mdiVideo,
} from '@mdi/js'

import { upperCase, orderBy, get, isEmpty } from 'lodash'

import DeliveryStatus from 'Constants/deliveryStatus'
import carriersData from 'Constants/carriers'

import {
  AddTrackCodeModal,
  ConfirmModal,
  DeliveryDetailsModal,
} from 'Components/Blocks'

import i18n from 'I18N'

import { useStyles } from './styles'

const DeliveryBlock = ({
  item,
  classes,
  archived = false,
  isEditModeOn = false,
  itemChecked = false,
  onEditClick,
  onDeleteClick,
  onItemCheckboxClick,
  onItemDetailsClick,
}) => {
  const [showPendingText, setShowPendingText] = useState(
    item.updatedAt + 5000 < Date.now(),
  )
  useEffect(() => {
    if (!showPendingText) {
      setTimeout(() => {
        setShowPendingText(true)
      }, 5000)
    }
  })

  const handleItemDetailsBtnClick = () => {
    onItemDetailsClick(item)
  }

  const handleEditClick = () => {
    onEditClick(item)
  }

  const handleDeleteClick = () => {
    onDeleteClick(item, archived)
  }

  const handleItemCheckboxClick = () => {
    onItemCheckboxClick(item.packageId, archived)
  }

  const renderSecondayAction = () => {
    if (isEditModeOn) {
      return null
    }

    const deliveredEvent = get(item, 'deliveredEvent', null)

    const isMediaUrl =
      deliveredEvent && !isEmpty(get(deliveredEvent, 'mediaUrls'), [])
    const isVideo =
      isMediaUrl && get(deliveredEvent, 'mediaUrls')[0].match(/(.mp4)/i)

    return (
      <ListItemSecondaryAction className={classes.cardListActionContainer}>
        {isMediaUrl && (
          <IconButton onClick={handleItemDetailsBtnClick}>
            <Icon
              path={isVideo ? mdiVideo : mdiImage}
              size="16px"
              className={classes.icon}
            />
          </IconButton>
        )}
        {!archived && (
          <Button
            size="small"
            className={classes.button}
            startIcon={
              <Icon path={mdiPencil} size="16px" className={classes.icon} />
            }
            onClick={handleEditClick}
          >
            {i18n.t('trackCodesScreen.trackTab.listItem.editBtnText')}
          </Button>
        )}
        <Button
          size="small"
          className={classes.button}
          startIcon={
            <Icon path={mdiDelete} size="16px" className={classes.icon} />
          }
          onClick={handleDeleteClick}
        >
          {i18n.t('trackCodesScreen.trackTab.listItem.deleteBtnText')}
        </Button>
      </ListItemSecondaryAction>
    )
  }

  const deliveryName =
    item.notes && item.notes !== '' ? item.notes : item.trackingCode
  const carrierName = carriersData[item.carrier]
    ? carriersData[item.carrier].title
    : upperCase(item.carrier)

  const getDate = date => moment(new Date(date)).format('MMMM D, YYYY')
  const getTime = date => moment(new Date(date)).format('h:mm A')

  return (
    <Card className={classes.card} key={item.packageId}>
      <CardContent className={classes.cardContent}>
        <List className={classes.list} disablePadding="true">
          <ListItem alignItems="flex-start">
            <Grid container>
              <Grid item>
                <Box display="flex" flexDirection="row" alignItems="center">
                  {isEditModeOn && (
                    <Box>
                      <Checkbox
                        checked={itemChecked}
                        onChange={handleItemCheckboxClick}
                      />
                    </Box>
                  )}
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                  >
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      component="p"
                    >
                      {deliveryName}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {item.delivered
                        ? i18n.t('trackCodesScreen.labels.statuses.delivered', {
                            date: getDate(item.delivered),
                            time: getTime(item.delivered),
                          })
                        : carrierName}
                    </Typography>
                    {showPendingText && item.pendingUpdate && (
                      <Typography
                        className={classes.pendingText}
                        component="p"
                        variant="body2"
                      >
                        {i18n.t('trackCodesScreen.labels.pendingBench')}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </ListItem>
          {renderSecondayAction()}
        </List>
      </CardContent>
    </Card>
  )
}

const Deliveries = ({
  benchId,
  trackCodes,
  trackCodesArchive,
  onTrackCodeCreate,
  onTrackCodeDelete,
  onTrackCodeUpdate,
  onArchivedTrackCodeDelete,
}) => {
  const classes = useStyles()

  const [itemToEdit, setItemToEdit] = useState(null)
  const [itemToDelete, setItemToDelete] = useState(null)
  const [isAddNewDeliveryModalOpen, setIsAddNewDeliveryModalOpen] = useState(
    false,
  )
  const [isRequest, setIsRequest] = useState(false)
  const [isEditModeOn, setIsEditModeOn] = useState(false)
  const [checkedItems, setCheckedItems] = useState({})
  const [checkedArchivedItems, setCheckedArchivedItems] = useState({})
  const [
    isEditModeConfirmDeleteModalOpen,
    setIsEditModeConfirmDeleteModalOpen,
  ] = useState(false)
  const [deliveryToShowDetails, setDeliveryToShowDetails] = useState(null)

  const handleDeliveryDetailsModalClose = () => {
    setDeliveryToShowDetails(null)
  }

  const handleItemDetailsBtnClick = delivery => {
    setDeliveryToShowDetails(delivery)
  }

  const handleEditListBtnClick = () => {
    setIsEditModeOn(true)
  }

  const handleEditListCancelBtnClick = () => {
    setIsEditModeOn(false)
  }

  const handleSelectAllBtnClick = () => {
    const nextCheckedItems = {}
    const nextCheckedArchivedItems = {}

    trackCodes.forEach(item => {
      nextCheckedItems[item.packageId] = true
    })
    trackCodesArchive.forEach(item => {
      nextCheckedArchivedItems[item.packageId] = true
    })

    setCheckedItems(nextCheckedItems)
    setCheckedArchivedItems(nextCheckedArchivedItems)
  }

  const handleDeleteBtnClick = () => {
    setIsEditModeConfirmDeleteModalOpen(true)
  }

  const handleEditModeConfirmDeleteModalConfirmBtnClick = () => {
    setIsEditModeConfirmDeleteModalOpen(false)

    // eslint-disable-next-line
    for (let [key, value] of Object.entries(checkedItems)) {
      if (value) {
        onTrackCodeDelete(benchId, key, DeliveryStatus.DELETED)
      }
    }
    // eslint-disable-next-line
    for (let [key, value] of Object.entries(checkedArchivedItems)) {
      if (value) {
        onArchivedTrackCodeDelete(benchId, key)
      }
    }

    setCheckedArchivedItems({})
    setCheckedItems({})
    setIsEditModeOn(false)
  }

  const handleEditModeConfirmDeleteModalCancelBtnClick = () => {
    setIsEditModeConfirmDeleteModalOpen(false)
  }

  const handleAddNewBtnClick = () => {
    setIsAddNewDeliveryModalOpen(true)
  }

  const handleAddNewDeliveryModalClose = () => {
    setIsAddNewDeliveryModalOpen(false)
  }

  const handleItemCheckboxClick = (itemId, isArchived) => {
    if (isArchived) {
      setCheckedArchivedItems({
        ...checkedArchivedItems,
        [itemId]: !checkedArchivedItems[itemId],
      })
    } else {
      setCheckedItems({
        ...checkedItems,
        [itemId]: !checkedItems[itemId],
      })
    }
  }

  const handleAddTrackCode = ({ trackCode, name }) => {
    setIsRequest(true)
    onTrackCodeCreate(benchId, trackCode, name, isSuccess => {
      setIsRequest(false)

      if (isSuccess) {
        setIsAddNewDeliveryModalOpen(false)
      }
    })
  }

  const handleEditItemModalClose = () => {
    setItemToEdit(null)
  }

  const handleEditItemModalConfirm = update => {
    setIsRequest(true)

    onTrackCodeUpdate(
      benchId,
      itemToEdit.packageId,
      {
        ...itemToEdit,
        notes: update.name,
        rawTrackingCode: update.trackCode,
        trackingCode: update.trackCode,
      },
      isSuccess => {
        setIsRequest(false)

        if (isSuccess) {
          setItemToEdit(null)
        }
      },
    )

    setItemToEdit(null)
  }

  const handleDeleteDeliveryModalClose = () => {
    setItemToDelete(null)
  }

  const handleDeleteDeliveryModalConfirm = () => {
    setIsRequest(true)

    const callback = isSuccess => {
      setIsRequest(false)

      if (isSuccess) {
        setItemToDelete(null)
      }
    }

    if (itemToDelete.isArchive) {
      onArchivedTrackCodeDelete(benchId, itemToDelete.packageId, callback)
    } else {
      onTrackCodeDelete(
        benchId,
        itemToDelete.packageId,
        DeliveryStatus.DELETED,
        callback,
      )
    }
  }

  const handleEditItemBtnClick = item => {
    setItemToEdit(item)
  }

  const handleDeleteItemBtnClick = (item, isArchive) => {
    setItemToDelete({
      packageId: item.packageId,
      isArchive,
    })
  }

  const orderedUpcomintDeliveries = useMemo(() => {
    return orderBy(trackCodes || [], 'created', 'desc')
  }, [trackCodes])
  const orderedArchiveDeliveries = useMemo(() => {
    return orderBy(trackCodesArchive || [], 'delivered', 'desc')
  }, [trackCodesArchive])

  const checkedItemsCount =
    Object.values(checkedItems).filter(item => item === true).length +
    Object.values(checkedArchivedItems).filter(item => item === true).length

  return (
    <>
      <Container>
        <AddTrackCodeModal
          isOpen={isAddNewDeliveryModalOpen}
          onClose={handleAddNewDeliveryModalClose}
          onConfirm={handleAddTrackCode}
          isRequest={isRequest}
        />
        <AddTrackCodeModal
          initialValues={
            itemToEdit && {
              trackCode: itemToEdit.trackingCode,
              name: itemToEdit.notes || '',
            }
          }
          isOpen={itemToEdit !== null}
          isRequest={isRequest}
          title={i18n.t('trackCodesScreen.updateDetailModal.header')}
          submitBtnText={i18n.t(
            'trackCodesScreen.updateDetailModal.buttons.submit',
          )}
          onClose={handleEditItemModalClose}
          onConfirm={handleEditItemModalConfirm}
        />
        <ConfirmModal
          isOpen={itemToDelete !== null}
          isRequest={isRequest}
          title={i18n.t('trackCodesScreen.details.deleteModal.header')}
          confirmBtnText={i18n.t(
            'trackCodesScreen.details.deleteModal.buttons.confirm',
          )}
          cancelBtnText={i18n.t(
            'trackCodesScreen.details.deleteModal.buttons.cancel',
          )}
          onClose={handleDeleteDeliveryModalClose}
          onConfirm={handleDeleteDeliveryModalConfirm}
        />
        <ConfirmModal
          isOpen={isEditModeConfirmDeleteModalOpen}
          isRequest={isRequest}
          title={i18n.t(
            'trackCodesScreen.trackTab.editModeConfirmDeleteModal.header',
          )}
          description={i18n.t(
            'trackCodesScreen.trackTab.editModeConfirmDeleteModal.subheader',
          )}
          confirmBtnText={i18n.t(
            'trackCodesScreen.trackTab.editModeConfirmDeleteModal.confirmBtnLabel',
          )}
          cancelBtnText={i18n.t(
            'trackCodesScreen.trackTab.editModeConfirmDeleteModal.cancelBtnLabel',
          )}
          onClose={handleEditModeConfirmDeleteModalCancelBtnClick}
          onConfirm={handleEditModeConfirmDeleteModalConfirmBtnClick}
        />
        <DeliveryDetailsModal
          isOpen={deliveryToShowDetails !== null}
          delivery={deliveryToShowDetails}
          onClose={handleDeliveryDetailsModalClose}
        />

        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          alignItems="center"
          justifyContent="space-between"
          className={classes.topBar}
        >
          <Typography variant="h5">
            {isEditModeOn
              ? i18n.t('trackCodesScreen.trackTab.editMode.title', {
                  checkedItemsCount,
                })
              : i18n.t('trackCodesScreen.labels.header')}
          </Typography>

          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            {isEditModeOn ? (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  endIcon={
                    <Icon path={mdiExpandAll} size="24px" color="white" />
                  }
                  style={{
                    marginRight: '16px',
                  }}
                  onClick={handleSelectAllBtnClick}
                >
                  {i18n.t(
                    'trackCodesScreen.trackTab.editMode.selectAllBtnLabel',
                  )}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  endIcon={<Icon path={mdiDelete} size="24px" color="white" />}
                  style={{
                    marginRight: '16px',
                  }}
                  onClick={handleDeleteBtnClick}
                >
                  {i18n.t('trackCodesScreen.trackTab.editMode.deleteBtnLabel')}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  endIcon={<Icon path={mdiCancel} size="24px" color="white" />}
                  style={{
                    marginRight: '16px',
                  }}
                  onClick={handleEditListCancelBtnClick}
                >
                  {i18n.t('trackCodesScreen.trackTab.editMode.cancelBtnLabel')}
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  endIcon={
                    <Icon
                      path={mdiPencilBoxMultiple}
                      size="24px"
                      color="white"
                    />
                  }
                  style={{
                    marginRight: '16px',
                  }}
                  onClick={handleEditListBtnClick}
                >
                  {i18n.t('trackCodesScreen.trackTab.editListBtnLabel')}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  endIcon={<Icon path={mdiPlus} size="24px" color="white" />}
                  onClick={handleAddNewBtnClick}
                >
                  {i18n.t('trackCodesScreen.trackTab.addNewDeliveryBtn')}
                </Button>
              </>
            )}
          </Box>
        </Box>

        {orderedUpcomintDeliveries.length > 0 ? (
          <>
            <Box className={classes.sectionHeader}>
              {i18n.t('trackCodesScreen.trackTab.sectionsHeaders.active')}
            </Box>
            {orderedUpcomintDeliveries.map(item => {
              return (
                <DeliveryBlock
                  classes={classes}
                  item={item}
                  itemChecked={checkedItems[item.packageId]}
                  isEditModeOn={isEditModeOn}
                  key={item.packageId}
                  onEditClick={handleEditItemBtnClick}
                  onDeleteClick={handleDeleteItemBtnClick}
                  onItemCheckboxClick={handleItemCheckboxClick}
                />
              )
            })}
          </>
        ) : (
          <Typography variant="h5">
            {i18n.t('trackCodesScreen.emptyBlock.text')}
          </Typography>
        )}

        {orderedArchiveDeliveries.length > 0 && (
          <>
            <Box className={classes.sectionHeader}>
              {i18n.t('trackCodesScreen.trackTab.sectionsHeaders.delivered')}
            </Box>
            {orderedArchiveDeliveries.map(item => {
              return (
                <DeliveryBlock
                  archived
                  key={item.packageId}
                  item={item}
                  itemChecked={checkedArchivedItems[item.packageId]}
                  isEditModeOn={isEditModeOn}
                  classes={classes}
                  onDeleteClick={handleDeleteItemBtnClick}
                  onItemCheckboxClick={handleItemCheckboxClick}
                  onItemDetailsClick={handleItemDetailsBtnClick}
                />
              )
            })}
          </>
        )}
      </Container>
    </>
  )
}

Deliveries.propTypes = {
  benchId: PT.string,
  trackCodes: PT.arrayOf(PT.object),
  trackCodesArchive: PT.arrayOf(PT.object),
  onArchivedTrackCodeDelete: PT.func,
  onTrackCodeCreate: PT.func,
}

export default Deliveries
