export default (array, nextItem, updateCondFunc) => {
  let isUpdated = false

  const nextArray = array.map(item => {
    if (updateCondFunc(item, nextItem)) {
      isUpdated = true

      return nextItem
    }

    return item
  })

  if (!isUpdated) {
    nextArray.push(nextItem)
  }

  return nextArray
}
