import { takeLatest } from 'redux-saga/effects'

// action types
import { FETCH_USERS_PREORDERS } from 'Redux/actions/preOrder'

// sagas
import onFetchUsersPreorder from './onFetchUsersPreorder'

function* packagesSaga() {
  yield takeLatest(FETCH_USERS_PREORDERS.SUCCESS, onFetchUsersPreorder)
}

export default packagesSaga
