import React from 'react'

import { Formik } from 'formik'
import { toast } from 'react-toastify'

import { pick, get } from 'lodash'

import PT from 'prop-types'

import {
  Box,
  Grid,
  Button,
  TextField,
  ListItem,
  List,
  ListItemAvatar,
  Typography,
  MenuItem,
} from '@material-ui/core'

import i18n from 'I18N'

import { useStyles } from './styles'

const COLORS_LIST = i18n.t('preOrderScreen.sections.orderDetails.benchColors')

const StepTwo = ({ onSubmit, isRequest, preorderConfig, existingPreorder }) => {
  const classes = useStyles()
  const MAX_BENCHES_IN_ORDER = preorderConfig.maxBenchesAmountInPreorder || 3

  const handleConfirm = orderDetails => {
    const currentBenchesQuantity = Object.values(orderDetails).reduce(
      (a, b) => a + b,
      0,
    )

    if (currentBenchesQuantity > MAX_BENCHES_IN_ORDER) {
      toast.error(
        i18n.t(
          'preOrderScreen.sections.orderDetails.errorMessages.tooMuchBenches',
          {
            maxAmount: MAX_BENCHES_IN_ORDER,
          },
        ),
      )

      return
    }

    const orderConfigs = pick(preorderConfig, [
      'slateBenchPrice',
      'tanBenchPrice',
      'estimatedDelivery',
    ])

    onSubmit({
      ...orderDetails,
      ...orderConfigs,
    })
    window.scrollTo(0, 0)
  }

  const getColorID = colorVal => `${colorVal}BenchesAmount`

  const getImage = colorVal =>
    // eslint-disable-next-line
    require(`../../../../../../Assets/benchImages/${colorVal}-bench.png`)

  const initialValues = {}

  COLORS_LIST.forEach(colorData => {
    const colorId = getColorID(colorData.value)

    initialValues[colorId] = get(existingPreorder, `orderDetails.${colorId}`, 0)
  })

  return (
    <Grid
      container
      display="flex"
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
    >
      <Grid item xs={9} md={5} lg={4} xl={3}>
        <Formik initialValues={initialValues} onSubmit={handleConfirm}>
          {({
            values = {},
            errors,
            touched,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => {
            const handleInputKeyDown = e => {
              if (e.key === 'Enter') {
                handleSubmit()
              }
            }

            const currentBenchesQuantity = Object.values(values).reduce(
              (a, b) => a + b,
              0,
            )

            return (
              <>
                <Box className={classes.sectionHeader}>
                  {i18n.t('preOrderScreen.sections.orderDetails.title')}
                </Box>
                <Typography className={classes.mainColor} variant="h6">
                  {i18n.t(
                    'preOrderScreen.sections.orderDetails.dueTodayLabel',
                    { sum: 0 },
                  )}
                </Typography>
                {i18n
                  .t('preOrderScreen.sections.orderDetails.infoLines')
                  .map(line => (
                    <Typography className={classes.mainColor}>
                      {line}
                    </Typography>
                  ))}
                <Typography className={classes.mainColor}>
                  {i18n.t(
                    'preOrderScreen.sections.orderDetails.estimatedDeliveryLabel',
                    { estDelivery: preorderConfig.estimatedDelivery },
                  )}
                </Typography>
                <Typography className={classes.mainColor} variant="h6">
                  {i18n.t('preOrderScreen.sections.orderDetails.priceLabel', {
                    preorderPrice: preorderConfig.slateBenchPrice,
                  })}
                </Typography>
                <Typography className={classes.mainColor}>
                  {i18n.t('preOrderScreen.sections.orderDetails.chargedLabel')}
                </Typography>
                <Typography className={classes.mainColor} variant="subtitle2">
                  {i18n.t('preOrderScreen.sections.orderDetails.limitLabel', {
                    maxBenchesAmount:
                      preorderConfig.maxBenchesAmountInPreorder || 3,
                  })}
                </Typography>
                <Box
                  style={{
                    width: 'max-content',
                    margin: 'auto',
                  }}
                >
                  <List>
                    {COLORS_LIST.map(colorData => {
                      const inputId = getColorID(colorData.value)
                      const inputMaxValue = Math.max(
                        values[inputId] +
                          MAX_BENCHES_IN_ORDER -
                          currentBenchesQuantity,
                        values[inputId],
                      )

                      return (
                        <ListItem>
                          <ListItemAvatar>
                            <img
                              alt={colorData.title}
                              src={getImage(colorData.value)}
                              style={{
                                width: '150px',
                              }}
                            />
                          </ListItemAvatar>
                          <TextField
                            error={errors[inputId] && touched[inputId]}
                            fullWidth
                            helperText={errors[inputId]}
                            margin="dense"
                            id={inputId}
                            label={colorData.title}
                            style={{
                              marginLeft: '16px',
                            }}
                            select
                            variant="outlined"
                            value={values[inputId]}
                            onBlur={handleBlur}
                            onChange={({ target }) =>
                              setFieldValue(inputId, target.value)
                            }
                            onKeyDown={handleInputKeyDown}
                          >
                            {new Array(inputMaxValue + 1)
                              .fill(0)
                              .map((item, index) => (
                                <MenuItem value={index} key={index}>
                                  {index}
                                </MenuItem>
                              ))}
                          </TextField>
                        </ListItem>
                      )
                    })}
                  </List>
                </Box>

                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  marginTop="16px"
                >
                  <Button
                    color="secondary"
                    disabled={isRequest}
                    variant="contained"
                    onClick={handleSubmit}
                  >
                    {i18n.t('preOrderScreen.submitBtnLabel')}
                  </Button>
                </Box>
              </>
            )
          }}
        </Formik>
      </Grid>
    </Grid>
  )
}

StepTwo.propTypes = {
  isRequest: PT.bool,
  preorderConfig: PT.object,
  onSubmit: PT.func,
}

export { StepTwo }
