import { createSelector } from 'reselect'
import { isObject, get, find } from 'lodash'

const benchData = store => store.bench
const benchCodesData = store => store.benchCodes

export const getPrimaryBenchEntryCodes = createSelector(
  [benchCodesData, benchData],
  (codes, bench) => {
    if (bench.primaryBenchId) {
      const entryCodesSync = get(bench.entryCodes, bench.primaryBenchId, [])
      const entryCodesSyncArr = (entryCodesSync || []).filter(el =>
        isObject(el),
      )
      const entryCodesArr = get(codes.entryCodes, bench.primaryBenchId, [])

      const newArr = entryCodesArr.map(el => {
        const findElem = entryCodesSyncArr.find(elem => elem.code === el.code)

        const pendingUpdate = findElem ? findElem.pendingUpdate : true

        return Object.assign(el, { pendingUpdate })
      })
      return newArr || []
    }
    return []
  },
)

export const getPrimaryBenchEntryCodesObject = createSelector(
  [benchCodesData, benchData],
  (codes, bench) => {
    if (bench.primaryBenchId) {
      const entryCodesSync = get(bench.entryCodes, bench.primaryBenchId, [])
      const entryCodesSyncArr = (entryCodesSync || []).filter(el =>
        isObject(el),
      )
      const entryCodesArr = get(codes.entryCodes, bench.primaryBenchId, [])

      const res = {}

      entryCodesArr.forEach(el => {
        const findElem = entryCodesSyncArr.find(elem => elem.code === el.code)

        const pendingUpdate = findElem ? findElem.pendingUpdate : true

        res[el.id] = Object.assign(el, { pendingUpdate })
      })
      return res
    }
    return {}
  },
)

export const getPrimartyBenchTrackCodes = createSelector(
  [benchCodesData, benchData],
  (codes, bench) => {
    if (bench.primaryBenchId) {
      const trackCodesSync = get(bench.trackCodes, bench.primaryBenchId, [])
      const trackCodesSyncArr = (trackCodesSync || []).filter(el =>
        isObject(el),
      )
      const trackCodesArr = get(codes.trackCodes, [bench.primaryBenchId], [])

      const newArr = trackCodesArr.map(el => {
        const findElem = trackCodesSyncArr.find(elem => elem.code === el.code)

        const pendingUpdate = findElem ? findElem.pendingUpdate : true

        return Object.assign(el, { pendingUpdate })
      })
      return newArr || []
    }
    return []
  },
)

export const getPrimartyBenchTrackCodesObject = createSelector(
  [benchCodesData, benchData],
  (codes, bench) => {
    if (bench.primaryBenchId) {
      const trackCodesSync = get(bench.trackCodes, bench.primaryBenchId, [])
      const trackCodesSyncArr = (trackCodesSync || []).filter(el =>
        isObject(el),
      )
      const trackCodesArr = get(codes.trackCodes, [bench.primaryBenchId], [])

      const res = {}

      trackCodesArr.forEach(el => {
        const findElem = trackCodesSyncArr.find(elem => elem.code === el.code)

        const pendingUpdate = findElem ? findElem.pendingUpdate : true

        res[el.packageId] = Object.assign(el, { pendingUpdate })
      })

      return res
    }
    return {}
  },
)

export const getPrimaryBenchTrackCodesArchive = createSelector(
  [benchCodesData, benchData],
  (codes, bench) => {
    if (bench.primaryBenchId) {
      return get(codes, `trackCodesArchive[${bench.primaryBenchId}]`, [])
    }
    return []
  },
)

export const getPrimaryBenchTrackCodesArchiveObject = createSelector(
  [benchCodesData, benchData],
  (codes, bench) => {
    if (bench.primaryBenchId) {
      const res = {}

      const oldCodes = get(
        codes,
        `trackCodesArchive[${bench.primaryBenchId}]`,
        [],
      )

      oldCodes.forEach(el => {
        res[el.packageId] = el
      })

      return res
    }

    return {}
  },
)

export const getPrimaryBenchPackageSearchFunc = createSelector(
  [benchCodesData, benchData],
  (codes, bench) => packageId => {
    const trackCodesArchive = get(
      codes,
      `trackCodesArchive[${bench.primaryBenchId}]`,
      [],
    )
    const trackCodes = get(codes, `trackCodes[${bench.primaryBenchId}]`, [])

    let packageDetails = find(trackCodes, item => item.packageId === packageId)

    if (!packageDetails) {
      packageDetails = find(
        trackCodesArchive,
        item => item.packageId === packageId,
      )
    }

    if (!packageDetails) {
      return null
    }

    return packageDetails
  },
)
