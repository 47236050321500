import React, { useState } from 'react'
import PT from 'prop-types'

import Typography from '@material-ui/core/Typography'

import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Container from '@material-ui/core/Container'

import Button from '@material-ui/core/Button'
import Icon from '@mdi/react'
import { mdiShare, mdiDelete, mdiPlus } from '@mdi/js'

import Grid from '@material-ui/core/Grid'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'

import { orderBy } from 'lodash'

import { ConfirmModal, AddEntryCodeModal } from 'Components/Blocks'

import i18n from 'I18N'

import { ShareEntryCodeModal } from './innerBlocks'

import { useStyles } from './styles'

const EntryCodeBlock = ({
  item,
  classes,
  onShareBtnClick,
  onDeleteBtnClick,
}) => {
  const handleShareBtnClick = () => {
    onShareBtnClick(item)
  }

  const handleDeleteBtnClick = () => {
    onDeleteBtnClick(item)
  }

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <List className={classes.list} disablePadding="true">
          <ListItem alignItems="flex-start">
            <Grid container>
              <Grid item>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Typography variant="body1" color="textPrimary" component="p">
                    {i18n.t('entryCodesScreen.entryCodeBlock.nameField', {
                      name: item.name,
                    })}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {i18n.t('entryCodesScreen.entryCodeBlock.codeField', {
                      code: item.code,
                    })}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </ListItem>

          <ListItemSecondaryAction className={classes.cardListActionContainer}>
            <Button
              size="small"
              className={classes.button}
              startIcon={
                <Icon path={mdiShare} size="16px" className={classes.icon} />
              }
              onClick={handleShareBtnClick}
            >
              {i18n.t('entryCodesScreen.entryCodeBlock.shareBtnLabel')}
            </Button>

            <Button
              size="small"
              className={classes.button}
              startIcon={
                <Icon path={mdiDelete} size="16px" className={classes.icon} />
              }
              onClick={handleDeleteBtnClick}
            >
              {i18n.t('entryCodesScreen.entryCodeBlock.deleteBtnLabel')}
            </Button>
          </ListItemSecondaryAction>
        </List>
      </CardContent>
    </Card>
  )
}

const EntryCodes = ({
  entryCodes,
  benchId,
  user,
  onEntryCodeCreate,
  onEntryCodeDelete,
}) => {
  const classes = useStyles()

  const [itemToShare, setItemToShare] = useState(null)
  const [itemToDelete, setItemToDelete] = useState(null)
  const [isAddEntryCodeModalOpen, setIsAddEntryCodeModalOpen] = useState(null)
  const [isRequest, setIsRequest] = useState(false)

  const handleShareItemBtnClick = item => {
    setItemToShare(item)
  }

  const handleShareModalClose = () => {
    setItemToShare(null)
  }

  const handleDeleteItemBtnClick = item => {
    setItemToDelete(item)
  }

  const handleDeleteItemModalClose = () => {
    setItemToDelete(null)
  }

  const handleDeleteItemModalConfirm = () => {
    setIsRequest(true)
    onEntryCodeDelete(benchId, itemToDelete.id, isSuccess => {
      setIsRequest(false)

      if (isSuccess) {
        setItemToDelete(null)
      }
    })
  }

  const handleAddEntryCodeBtnClick = () => {
    setIsAddEntryCodeModalOpen(true)
  }

  const handleAddEntryCodeModalClose = () => {
    setIsAddEntryCodeModalOpen(false)
  }

  const handleAddEntryCodeModalConfirm = ({ code, name }) => {
    setIsRequest(true)
    onEntryCodeCreate(
      benchId,
      code,
      name,
      // addExpirationDate ? moment(expDate).format() : null,
      null,
      isSuccess => {
        setIsRequest(false)

        if (isSuccess) {
          setIsAddEntryCodeModalOpen(false)
        }
      },
    )
  }

  const orderedEntryCodes = orderBy(entryCodes || [], 'name', 'asc')

  return (
    <>
      <Container>
        <ShareEntryCodeModal
          isOpen={itemToShare !== null}
          item={itemToShare}
          user={user}
          onClose={handleShareModalClose}
        />
        <ConfirmModal
          isRequest={isRequest}
          isOpen={itemToDelete !== null}
          title={i18n.t('entryCodesScreen.deleteModal.header', {
            name: itemToDelete ? itemToDelete.name : '',
          })}
          confirmBtnText={i18n.t(
            'entryCodesScreen.deleteModal.buttons.confirm',
          )}
          cancelBtnText={i18n.t('entryCodesScreen.deleteModal.buttons.cancel')}
          onClose={handleDeleteItemModalClose}
          onConfirm={handleDeleteItemModalConfirm}
        />
        <AddEntryCodeModal
          isOpen={isAddEntryCodeModalOpen}
          isRequest={isRequest}
          onClose={handleAddEntryCodeModalClose}
          onConfirm={handleAddEntryCodeModalConfirm}
        />

        <Box display="flex" alignItems="center" className={classes.topBar}>
          <Typography variant="h5">
            {i18n.t('entryCodesScreen.header')}
          </Typography>

          <Box display="flex" flexGrow={1} justifyContent="flex-end">
            <Button
              variant="contained"
              color="secondary"
              endIcon={<Icon path={mdiPlus} size="24px" color="white" />}
              onClick={handleAddEntryCodeBtnClick}
            >
              {i18n.t('entryCodesScreen.addNewBtnLabel')}
            </Button>
          </Box>
        </Box>

        {orderedEntryCodes.length > 0 ? (
          <>
            <Box className={classes.sectionHeader}>
              {i18n.t('entryCodesScreen.sectionNames.active')}
            </Box>
            {entryCodes.map(item => (
              <EntryCodeBlock
                classes={classes}
                key={item.id}
                item={item}
                onShareBtnClick={handleShareItemBtnClick}
                onDeleteBtnClick={handleDeleteItemBtnClick}
              />
            ))}
          </>
        ) : (
          <>
            <Typography variant="h5">
              {i18n.t('entryCodesScreen.emptyBlock.text')}
              <Typography variant="subtitle1">
                {i18n.t('entryCodesScreen.emptyBlock.subText')}
              </Typography>
            </Typography>
          </>
        )}
      </Container>
    </>
  )
}

EntryCodes.propTypes = {
  entryCodes: PT.array,
  benchId: PT.string,
  user: PT.object,
  onEntryCodeCreate: PT.func,
  onEntryCodeDelete: PT.func,
}

export default EntryCodes
