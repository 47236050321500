import { createSelector } from 'reselect'
import { groupBy, get, orderBy, isEmpty, last } from 'lodash'
import moment from 'moment'
import i18n from 'I18N'

const benchData = store => store.bench

export const getAllBenches = createSelector(
  benchData,
  data => data.benches,
)

export const getPrimaryBench = createSelector(
  benchData,
  data => data.benches[data.primaryBenchId] || null,
)

export const getPrimaryBenchId = createSelector(
  benchData,
  data => data.primaryBenchId,
)

export const getPrimaryBenchOwnerCode = createSelector(
  benchData,
  data => {
    if (isEmpty(data.ownerCodes[data.primaryBenchId])) return {}
    const newData = data.ownerCodes[data.primaryBenchId]

    newData.pendingUpdate = last(
      data.ownerCodesStatus[data.primaryBenchId],
    ).pendingUpdate
    return newData
  },
)

export const getAllEvents = createSelector(
  benchData,
  data => data.events,
)

export const getAllPrimaryBenchEvents = createSelector(
  benchData,
  data => data.allEvents[data.primaryBenchId] || [],
)

export const getPrimaryBenchEvents = createSelector(
  benchData,
  data =>
    get(data.allEvents, data.primaryBenchId, []).filter(el => {
      return (
        el.eventTime >
          moment()
            .startOf('day')
            .valueOf() &&
        el.eventTime <
          moment()
            .endOf('day')
            .valueOf()
      )
    }),
)

export const getPrimaryBenchEventsByDays = createSelector(
  benchData,
  data => {
    const groupedByDay =
      data.allEvents &&
      groupBy(
        orderBy(
          data.allEvents[data.primaryBenchId],
          item => item.eventTime || item.time,
          'desc',
        ),
        item => {
          return moment(item.eventTime || item.time).format('MM/DD/YY')
        },
      )

    const sections =
      groupedByDay &&
      Object.keys(groupedByDay).map(key => {
        let title = key

        if (moment().isSame(moment(key), 'day')) {
          title = i18n.t('activityScreen.labels.today')
        } else if (
          moment()
            .subtract({ days: 1 })
            .isSame(moment(key), 'day')
        ) {
          title = i18n.t('activityScreen.labels.yesterday')
        } else {
          title = `${moment(key, 'MM/DD/YY').format('MM/DD/YY')} ${i18n.t(
            'activityScreen.layouts.header',
          )}`
        }

        return {
          title,
          data: groupedByDay[key],
        }
      })

    return sections || []
  },
)

export const getPrimaryBenchPhoto = createSelector(
  benchData,
  data => data.photos[data.primaryBenchId] || null,
)

export const getIsWaitingForBenchPhoto = createSelector(
  benchData,
  data => data.waitingForBenchPhoto,
)
