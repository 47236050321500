import firebase from 'firebase/app'
import 'firebase/database'

import { Observable } from 'rxjs/Observable'

import { pick } from 'lodash'

import i18n from 'I18N'

import SubscriptionManager from '../SubscriptionsManager'

const errorMessages = i18n.t('firebaseErrors.bench')

class BenchService {
  constructor() {
    this.benchStateSources = {}
  }

  /**
   * Add a custom message to the error object
   */
  getErrorWithMessage = error => {
    return {
      ...error,
      message: errorMessages[error.code] || error.message,
    }
  }

  /**
   * Subscribe on user's benches list updates
   */
  onUserBenchListUpdate = userId => {
    try {
      const path = `users/${userId}/benches`
      const benchListRef = firebase.database().ref(path)

      SubscriptionManager.addSubscriptionRoute(path)

      const onBenchAdd = Observable.create(observer => {
        benchListRef.on(
          'child_added',
          dataSnapshot => {
            if (dataSnapshot) observer.next(dataSnapshot.key)
          },
          error => {
            console.warn('Error onUserBenchListUpdate.onBenchAdd', error)
            observer.error(error)
          },
        )
      })

      const onBenchRemove = Observable.create(observer => {
        benchListRef.on(
          'child_removed',
          dataSnapshot => {
            if (dataSnapshot) observer.next(dataSnapshot.key)
          },
          error => {
            console.warn('Error onUserBenchListUpdate.onBenchRemove', error)
            observer.error(error)
          },
        )
      })

      return {
        onBenchAdd,
        onBenchRemove,
      }
    } catch (error) {
      throw this.getErrorWithMessage(error)
    }
  }

  /**
   * Unsubscribe from user's benches list updates
   */
  onUserBenchListUpdateUnsibscribe = userId => {
    try {
      const benchListRef = firebase.database().ref(`users/${userId}/benches`)

      benchListRef.off()
    } catch (error) {
      throw this.getErrorWithMessage(error)
    }
  }

  /**
   * Subscribe on bench owner code updates
   */
  benchOwnerCode = (benchId, userId) => {
    try {
      const path = `ownerCodes/${benchId}/${userId}`
      const ownerCodeRef = firebase.database().ref(path)

      SubscriptionManager.addSubscriptionRoute(path)

      return Observable.create(observer => {
        ownerCodeRef.on(
          'value',
          dataSnapshot => {
            if (dataSnapshot)
              observer.next({
                benchId,
                val: dataSnapshot.val(),
              })
          },
          error => {
            console.warn('Error benchOwnerCodeSubscribe', error)
            observer.error(error)
          },
        )
      })
    } catch (error) {
      throw this.getErrorWithMessage(error)
    }
  }

  /**
   * Subscribe on bench owner codes list updates
   */
  benchOwnersCodeStatus = benchId => {
    try {
      const path = `benches/${benchId}/ownerCodes`
      const ownerCodeRef = firebase.database().ref(path)

      SubscriptionManager.addSubscriptionRoute(path)

      return Observable.create(observer => {
        ownerCodeRef.on(
          'value',
          dataSnapshot => {
            if (dataSnapshot)
              observer.next({
                benchId,
                val: dataSnapshot.val(),
              })
          },
          error => {
            console.warn('Error benchOwnerCodeSubscribe', error)
            observer.error(error)
          },
        )
      })
    } catch (error) {
      throw this.getErrorWithMessage(error)
    }
  }

  /**
   * Subscribe on bench entry codes list updates
   */
  benchEntryCode = benchId => {
    try {
      const path = `benches/${benchId}/entryCodes`
      const ownerCodeRef = firebase.database().ref(path)

      SubscriptionManager.addSubscriptionRoute(path)
      return Observable.create(observer => {
        ownerCodeRef.on(
          'value',
          dataSnapshot => {
            if (dataSnapshot)
              observer.next({
                benchId,
                val: dataSnapshot.val(),
              })
          },
          error => {
            console.warn('Error benchOwnerCodeSubscribe', error)
            observer.error(error)
          },
        )
      })
    } catch (error) {
      throw this.getErrorWithMessage(error)
    }
  }

  /**
   * Subscribe on bench package codes list updates
   */
  benchTrackCode = benchId => {
    try {
      const path = `benches/${benchId}/packageCodes`
      const ownerCodeRef = firebase.database().ref(path)

      SubscriptionManager.addSubscriptionRoute(path)

      return Observable.create(observer => {
        ownerCodeRef.on(
          'value',
          dataSnapshot => {
            if (dataSnapshot)
              observer.next({
                benchId,
                val: dataSnapshot.val(),
              })
          },
          error => {
            console.warn('Error benchOwnerCodeSubscribe', error)
            observer.error(error)
          },
        )
      })
    } catch (error) {
      throw this.getErrorWithMessage(error)
    }
  }

  /**
   * Subscribe on bench state updates
   */
  getBenchStateSourceForBenchId = benchId => {
    let benchStateSource

    if (benchId) {
      // Check if this source has already been created
      if (this.benchStateSources && this.benchStateSources[benchId]) {
        benchStateSource = this.benchStateSources[benchId]
      } else {
        const path = `benches/${benchId}`
        const benchStateRef = firebase.database().ref(path)

        SubscriptionManager.addSubscriptionRoute(path)

        benchStateSource = Observable.create(observer =>
          benchStateRef.on(
            'value',
            dataSnapshot => {
              observer.next(dataSnapshot)
            },
            error => {
              console.warn('Error retrieving bench state ', benchId, error)
              observer.error(error)
            },
          ),
        )

        this.benchStateSources.benchId = benchStateSource
      }
    } else {
      console.warn('No bench ID provided for bench state listening')
    }

    return benchStateSource
  }

  /**
   * Unsubscribe from bench state updates
   */
  benchStateUpdateUnsubscribe = benchId => {
    try {
      const benchStateRef = firebase.database().ref(`benches/${benchId}/state`)

      benchStateRef.off('value')
    } catch (error) {
      throw this.getErrorWithMessage()
    }
  }

  /**
   * Unsubscribe from owner codes updates
   */
  benchOwnerCodeUnsubscribe = (benchId, userId) => {
    try {
      const ownerCodeRef = firebase
        .database()
        .ref(`ownerCodes/${benchId}/${userId}`)

      ownerCodeRef.off()
    } catch (error) {
      throw this.getErrorWithMessage()
    }
  }

  /**
   * Update bench data
   */
  updateBenchFields = async (benchId, update) => {
    // add here more customizable fields
    const customizableFields = ['name', 'preferences']
    const updateObject = pick(update, customizableFields)

    const benchRef = firebase.database().ref(`benches/${benchId}`)

    await benchRef.update(updateObject)
  }

  /**
   * Update bench prefferences
   */
  updateBenchPreferences = (benchId, update) => {
    const benchUpdate = {
      preferences: update,
    }

    return this.updateBenchFields(benchId, benchUpdate)
  }

  /**
   * Create a new lock request
   */
  lockBench = (benchId, userId) => {
    this.sendRequest('lock', benchId, userId)
  }

  /**
   * Create a new unlock request
   */
  unlockBench = (benchId, userId) => {
    this.sendRequest('unlock', benchId, userId)
  }

  /**
   * Create a new photo request
   */
  takePicture = (benchId, userId) => {
    this.sendRequest('take_picture', benchId, userId)
  }

  /**
   * Create a new request
   */
  sendRequest = async (requestType, benchId, userId) => {
    try {
      const benchRef = firebase.database().ref(`requests/${benchId}`)

      const res = await benchRef.push({
        time: firebase.database.ServerValue.TIMESTAMP,
        type: requestType,
        requestedBy: userId,
      })

      return res
    } catch (error) {
      throw this.getErrorWithMessage(error, {
        func: 'updateBenchPreferences',
        args: { benchId, userId },
      })
    }
  }
}

export default new BenchService()
