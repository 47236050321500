import React from 'react'

import { Formik } from 'formik'
import { toast } from 'react-toastify'

import PT from 'prop-types'

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Box,
  Button,
  TextField,
  ListItem,
  List,
  ListItemAvatar,
  Typography,
  MenuItem,
} from '@material-ui/core'

import i18n from 'I18N'

import { useStyles } from './styles'

const COLORS_LIST = i18n.t(
  'preOrderDashboard.editOrderDetailsModal.benchColors',
)

const EditBenchPreorderDetailsModal = ({
  isOpen,
  isRequest,
  preorderDetails,
  onClose,
  onSubmit,
}) => {
  const classes = useStyles()
  const MAX_BENCHES_IN_ORDER = 3

  const handleCancel = () => {
    onClose()
  }

  const handleConfirm = nextPreorderDetails => {
    const currentBenchesQuantity = Object.values(nextPreorderDetails).reduce(
      (a, b) => a + b,
      0,
    )

    if (currentBenchesQuantity > MAX_BENCHES_IN_ORDER) {
      toast.error(
        i18n.t(
          'preOrderDashboard.editOrderDetailsModal.errorMessages.tooMuchBenches',
          {
            maxAmount: MAX_BENCHES_IN_ORDER,
          },
        ),
      )

      return
    }

    onSubmit({
      ...preorderDetails,
      ...nextPreorderDetails,
    })
  }

  const getColorID = colorVal => `${colorVal}BenchesAmount`

  const getImage = colorVal =>
    // eslint-disable-next-line
    require(`../../../Assets/benchImages/${colorVal}-bench.png`)

  const initialValues = {}

  COLORS_LIST.forEach(colorData => {
    const colorId = getColorID(colorData.value)
    initialValues[colorId] = preorderDetails[colorId] || 0
  })

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        {i18n.t('preOrderDashboard.editOrderDetailsModal.title')}
      </DialogTitle>
      <DialogContent>
        <Formik initialValues={initialValues} onSubmit={handleConfirm}>
          {({
            values = {},
            errors,
            touched,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => {
            const handleInputKeyDown = e => {
              if (e.key === 'Enter') {
                handleSubmit()
              }
            }

            const currentBenchesQuantity = Object.values(values).reduce(
              (a, b) => a + b,
              0,
            )

            return (
              <>
                <Typography className={classes.mainColor} variant="h6">
                  {i18n.t(
                    'preOrderDashboard.editOrderDetailsModal.dueTodayLabel',
                    { sum: 0 },
                  )}
                </Typography>
                {i18n
                  .t('preOrderDashboard.editOrderDetailsModal.infoLines')
                  .map(line => (
                    <Typography className={classes.mainColor}>
                      {line}
                    </Typography>
                  ))}
                <Typography className={classes.mainColor}>
                  {i18n.t(
                    'preOrderDashboard.editOrderDetailsModal.estimatedDeliveryLabel',
                    { estDelivery: preorderDetails.estimatedDelivery },
                  )}
                </Typography>
                <Typography className={classes.mainColor} variant="h6">
                  {i18n.t(
                    'preOrderDashboard.editOrderDetailsModal.priceLabel',
                    {
                      preorderPrice: preorderDetails.slateBenchPrice,
                    },
                  )}
                </Typography>
                <Typography className={classes.mainColor}>
                  {i18n.t(
                    'preOrderDashboard.editOrderDetailsModal.chargedLabel',
                  )}
                </Typography>
                <Typography className={classes.mainColor} variant="subtitle2">
                  {i18n.t(
                    'preOrderDashboard.editOrderDetailsModal.limitLabel',
                    {
                      maxBenchesAmount:
                        preorderDetails.maxBenchesAmountInPreorder || 3,
                    },
                  )}
                </Typography>
                <Box
                  style={{
                    width: 'max-content',
                    margin: 'auto',
                  }}
                >
                  <List>
                    {COLORS_LIST.map(colorData => {
                      const inputId = getColorID(colorData.value)
                      const inputMaxValue = Math.max(
                        values[inputId] +
                          MAX_BENCHES_IN_ORDER -
                          currentBenchesQuantity,
                        values[inputId],
                      )

                      return (
                        <ListItem>
                          <ListItemAvatar>
                            <img
                              alt={colorData.title}
                              src={getImage(colorData.value)}
                              style={{
                                width: '150px',
                              }}
                            />
                          </ListItemAvatar>
                          <TextField
                            error={errors[inputId] && touched[inputId]}
                            fullWidth
                            helperText={errors[inputId]}
                            margin="dense"
                            id={inputId}
                            label={colorData.title}
                            style={{
                              marginLeft: '16px',
                            }}
                            select
                            variant="outlined"
                            value={values[inputId]}
                            onBlur={handleBlur}
                            onChange={({ target }) => {
                              setFieldValue(inputId, target.value)
                            }}
                            onKeyDown={handleInputKeyDown}
                          >
                            {new Array(inputMaxValue + 1)
                              .fill(0)
                              .map((item, index) => (
                                /* eslint-disable-next-line react/no-array-index-key */
                                <MenuItem value={index} key={index}>
                                  {index}
                                </MenuItem>
                              ))}
                          </TextField>
                        </ListItem>
                      )
                    })}
                  </List>
                </Box>

                <DialogActions>
                  <Button onClick={handleCancel} color="primary">
                    {i18n.t(
                      'preOrderDashboard.editOrderDetailsModal.cancelBtnLabel',
                    )}
                  </Button>
                  <Button
                    color="secondary"
                    disabled={isRequest}
                    variant="contained"
                    onClick={handleSubmit}
                  >
                    {i18n.t(
                      'preOrderDashboard.editOrderDetailsModal.submitBtnLabel',
                    )}
                  </Button>
                </DialogActions>
              </>
            )
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

EditBenchPreorderDetailsModal.propTypes = {
  isRequest: PT.bool,
  onSubmit: PT.func,
}

export { EditBenchPreorderDetailsModal }
