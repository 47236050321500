import { takeLatest } from 'redux-saga/effects'

// action types
import {
  FETCH_PACKAGES_LIST,
  FETCH_PACKAGES_ARCHIVE_LIST,
} from 'Redux/actions/benchCodes'

// sagas
import onFetchPackagesListSucess from './onFetchPackagesListSuccess'
import onFetchPackagesArchiveListSuccess from './onFetchPackagesArchiveListSuccess'

function* packagesSaga() {
  yield takeLatest(FETCH_PACKAGES_LIST.SUCCESS, onFetchPackagesListSucess)
  yield takeLatest(
    FETCH_PACKAGES_ARCHIVE_LIST.SUCCESS,
    onFetchPackagesArchiveListSuccess,
  )
}

export default packagesSaga
