import React from 'react'

import { Route } from 'react-router-dom'

import AppLayout from './PreorderAppLayout'

const PreorderAppLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <AppLayout>
          <Component {...matchProps} />
        </AppLayout>
      )}
    />
  )
}

export default PreorderAppLayoutRoute
