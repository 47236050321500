import React, { useEffect } from 'react'

import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { get } from 'lodash'

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import { getPreorder } from 'Redux/selectors/preOrder'
import { getUserAuthenticated } from 'Redux/selectors/auth'
import { getUser } from 'Redux/selectors/user'

import {
  fetchUsersPreorders,
  fetchPreordersConfig,
} from 'Redux/actions/preOrder'

import * as routes from 'Constants/routes'
import UserAccountSetupStep from 'Constants/userAccountSetupState'
import PreorderStatus from 'Constants/PreorderStatus'

import AppLayoutRoute from './Layouts/AppLayout'
import AuthenticationLayoutRoute from './Layouts/AuthenticationLayout'
import PreorderAppLayoutRoute from './Layouts/PreorderAppLayout'

import Dashboard from './Pages/Dashboard'
import Activity from './Pages/Activities'
import Deliveries from './Pages/Deliveries'
import EntryCodes from './Pages/EntryCodes'
import AccountSettings from './Pages/Settings'
import TermsOfServices from './Pages/TermsOfServices'
import PrivacyPolicy from './Pages/PrivacyPolicy'

import PreOrderLandingPage from './Pages/PreOrder/Landing'
import PutAnOrder from './Pages/PreOrder/PutAnOrder'
import PreOrderSignUp from './Pages/PreOrder/SignUp'

import Login from './Pages/Login'
import SetupYourAccountPage from './Pages/SetupYourAccount'
import SplashScreen from './Pages/SplashScreen'

const Routes = ({
  isAuthenticated,
  user,
  isAppReady,
  onFetchUsersPreorders,
  onFetchProdersConfig,
  usersPreorder,
}) => {
  const accountSetupStep = get(user || {}, 'setupState', null)

  useEffect(() => {
    if (accountSetupStep === UserAccountSetupStep.preOrder) {
      onFetchUsersPreorders()
      onFetchProdersConfig()
    }
  }, [accountSetupStep])

  const getSwitch = () => {
    if ((isAuthenticated && accountSetupStep === null) || !isAppReady) {
      return <SplashScreen />
    }

    if (!isAuthenticated || !user) {
      return (
        <>
          <Route exact path={routes.preOrder} component={PreOrderSignUp} />
          <AuthenticationLayoutRoute
            exact
            path={routes.login}
            component={Login}
          />
          <Route path={routes.termsOfServices} component={TermsOfServices} />
          <Route component={PrivacyPolicy} path={routes.privacyPolicy} />
        </>
      )
    }

    switch (accountSetupStep) {
      case UserAccountSetupStep.completed:
        return (
          <>
            <Route exact path={routes.home}>
              <AppLayoutRoute component={Dashboard} />
            </Route>
            <Route path={routes.activities}>
              <AppLayoutRoute component={Activity} />
            </Route>
            <Route path={routes.trackCodes}>
              <AppLayoutRoute component={Deliveries} />
            </Route>
            <Route path={routes.entryCodes}>
              <AppLayoutRoute component={EntryCodes} />
            </Route>
            <Route path={routes.accountAndSettings}>
              <AppLayoutRoute component={AccountSettings} />
            </Route>
          </>
        )
      case UserAccountSetupStep.preOrder:
        return (
          <>
            <Route exact path={routes.home}>
              <PreorderAppLayoutRoute component={PreOrderLandingPage} />
            </Route>
            {usersPreorder.orderStatus === PreorderStatus.pending ? (
              <Redirect to={routes.home} />
            ) : (
              <Route path={routes.preOrder} component={PutAnOrder} />
            )}
          </>
        )
      default:
        return (
          <>
            <Route exact path={routes.home} component={SetupYourAccountPage} />
          </>
        )
    }
  }

  return (
    <Router>
      <Switch>{getSwitch()}</Switch>
    </Router>
  )
}

const actions = {
  onFetchUsersPreorders: fetchUsersPreorders,
  onFetchProdersConfig: fetchPreordersConfig,
}

const selector = createStructuredSelector({
  isAuthenticated: getUserAuthenticated,
  user: getUser,
  usersPreorder: getPreorder,
})

export default connect(
  selector,
  actions,
)(Routes)
