import * as Yup from 'yup'
import i18n from 'I18N'

const errorMessages = i18n.t('changeBenchOwnerScreen.validation.errorMessages')

const validationSchema = Yup.object().shape({
  // current: Yup.string()
  //   .min(5, errorMessages.tooShort)
  //   .max(12, errorMessages.tooLong),
  new: Yup.string()
    .min(5, errorMessages.tooShort)
    .max(12, errorMessages.tooLong)
    .matches(/^[\w]+$/, errorMessages.mustHaveAlphaNum)
    .required(
      i18n.t('changeBenchOwnerScreen.validation.errorMessages.required', {
        code: 'New Owner Code',
      }),
    ),
  confirm: Yup.string()
    .min(5, errorMessages.tooShort)
    .max(12, errorMessages.tooLong)
    .matches(/^[\w]+$/, errorMessages.mustHaveAlphaNum)
    .oneOf([Yup.ref('new'), null], errorMessages.mustBeSame)
    .required(
      i18n.t('changeBenchOwnerScreen.validation.errorMessages.required', {
        code: 'Code Confirmation',
      }),
    ),
})

export { errorMessages, validationSchema }
