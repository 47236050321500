import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { getPrimaryBenchId } from 'Redux/selectors/bench'
import { getPrimaryBenchEntryCodes } from 'Redux/selectors/benchCodes'
import { getUser } from 'Redux/selectors/user'

import { createEntryCode, deleteEntryCode } from 'Redux/actions/benchCodes'

import Component from './Component'

const actions = {
  onEntryCodeCreate: createEntryCode,
  onEntryCodeDelete: deleteEntryCode,
}

const selector = createStructuredSelector({
  entryCodes: getPrimaryBenchEntryCodes,
  benchId: getPrimaryBenchId,
  user: getUser,
})

export default connect(
  selector,
  actions,
)(Component)
