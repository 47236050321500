import React, { useMemo, useState } from 'react'
import PT from 'prop-types'

import {
  Typography,
  ListItemAvatar,
  ListItemText,
  Checkbox,
  Box,
  Card,
  CardContent,
  Container,
  List,
  ListItem,
  ListItemSecondaryAction,
  Button,
} from '@material-ui/core'

import Icon from '@mdi/react'
import IconButton from '@material-ui/core/IconButton'
import {
  mdiChevronRight,
  mdiLockOpen,
  mdiBarcode,
  mdiLockAlert,
  mdiDialpad,
  mdiLock,
  mdiAccountLock,
  mdiCellphoneLock,
  mdiHelpBox,
  mdiCamera,
  mdiDelete,
  mdiPencilBoxMultiple,
  mdiCancel,
  mdiExpandAll,
} from '@mdi/js'

import Avatar from '@material-ui/core/Avatar'

import moment from 'moment'
import { get, isArray } from 'lodash'

import i18n from 'I18N'

import EventType from 'Constants/benchEventTypes'
import BenchCodeType from 'Constants/benchCodeTypes'

import {
  ConfirmModal,
  EventDetailsModal,
  DeliveryDetailsModal,
} from 'Components/Blocks'

import { useStyles } from './styles'

const EventBlock = ({
  event,
  classes,
  trackCodes,
  trackCodeArchive,
  entryCodes,
  isEditModeOn = false,
  itemChecked = false,
  onItemCheckboxClick,
  onItemClick,
}) => {
  const getPackageName = id => {
    const findTrackCode = trackCodes[id]
    const findTrackCodeArch = trackCodeArchive[id]

    let descriptionValue
    const arr = findTrackCodeArch || findTrackCode

    if (!arr) {
      descriptionValue = {
        name: null,
        type: 'package_delivered_unknown',
      }
    } else if (arr.notes) {
      descriptionValue = {
        name: arr.notes,
        type: 'package_delivered_notes',
      }
    } else if (arr.carrier && arr.carrier !== 'unknown') {
      descriptionValue = {
        name: arr.carrier,
        type: 'package_delivered_carrier',
      }
    } else {
      descriptionValue = {
        name: null,
        type: 'package_delivered_unknown',
      }
    }

    return descriptionValue
  }

  const getEntryName = id => {
    const findEntryCode = entryCodes[id]

    const eventName = { name: '' }
    if (findEntryCode && findEntryCode.name) {
      const { name } = findEntryCode
      eventName.name = name
    }
    return eventName
  }

  const handleItemCheckboxClick = () => {
    onItemCheckboxClick(event.id)
  }

  const [
    eventTitle,
    eventDescription,
    eventIcon,
    eventItemName,
    time,
    eventAvatarClass,
  ] = useMemo(() => {
    let title = ''
    let description = ''
    let icon = mdiHelpBox
    let itemName = null

    let eventTime = null
    let avatarClass = classes.avatarGreen

    switch (event.type) {
      case EventType.LOCK_FAILED:
        title = 'lock_failed'
        description = 'lock_failed'
        icon = mdiLockAlert
        eventTime = event.time

        break
      case EventType.INVALID_CODE_ENTRY:
        title = 'invalid_code'
        description = 'invalid_code'
        icon = mdiDialpad
        eventTime = event.time
        avatarClass = classes.avatarRed

        break
      case EventType.PICTURE_TAKEN:
        title = 'picture_taken'
        description = 'picture_taken'
        icon = mdiCamera
        eventTime = event.time

        break
      case EventType.LOCKED:
        title = 'locked'
        description = 'locked'
        icon = mdiLock
        eventTime = event.time

        break
      case EventType.MOBILE_LOCK:
        title = 'mobile_lock'
        description = 'mobile_lock'
        icon = mdiAccountLock
        eventTime = event.time

        break
      case EventType.MOBILE_UNLOCK:
        title = 'mobile_unlock'
        description = 'unlockedByMobile'
        icon = mdiCellphoneLock
        eventTime = event.time

        break
      case EventType.UNLOCKED:
        switch (event.codeType) {
          case BenchCodeType.PACKAGE: {
            const { type, name } = getPackageName(event.packageId)

            title = type
            description = 'package_delivered'
            icon = mdiBarcode
            eventTime = event.time
            itemName = name

            break
          }
          case BenchCodeType.ENTRY: {
            const { name } = getEntryName(event.entryCodeId)

            title = 'unlockedEntry'
            description = 'unlockedEntry'
            icon = mdiAccountLock
            eventTime = event.time
            itemName = name

            break
          }
          case BenchCodeType.OWNER:
            title = 'owner'
            description = 'owner'
            icon = mdiAccountLock
            eventTime = event.time

            break
          default:
            break
        }
        break
      case EventType.UNKNOWN_DELIVERY:
        title = 'unknown'
        description = 'unknown'
        icon = mdiHelpBox
        eventTime = event.time
        avatarClass = classes.avatarYellow

        break
      case EventType.LID_OPEN:
        title = 'lid_open'
        description = 'lid_open'
        icon = mdiLockOpen
        eventTime = event.time

        break
      default:
        console.warn('UNKNOWN EVENT TYPE', event.type)
        break
    }

    return [title, description, icon, itemName, eventTime, avatarClass]
  }, [])

  const title = `${eventItemName ? `${eventItemName} ` : ''}${i18n.t(
    `activityScreen.type.${eventTitle}`,
  )}`
  const description = i18n.t(`activityScreen.description.${eventDescription}`)

  const eventTime = moment(time).format('h:mm a')

  const mediaUrls = get(event, 'mediaUrls', null)
  const hasMediaUrls = isArray(mediaUrls) && mediaUrls.length > 0

  const handleItemClick = () => {
    if (!hasMediaUrls) return

    onItemClick({
      ...event,
      title,
      description,
    })
  }

  let renderMediaIcon = !isEditModeOn && hasMediaUrls

  if (
    event.type === EventType.UNLOCKED &&
    event.codeType === BenchCodeType.PACKAGE
  ) {
    renderMediaIcon = renderMediaIcon && trackCodeArchive[event.packageId]
  }

  return (
    <Card
      className={classes.card}
      key={event.id}
      onClick={isEditModeOn ? handleItemCheckboxClick : handleItemClick}
    >
      <CardContent className={classes.cardContent}>
        <List className={classes.list} disablePadding="true">
          <ListItem alignItems="flex-start" button>
            {isEditModeOn && (
              <Box>
                <Checkbox
                  style={{ zIndex: 1 }}
                  checked={itemChecked}
                  onChange={handleItemCheckboxClick}
                />
              </Box>
            )}
            <ListItemAvatar>
              <>
                <Avatar className={eventAvatarClass}>
                  <Icon path={eventIcon} color="white" size="12px" />
                </Avatar>
              </>
            </ListItemAvatar>
            <ListItemText
              primary={title}
              secondary={
                <>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textSecondary"
                  >
                    {description}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {eventTime}
                  </Typography>
                </>
              }
            />
            {renderMediaIcon && (
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="comments">
                  <Icon path={mdiChevronRight} size="24px" />
                </IconButton>
              </ListItemSecondaryAction>
            )}
          </ListItem>
        </List>
      </CardContent>
    </Card>
  )
}

EventBlock.propTypes = {
  event: PT.object,
}

const Activities = ({
  bench,
  eventsByDay,
  events,
  entryCodes,
  trackCodes,
  trackCodeArchive,
  onUserEventDelete,
}) => {
  const classes = useStyles()

  const [isEditModeOn, setIsEditModeOn] = useState(false)
  const [checkedItems, setCheckedItems] = useState({})
  const [
    isEditModeConfirmDeleteModalOpen,
    setIsEditModeConfirmDeleteModalOpen,
  ] = useState(false)
  const [itemToShowDetails, setItemToShowDetails] = useState(null)
  const [deliveryToShowDetails, setDeliveryToShowDetails] = useState(null)

  const handleDeliveryDetailsModalClose = () => {
    setDeliveryToShowDetails(null)
  }

  const handleDetailsModalClose = () => {
    setItemToShowDetails(null)
  }

  const handleItemClick = event => {
    if (
      event.type === EventType.UNLOCKED &&
      event.codeType === BenchCodeType.PACKAGE
    ) {
      const delivery = trackCodeArchive[event.packageId]

      setDeliveryToShowDetails(delivery)
    } else {
      setItemToShowDetails(event)
    }
  }

  const handleItemCheckboxClick = itemId => {
    setCheckedItems({
      ...checkedItems,
      [itemId]: !checkedItems[itemId],
    })
  }

  const handleEditListBtnClick = () => {
    setIsEditModeOn(true)
  }

  const handleEditListCancelBtnClick = () => {
    setIsEditModeOn(false)
  }

  const handleSelectAllBtnClick = () => {
    const nextCheckedItems = {}

    events.forEach(item => {
      nextCheckedItems[item.id] = true
    })

    setCheckedItems(nextCheckedItems)
  }

  const handleDeleteBtnClick = () => {
    setIsEditModeConfirmDeleteModalOpen(true)
  }

  const handleEditModeConfirmDeleteModalConfirmBtnClick = () => {
    setIsEditModeConfirmDeleteModalOpen(false)

    // eslint-disable-next-line
    for (let [key, value] of Object.entries(checkedItems)) {
      if (value) {
        onUserEventDelete(bench.benchId, key)
      }
    }

    setCheckedItems({})
    setIsEditModeOn(false)
  }

  const handleEditModeConfirmDeleteModalCancelBtnClick = () => {
    setIsEditModeConfirmDeleteModalOpen(false)
  }

  const checkedItemsCount = Object.values(checkedItems).filter(
    item => item === true,
  ).length

  return (
    <>
      <Container>
        <ConfirmModal
          isOpen={isEditModeConfirmDeleteModalOpen}
          title={i18n.t('activityScreen.editModeConfirmDeleteModal.header')}
          description={i18n.t(
            'activityScreen.editModeConfirmDeleteModal.subheader',
          )}
          confirmBtnText={i18n.t(
            'activityScreen.editModeConfirmDeleteModal.confirmBtnLabel',
          )}
          cancelBtnText={i18n.t(
            'activityScreen.editModeConfirmDeleteModal.cancelBtnLabel',
          )}
          onClose={handleEditModeConfirmDeleteModalCancelBtnClick}
          onConfirm={handleEditModeConfirmDeleteModalConfirmBtnClick}
        />
        <EventDetailsModal
          isOpen={itemToShowDetails !== null}
          event={itemToShowDetails}
          onClose={handleDetailsModalClose}
        />
        <DeliveryDetailsModal
          isOpen={deliveryToShowDetails !== null}
          delivery={deliveryToShowDetails}
          onClose={handleDeliveryDetailsModalClose}
        />

        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          alignItems="center"
          justifyContent="space-between"
          className={classes.topBar}
        >
          <Typography variant="h5">
            {isEditModeOn
              ? i18n.t('activityScreen.editMode.title', {
                  checkedItemsCount,
                })
              : i18n.t('activityScreen.layouts.header')}
          </Typography>
          <Typography variant="h5">{i18n.t('')}</Typography>

          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            {isEditModeOn ? (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  endIcon={
                    <Icon path={mdiExpandAll} size="24px" color="white" />
                  }
                  style={{
                    marginRight: '16px',
                  }}
                  onClick={handleSelectAllBtnClick}
                >
                  {i18n.t('activityScreen.editMode.selectAllBtnLabel')}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  endIcon={<Icon path={mdiDelete} size="24px" color="white" />}
                  style={{
                    marginRight: '16px',
                  }}
                  onClick={handleDeleteBtnClick}
                >
                  {i18n.t('activityScreen.editMode.deleteBtnLabel')}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  endIcon={<Icon path={mdiCancel} size="24px" color="white" />}
                  style={{
                    marginRight: '16px',
                  }}
                  onClick={handleEditListCancelBtnClick}
                >
                  {i18n.t('activityScreen.editMode.cancelBtnLabel')}
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  endIcon={
                    <Icon
                      path={mdiPencilBoxMultiple}
                      size="24px"
                      color="white"
                    />
                  }
                  onClick={handleEditListBtnClick}
                >
                  {i18n.t('activityScreen.editListBtnLabel')}
                </Button>
              </>
            )}
          </Box>
        </Box>

        {isArray(eventsByDay) && eventsByDay.length > 0 ? (
          eventsByDay.map(day => {
            return (
              <>
                <Box className={classes.sectionHeader}>{day.title}</Box>
                {(day.data || []).map(event => {
                  return (
                    <EventBlock
                      classes={classes}
                      event={event}
                      key={event.id}
                      itemChecked={checkedItems[event.id]}
                      isEditModeOn={isEditModeOn}
                      trackCodes={trackCodes}
                      trackCodeArchive={trackCodeArchive}
                      entryCodes={entryCodes}
                      onItemCheckboxClick={handleItemCheckboxClick}
                      onItemClick={handleItemClick}
                    />
                  )
                })}
              </>
            )
          })
        ) : (
          <ListItem alignItems="flex-start">
            <Typography variant="subtitle1">
              {i18n.t('activityScreen.emptyBlock.text')}
            </Typography>
          </ListItem>
        )}
      </Container>
    </>
  )
}

Activities.propTypes = {
  eventsByDay: PT.array,
  entryCodes: PT.object,
  trackCodes: PT.object,
  trackCodeArchive: PT.object,
}

export default Activities
