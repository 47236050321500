import { makeStyles } from '@material-ui/core/styles'
import { green, red, yellow } from '@material-ui/core/colors'

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  cardContent: {
    padding: '8px 16px !important',
  },
  searchBar: {
    marginTop: 32,
    marginBottom: 8,
  },
  avatarGreen: {
    backgroundColor: green[500],
    width: 28,
    height: 28,
  },
  avatarYellow: {
    backgroundColor: yellow[700],
    width: 28,
    height: 28,
  },
  avatarRed: {
    backgroundColor: red[500],
    width: 28,
    height: 28,
  },
  toolbar: theme.mixins.toolbar,
  title: {
    paddingLeft: 16,
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
      lineHeight: '1.4rem',
    },
  },
  tableActionBar: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    paddingRight: 8,
    backgroundColor: '#f0f3f5',
  },
  tableFooterBar: {
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    paddingLeft: 8,
  },
  topBar: {
    height: 80,
  },
  list: {
    padding: 0,
  },
}))
