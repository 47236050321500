import React from 'react'
import { Formik } from 'formik'

import PT from 'prop-types'

import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'

import { trim } from 'lodash'

import i18n from 'I18N'

import { validationSchema } from './config'

const ReauthenticateModal = ({ isOpen, onClose, onConfirm }) => {
  const handleConfirm = ({ pass }) => {
    if (!pass) return

    const trimmedValue = trim(pass)

    if (!trimmedValue.length) return

    onConfirm(trimmedValue)
  }

  const handleCancel = () => {
    onClose()
  }

  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title">
      <Formik
        initialValues={{
          oldPass: '',
          pass: '',
          passConfirm: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleConfirm}
      >
        {({
          values = {},
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => {
          const handleInputKeyDown = e => {
            if (e.key === 'Enter') {
              handleSubmit()
            }
          }

          return (
            <>
              <DialogContent>
                <DialogContentText>
                  {i18n.t('reauthModal.description')}
                </DialogContentText>
                <TextField
                  autoFocus
                  error={errors.pass && touched.pass}
                  fullWidth
                  helperText={errors.pass}
                  margin="dense"
                  id="pass"
                  label={i18n.t('reauthModal.inputPassPlaceholder')}
                  type="password"
                  variant="outlined"
                  value={values.pass}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onKeyDown={handleInputKeyDown}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCancel} color="primary">
                  {i18n.t('reauthModal.buttons.cancel')}
                </Button>
                <Button
                  onClick={handleSubmit}
                  color="secondary"
                  variant="contained"
                >
                  {i18n.t('reauthModal.buttons.submit')}
                </Button>
              </DialogActions>
            </>
          )
        }}
      </Formik>
    </Dialog>
  )
}

ReauthenticateModal.propTypes = {
  isOpen: PT.bool.isRequired,
  onClose: PT.func.isRequired,
  onConfirm: PT.func.isRequired,
}

export { ReauthenticateModal }
