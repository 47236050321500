export const login = '/'
export const preOrder = '/preorder'
export const termsOfServices = '/terms-of-use'
export const privacyPolicy = '/privacy-policy'

export const createAccount = '/register/create-account'
export const setupYourBench = '/register/setup-bench'
export const enterPairingCode = '/register/enter-pairing-code'
export const benchOwnerCode = '/register/bench-owner-code'
export const finalizeAccount = '/register/finalize-account'
export const confirmResetPassword = '/register/confirm-reset-password'

export const home = '/'
export const trackCodes = '/deliveries'
export const entryCodes = '/entry-codes'
export const accountAndSettings = '/settings'
export const changePassword = '/change-password'
export const changeBenchOwnerCode = '/change-bench-owner-code'

export const howToUse = '/how-to-use'
export const activities = '/events'
export const activityDetail = 'event/:id'
export const deliveryDetail = 'delivery/:id'

export const lockSettings = '/settings/lock'
export const notificationSettings = '/settings/notification'
export const addressSettings = '/settings/address'
