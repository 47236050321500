import qs from 'querystring'
import convert from 'xml-js'

import { helcimApiUrl, apiToken, accountId } from 'Config/helcim'

import { createAsyncAction } from 'Helpers/redux'

import PreOrderService from '../../Services/preOrder/PreOrderService'

import { apiCall, firebaseCall } from './api'

export const CREATE_HELCIM_CUSTOMER = createAsyncAction(
  'pre_order/CREATE_HELCIM_CUSTOMER',
)
export const createCustomer = (customerData, callback) => {
  return apiCall({
    url: helcimApiUrl,
    endpoint: '',
    method: 'POST',
    query: {
      action: 'customerEdit',
      accountId,
      apiToken,
      ...customerData,
    },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    callback,
    types: CREATE_HELCIM_CUSTOMER,
    preProcess: rawBody => qs.stringify(rawBody),
    postProcess: rawResponse => {
      const jsonResponse = convert.xml2js(rawResponse, { compact: true })

      return jsonResponse
    },
  })
}

export const ADD_PREORDER_TO_DB = createAsyncAction(
  'pre_order/ADD_PREORDER_TO_DB',
)
export const addPreOrderToDB = (
  userID,
  customerCode,
  orderDetails,
  callback,
) => {
  return firebaseCall({
    func: PreOrderService.addPreorderData,
    params: [userID, customerCode, orderDetails],
    types: ADD_PREORDER_TO_DB,
    callback,
  })
}

export const UPDATE_PREORDER_DETAILS = createAsyncAction(
  'pre_order/UPDATE_PREORDER_DETAILS',
)
export const updatePreorderDetails = (
  preorderId,
  nextOrderDetails,
  callback,
) => {
  return firebaseCall({
    func: PreOrderService.updatePreorderDetails,
    params: [preorderId, nextOrderDetails],
    types: UPDATE_PREORDER_DETAILS,
    callback,
  })
}

export const UPDATE_PREORDER = createAsyncAction('pre_order/UPDATE_PREORDER')
export const updatePreorder = (preorderId, orderUpdate, callback) => {
  return firebaseCall({
    func: PreOrderService.updatePreorderData,
    params: [preorderId, orderUpdate],
    types: UPDATE_PREORDER,
    callback,
  })
}

export const CANCEL_PREORDER = createAsyncAction('pre_order/CANCEL_PREORDER')
export const cancelPreorder = (
  preorderId,
  helcimCustomerCode,
  userId,
  callback,
) => {
  return firebaseCall({
    func: PreOrderService.cancelPreorder,
    params: [preorderId, helcimCustomerCode, userId],
    types: CANCEL_PREORDER,
    callback,
  })
}

export const ADD_HELCIM_CREDIT_CARD = createAsyncAction(
  'pre_order/ADD_HELCIM_CREDIT_CARD',
)
export const addHelcimCreditCard = (creditCardDetails, callback) => {
  return apiCall({
    url: helcimApiUrl,
    endpoint: '',
    method: 'POST',
    query: {
      action: 'customerCardEdit',
      accountId,
      apiToken,
      ...creditCardDetails,
    },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    callback,
    types: CREATE_HELCIM_CUSTOMER,
    preProcess: rawBody => qs.stringify(rawBody),
    postProcess: rawResponse => {
      const jsonResponse = convert.xml2js(rawResponse, { compact: true })

      return jsonResponse
    },
  })
}

export const FETCH_HELCIM_USER = createAsyncAction(
  'pre_order/FETCH_HELCIM_USER',
)
export const fetchHelcimUser = (customerCode, callback) => {
  return apiCall({
    url: helcimApiUrl,
    endpoint: '',
    method: 'POST',
    query: {
      action: 'customerView',
      accountId,
      apiToken,
      customerCode,
    },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    callback,
    types: FETCH_HELCIM_USER,
    preProcess: rawBody => qs.stringify(rawBody),
    postProcess: rawResponse => {
      const jsonResponse = convert.xml2js(rawResponse, { compact: true })

      return jsonResponse
    },
  })
}

export const FETCH_USERS_PREORDERS = createAsyncAction(
  'pre_order/FETCH_USERS_PREORDERS',
)
export const fetchUsersPreorders = callback => (dispatch, getState) => {
  const { uid } = getState().user.user

  dispatch(
    firebaseCall({
      func: PreOrderService.findUsersPreorder,
      params: [uid],
      types: FETCH_USERS_PREORDERS,
      callback,
      meta: {
        withActivityLoader: true,
      },
    }),
  )
}

export const FETCH_PREORDERS_CONFIG = createAsyncAction(
  'pre_order/FETCH_PREORDERS_CONFIG',
)
export const fetchPreordersConfig = callback => {
  return firebaseCall({
    func: PreOrderService.fetchPreordersConfig,
    params: [],
    types: FETCH_PREORDERS_CONFIG,
    callback,
  })
}
