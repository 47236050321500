import { combineReducers } from 'redux'

import { AUTH_CHANGE_SUBSCRIBE } from 'Redux/actions/auth'

import auth from './auth'
import user from './user'
import common from './common'
import bench from './bench'
import benchCodes from './benchCodes'
import preOrder from './preOrder'

const appReducer = combineReducers({
  auth,
  user,
  common,
  bench,
  benchCodes,
  preOrder,
})

const rootReducer = (state, action) => {
  if (action.type === AUTH_CHANGE_SUBSCRIBE && !action.payload.data) {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

export default rootReducer
