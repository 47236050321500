import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/functions'
import 'firebase/auth'

import i18n from 'I18N'

const errorMessages = i18n.t('firebaseErrors.codePair')

class PairService {
  /**
   * Add a custom message to the error object
   */
  getErrorWithMessage = error => {
    return {
      ...error,
      message: errorMessages[error.code] || error.message,
    }
  }

  /**
   * Validate pairing code
   */
  validateCode = pairingCode => {
    return new Promise((resolve, reject) => {
      const normalizedCode = pairingCode.toUpperCase()

      const ref = firebase.database().ref(`pairingCodes/${normalizedCode}`)

      ref
        .once('value', snapshot => {
          if (snapshot && snapshot.val() !== null) {
            resolve()
          } else {
            reject(this.getErrorWithMessage({ code: 'invalidCode' }))
          }
        })
        .catch(error => {
          reject(this.getErrorWithMessage(error))
        })
    })
  }

  /**
   * Pair bench to the user
   */
  pairBench = pairingCode => {
    return new Promise((resolve, reject) => {
      // Get unique token to decode and identify user on the server side
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then(idToken => {
          const pairFunction = firebase.functions().httpsCallable('pair')

          // Call cloud function to complete bench pairing
          pairFunction({ idToken, pairingCode }).then(res => {
            resolve(res.data)
          })
        })
        .catch(error => {
          reject(this.getErrorWithMessage(error))
        })
    })
  }
}

export default new PairService()
