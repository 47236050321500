import React from 'react'

import { Box, Typography } from '@material-ui/core'

import i18n from 'I18N'

const PrivacyPolicy = () => {
  return (
    <Box
      style={{
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
      }}
    >
      <Typography variant="h4" style={{ margin: 'auto' }}>
        {i18n.t('privacyPolicy.title')}
      </Typography>
      <Typography>{i18n.t('privacyPolicy.p1')}</Typography>
      <Typography variant="h6">{i18n.t('privacyPolicy.subTitle1')}</Typography>
      <Typography>{i18n.t('privacyPolicy.p2')}</Typography>
      <Typography>{i18n.t('privacyPolicy.p3')}</Typography>
      <Typography>{i18n.t('privacyPolicy.p4')}</Typography>
      <Typography>{i18n.t('privacyPolicy.p5')}</Typography>
      <Typography>{i18n.t('privacyPolicy.p6')}</Typography>
      <Typography>{i18n.t('privacyPolicy.p7')}</Typography>
      <Typography>{i18n.t('privacyPolicy.p8')}</Typography>
      <Typography>{i18n.t('privacyPolicy.p9')}</Typography>
      <Typography>{i18n.t('privacyPolicy.p10')}</Typography>
      <Typography>{i18n.t('privacyPolicy.p11')}</Typography>
      <Typography>{i18n.t('privacyPolicy.p12')}</Typography>
      <Typography variant="h6">{i18n.t('privacyPolicy.subTitle2')}</Typography>
      <Typography>{i18n.t('privacyPolicy.p13')}</Typography>
      <Typography>{i18n.t('privacyPolicy.p14')}</Typography>
      <Typography>{i18n.t('privacyPolicy.p15')}</Typography>
      <Typography>{i18n.t('privacyPolicy.p16')}</Typography>
      <Typography>{i18n.t('privacyPolicy.p17')}</Typography>
      <Typography>{i18n.t('privacyPolicy.p18')}</Typography>
      <Typography>{i18n.t('privacyPolicy.p19')}</Typography>
      <Typography>{i18n.t('privacyPolicy.p20')}</Typography>
      <Typography variant="h6" style={{ marginBottom: 10 }}>
        {i18n.t('privacyPolicy.subTitle3')}
      </Typography>
      <Typography>{i18n.t('privacyPolicy.p21')}</Typography>
      <Typography variant="h6">{i18n.t('privacyPolicy.subTitle4')}</Typography>
      <Typography>{i18n.t('privacyPolicy.p22')}</Typography>
      <Typography variant="h6">{i18n.t('privacyPolicy.subTitle5')}</Typography>
      <Typography>{i18n.t('privacyPolicy.p23')}</Typography>
      <Typography variant="h6">{i18n.t('privacyPolicy.subTitle6')}</Typography>
      <Typography>{i18n.t('privacyPolicy.p24')}</Typography>
      <Typography variant="h6">{i18n.t('privacyPolicy.subTitle7')}</Typography>
      <Typography>{i18n.t('privacyPolicy.p25')}</Typography>
      <Typography variant="h6">{i18n.t('privacyPolicy.subTitle8')}</Typography>
      <Typography>{i18n.t('privacyPolicy.p26')}</Typography>
      <Typography>{i18n.t('privacyPolicy.p27')}</Typography>
    </Box>
  )
}

export default PrivacyPolicy
