import React from 'react'
import { Formik } from 'formik'

import PT from 'prop-types'

import Button from '@material-ui/core/Button'

import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import i18n from 'I18N'

import { validationSchema } from './config'

const AddTrackCodeModal = ({
  isOpen,
  onClose,
  onConfirm,
  initialValues,
  trackCodeEditable,
  title,
  submitBtnText,
  isRequest,
}) => {
  const handleClose = () => {
    onClose()
  }

  const handleConfirm = data => {
    onConfirm(data)
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleConfirm}
      >
        {({
          values = {},
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => {
          return (
            <>
              <DialogContent>
                <TextField
                  margin="dense"
                  id="name"
                  name="name"
                  label={i18n.t(
                    'trackCodesScreen.trackTab.updateModal.fields.notes.placeholder',
                  )}
                  type="text"
                  variant="outlined"
                  fullWidth
                  value={values.name}
                  error={errors.name && touched.name}
                  helperText={errors.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />

                <TextField
                  disabled={!trackCodeEditable}
                  autoFocus={trackCodeEditable}
                  margin="dense"
                  id="trackCode"
                  name="trackCode"
                  label={i18n.t(
                    'trackCodesScreen.trackTab.updateModal.fields.number.placeholder',
                  )}
                  type="text"
                  variant="outlined"
                  fullWidth
                  value={values.trackCode}
                  error={errors.trackCode && touched.trackCode}
                  helperText={errors.trackCode}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  {i18n.t(
                    'trackCodesScreen.trackTab.updateModal.buttons.cancel',
                  )}
                </Button>
                <Button
                  color="secondary"
                  disabled={isRequest}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  {submitBtnText}
                </Button>
              </DialogActions>
            </>
          )
        }}
      </Formik>
    </Dialog>
  )
}

AddTrackCodeModal.defaultProps = {
  trackCodeEditable: true,
  initialValues: {
    name: '',
    trackCode: '',
  },
  isRequest: false,
  title: i18n.t('trackCodesScreen.trackTab.updateModal.header'),
  submitBtnText: i18n.t('trackCodesScreen.trackTab.updateModal.buttons.submit'),
}

AddTrackCodeModal.propTypes = {
  isOpen: PT.bool.isRequired,
  isRequest: PT.bool,
  initialValues: PT.object,
  trackCodeEditable: PT.bool,
  onClose: PT.func.isRequired,
  onConfirm: PT.func.isRequired,
  title: PT.string,
  submitBtnText: PT.string,
}

export { AddTrackCodeModal }
