import { createReducer } from 'Helpers/redux'
import updateOrPush from 'Helpers/updateOrPush'

import {
  PAIR_BENCH,
  BENCH_STATUS_CHANGED,
  UNSUBSCRIBE_BENCH_UPDATE,
  BENCH_OWNER_CODE_CHANGE,
  ADD_BENCH_EVENT,
  REQUEST_PHOTO_SUCCESS,
  CLEAR_PHOTO,
  SET_IS_WAIT_FOR_PHOTO,
  FETCH_EVENTS_LIST,
  FETCH_ALL_EVENTS_LIST,
  BENCH_ENTRY_CODE_CHANGE,
  BENCH_TRACK_CODE_CHANGE,
  BENCH_OWNERS_CODE_STATUS,
  DELETE_USER_EVENT,
} from 'Redux/actions/bench'

const initialState = {
  benches: {},
  ownerCodes: {},
  primaryBenchId: null,
  events: {},
  photos: {},
  waitingForBenchPhoto: false,
  allEvents: {},
  entryCodes: {},
  trackCodes: {},
  ownerCodesStatus: {},
}

const handlers = {
  [FETCH_EVENTS_LIST.SUCCESS]: (state, { payload }) => {
    const { benchId, list } = payload.data

    return {
      ...state,
      events: {
        ...state.events,
        [benchId]: list,
      },
    }
  },
  [FETCH_ALL_EVENTS_LIST.SUCCESS]: (state, { payload }) => {
    const { benchId, list } = payload.data

    return {
      ...state,
      allEvents: {
        ...state.events,
        [benchId]: list,
      },
    }
  },
  [DELETE_USER_EVENT.SUCCESS]: (state, { meta: { benchId, eventId } }) => {
    return {
      ...state,
      allEvents: {
        ...state.allEvents,
        [benchId]: state.allEvents[benchId].filter(item => item.id !== eventId),
      },
    }
  },
  [PAIR_BENCH.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      primaryBenchId: payload.data.benchId,
    }
  },
  [BENCH_STATUS_CHANGED.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      benches: {
        ...state.benches,
        [payload.data.benchId]: payload.data,
      },
      primaryBenchId: state.benches.length
        ? state.benches[0].benchId
        : payload.data.benchId,
    }
  },
  [UNSUBSCRIBE_BENCH_UPDATE]: (state, { payload }) => {
    return {
      ...state,
      benches: {
        ...state.benches,
        [payload.data]: undefined,
        ownerCodes: {
          ...state.ownerCodes,
          [payload.data]: undefined,
        },
      },
      primaryBenchId: state.benches.length ? state.benches[0].benchId : null,
    }
  },
  [BENCH_OWNER_CODE_CHANGE.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      ownerCodes: {
        ...state.ownerCodes,
        [payload.data.benchId]: payload.data.val,
      },
    }
  },
  [BENCH_OWNERS_CODE_STATUS.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      ownerCodesStatus: {
        ...state.ownerCodesStatus,
        [payload.data.benchId]: payload.data.val,
      },
    }
  },
  [ADD_BENCH_EVENT]: (state, { payload }) => {
    const newData = field => ({
      ...state[field],
      [payload.benchId]: updateOrPush(
        state[field][payload.benchId] || [],
        payload.data,
        item => item.id === payload.data.id,
      ),
    })
    return {
      ...state,
      allEvents: newData('allEvents'),
      events: newData('events'),
    }
  },
  [REQUEST_PHOTO_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      photos: {
        ...state.photos,
        [payload.benchId]: payload.data,
      },
      waitingForBenchPhoto: false,
    }
  },
  [CLEAR_PHOTO]: (state, { payload }) => {
    return {
      ...state,
      photos: {
        ...state.photos,
        [payload.benchId]: null,
      },
      waitingForBenchPhoto: false,
    }
  },
  [SET_IS_WAIT_FOR_PHOTO]: (state, { payload }) => {
    return {
      ...state,
      waitingForBenchPhoto: payload.data,
    }
  },
  [BENCH_ENTRY_CODE_CHANGE.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      entryCodes: {
        ...state.entryCodes,
        [payload.data.benchId]: payload.data.val,
      },
    }
  },
  [BENCH_TRACK_CODE_CHANGE.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      trackCodes: {
        ...state.trackCodes,
        [payload.data.benchId]: payload.data.val,
      },
    }
  },
}

export default createReducer(initialState, handlers)
