/* eslint-disable global-require */

export default {
  bottomBar: require('./bottomBar'),
  reauthModal: require('./reauthModal'),
  verifyPhoneNumberModal: require('./verifyPhoneNumberModal'),
  trackCodesScreen: require('./trackCodesScreen'),
  entryCodesScreen: require('./entryCodesScreen'),
  accountSettingsScreen: require('./accountSettingsScreen'),
  benchOwnerCodeScreen: require('./benchOwnerCodeScreen'),
  changeBenchOwnerScreen: require('./changeBenchOwnerScreen'),
  changePasswordScreen: require('./changePasswordScreen'),
  createAccountScreen: require('./createAccountScreen'),
  enterPairingCodeScreen: require('./enterPairingCodeScreen'),
  loginScreen: require('./loginScreen'),
  homeScreen: require('./homeScreen'),
  confirmResetPasswordScreen: require('./confirmResetPasswordScreen'),
  firebaseErrors: require('./firebaseErrors'),
  common: require('./common.json'),
  finalizeAccount: require('./finalizeAccountScreen.json'),
  setupBench: require('./setupBench.json'),
  lockSettingsScreen: require('./lockSettingsScreen'),
  activityScreen: require('./activityScreen.json'),
  notificationSettingsScreen: require('./notificationSettingsScreen'),
  changeAddressScreen: require('./changeAddressScreen'),
  alertBlock: require('./alertBlock'),
  wrapperAlert: require('./wrapperAlert'),
  privacyPolicy: require('./privacyPolicy'),
  termsOfService: require('./termsOfService'),
  setupYourAccount: require('./setupYourAccount.json'),
  preOrderScreen: require('./preOrderScreen'),
  preOrderSignUpScreen: require('./preOrderSignUpScreen'),
  preOrderDashboard: require('./preOrderDashboard.json'),
}
