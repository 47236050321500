import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { updateUserProfile } from 'Redux/actions/user'
import {
  createCustomer,
  addPreOrderToDB,
  addHelcimCreditCard,
  fetchUsersPreorders,
  updatePreorder,
} from 'Redux/actions/preOrder'

import { getUser } from 'Redux/selectors/user'
import {
  getPreorder,
  getHelcimUser,
  getPreordersConfig,
} from 'Redux/selectors/preOrder'

import Component from './Component'

const selector = createStructuredSelector({
  user: getUser,
  preorderConfig: getPreordersConfig,
  usersPreorder: getPreorder,
  helcimUser: getHelcimUser,
})

const actions = {
  onCustomerCreate: createCustomer,
  onAddPreorderToDb: addPreOrderToDB,
  onAddCreditCard: addHelcimCreditCard,
  onUpdateUserProfile: updateUserProfile,
  onFetchUsersPreorders: fetchUsersPreorders,
  onPreorderUpdate: updatePreorder,
}

export default connect(
  selector,
  actions,
)(Component)
