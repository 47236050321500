import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { getUser } from 'Redux/selectors/user'
import {
  getPrimaryBenchEventsByDays,
  getAllPrimaryBenchEvents,
  getPrimaryBench,
} from 'Redux/selectors/bench'
import {
  getPrimaryBenchEntryCodesObject,
  getPrimartyBenchTrackCodesObject,
  getPrimaryBenchTrackCodesArchiveObject,
} from 'Redux/selectors/benchCodes'

import { deleteUserEvent } from 'Redux/actions/bench'

import Component from './Component'

const actions = {
  onUserEventDelete: deleteUserEvent,
}

const selector = createStructuredSelector({
  user: getUser,
  bench: getPrimaryBench,
  events: getAllPrimaryBenchEvents,
  eventsByDay: getPrimaryBenchEventsByDays,
  entryCodes: getPrimaryBenchEntryCodesObject,
  trackCodes: getPrimartyBenchTrackCodesObject,
  trackCodeArchive: getPrimaryBenchTrackCodesArchiveObject,
})

export default connect(
  selector,
  actions,
)(Component)
