import React from 'react'
import { Formik } from 'formik'

import PT from 'prop-types'

import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
} from '@material-ui/core'

import i18n from 'I18N'

const EditValueModal = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  description,
  validationSchema,
  inputLabel,
  confirmBtnLabel,
  cancelBtnLabel,
  currentValue,
}) => {
  const handleConfirm = data => {
    onConfirm(data.nextValue)
  }

  const handleCancel = () => {
    onClose()
  }

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={isOpen}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <Formik
        initialValues={{
          nextValue: currentValue || '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleConfirm}
      >
        {({
          values = {},
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => {
          const handleInputKeyDown = e => {
            if (e.key === 'Enter') {
              handleSubmit()
            }
          }

          return (
            <>
              <DialogContent>
                {description && (
                  <DialogContentText>{description}</DialogContentText>
                )}
                <TextField
                  autoFocus
                  error={errors.nextValue && touched.nextValue}
                  fullWidth
                  helperText={errors.nextValue}
                  margin="dense"
                  id="nextValue"
                  name="nextValue"
                  label={inputLabel}
                  type="text"
                  variant="outlined"
                  value={values.nextValue}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onKeyDown={handleInputKeyDown}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCancel} color="primary">
                  {cancelBtnLabel}
                </Button>
                <Button
                  onClick={handleSubmit}
                  color="secondary"
                  variant="contained"
                >
                  {confirmBtnLabel}
                </Button>
              </DialogActions>
            </>
          )
        }}
      </Formik>
    </Dialog>
  )
}

EditValueModal.defaultProps = {
  isOpen: false,
  description: null,
  confirmBtnLabel: i18n.t('accountSettingsScreen.editValueModal.saveBtnLabel'),
  cancelBtnLabel: i18n.t('accountSettingsScreen.editValueModal.cancelBtnLabel'),
  inputLabel: '',
  title: '',
}

EditValueModal.propTypes = {
  isOpen: PT.bool,
  onClose: PT.func,
  onConfirm: PT.func,
  title: PT.string,
  description: PT.oneOf(PT.string, val => val === null),
  validationSchema: PT.object,
  inputLabel: PT.string,
  confirmBtnLabel: PT.string,
  cancelBtnLabel: PT.string,
  currentValue: PT.string,
}

export { EditValueModal }
