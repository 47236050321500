import i18n from 'I18N'

const labels = i18n.t('lockSettingsScreen.items')

export const options = [
  {
    title: labels.lockAfterFirst.title,
    text: labels.lockAfterFirst.text,
    status: 'lockAfterFirst',
  },
  {
    title: labels.unlocked.title,
    text: labels.unlocked.text,
    status: 'unlocked',
  },
  {
    title: labels.locked.title,
    text: labels.locked.text,
    status: 'locked',
  },
]
