import React from 'react'
import { Formik } from 'formik'

import PT from 'prop-types'

import Icon from '@mdi/react'
import { mdiSubdirectoryArrowRight } from '@mdi/js'

import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
} from '@material-ui/core'

import i18n from 'I18N'

import { validationSchema } from './config'

const ChangePasswordModal = ({ isOpen, onClose, onConfirm }) => {
  const handleConfirm = data => {
    onConfirm(data)
  }

  const handleCancel = () => {
    onClose()
  }

  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {i18n.t('changePasswordScreen.title')}
      </DialogTitle>
      <Formik
        initialValues={{
          oldPass: '',
          pass: '',
          passConfirm: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleConfirm}
      >
        {({
          values = {},
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => {
          const handleInputKeyDown = e => {
            if (e.key === 'Enter') {
              handleSubmit()
            }
          }

          return (
            <>
              <DialogContent>
                <TextField
                  autoFocus
                  error={errors.oldPass && touched.oldPass}
                  fullWidth
                  helperText={errors.oldPass}
                  margin="dense"
                  id="oldPass"
                  label={i18n.t('changePasswordScreen.oldPassInputLabel')}
                  type="password"
                  variant="outlined"
                  value={values.oldPass}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onKeyDown={handleInputKeyDown}
                />
                <TextField
                  error={errors.pass && touched.pass}
                  fullWidth
                  helperText={errors.pass}
                  margin="dense"
                  id="pass"
                  label={i18n.t('changePasswordScreen.newPassInputLabel')}
                  type="password"
                  variant="outlined"
                  value={values.pass}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onKeyDown={handleInputKeyDown}
                />
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-end"
                >
                  <Grid
                    item
                    style={{
                      display: 'flex',
                      marginBottom: '2px',
                    }}
                  >
                    <Icon
                      path={mdiSubdirectoryArrowRight}
                      size="10px"
                      style={{ marginRight: '4px' }}
                    />
                  </Grid>
                  <Grid item xs>
                    <FormHelperText>
                      {i18n.t('changePasswordScreen.info.length')}
                    </FormHelperText>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-end"
                >
                  <Grid
                    item
                    style={{
                      display: 'flex',
                      marginBottom: '2px',
                    }}
                  >
                    <Icon
                      path={mdiSubdirectoryArrowRight}
                      size="10px"
                      style={{ marginRight: '4px' }}
                    />
                  </Grid>
                  <Grid item xs>
                    <FormHelperText>
                      {i18n.t('changePasswordScreen.info.case')}
                    </FormHelperText>
                  </Grid>
                </Grid>
                <TextField
                  error={errors.passConfirm && touched.passConfirm}
                  fullWidth
                  helperText={errors.passConfirm}
                  margin="dense"
                  id="passConfirm"
                  label={i18n.t('changePasswordScreen.confirmPassInputLabel')}
                  type="password"
                  variant="outlined"
                  value={values.passConfirm}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCancel} color="primary">
                  {i18n.t('changePasswordScreen.cancelBtnLabel')}
                </Button>
                <Button
                  onClick={handleSubmit}
                  color="secondary"
                  variant="contained"
                >
                  {i18n.t('changePasswordScreen.submitBtnLabel')}
                </Button>
              </DialogActions>
            </>
          )
        }}
      </Formik>
    </Dialog>
  )
}

ChangePasswordModal.propTypes = {
  isOpen: PT.bool.isRequired,
  onClose: PT.func.isRequired,
  onConfirm: PT.func.isRequired,
}

export { ChangePasswordModal }
