export default {
  REGISTERED: 'registered',
  RETIRED: 'retired',
  PAIRING_CODE_ADDED: 'pairing_code_added',
  PAIRED: 'paired',
  OPENED: 'opened',
  CLOSED: 'closed',
  LOCKED: 'locked',
  UNLOCKED: 'unlocked',
  ENTRY_CODE_ADDED: 'entry_code_added',
  ENTRY_CODE_DELETED: 'entry_code_deleted',
  OWNER_CODE_SET: 'owner_code_set',
  PACKAGE_ADDED: 'package_added',
  PACKAGE_DELETED: 'package_deleted',
  PACKAGE_DELIVERED: 'package_delivered',
  ADD_MEDIA: 'add_media',
  BENCH_STATE: 'bench_state',
  PICTURE_TAKEN: 'picture_taken',
  INVALID_CODE_ENTRY: 'unlock_attempt',
  UNKNOWN_DELIVERY: 'unknown_delivery',
  LOCK_FAILED: 'lock_failed',
  MOBILE_UNLOCK: 'mobile_unlock',
  LID_OPEN: 'lid_open',
  MOBILE_LOCK: 'mobile_lock',
}
