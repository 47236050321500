import { put } from 'redux-saga/effects'
import _ from 'lodash'

import { fetchHelcimUser } from 'Redux/actions/preOrder'

function* onFetchUsersPreorder(action) {
  const helcimCustomerCode = _.get(
    action,
    'payload.data.helcimCustomerCode',
    null,
  )

  if (helcimCustomerCode) {
    yield put(fetchHelcimUser(helcimCustomerCode))
  }

  yield true
}

export default onFetchUsersPreorder
