import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  title: {
    paddingLeft: 16,
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
      lineHeight: '1.4rem',
    },
  },
  tableActionBar: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    paddingRight: 8,
    backgroundColor: '#f0f3f5',
  },
  tableFooterBar: {
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    paddingLeft: 8,
  },
  topBar: {
    height: 80,
  },
  list: {
    padding: 0,
  },
}))
