import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { createTrackingCode, createEntryCode } from 'Redux/actions/benchCodes'
import { updateLockSettings, updateLockBench } from 'Redux/actions/bench'

import {
  getPrimaryBench,
  getPrimaryBenchId,
  getPrimaryBenchEvents,
} from 'Redux/selectors/bench'
import { getUser } from 'Redux/selectors/user'
import {
  getPrimartyBenchTrackCodes,
  getPrimaryBenchEntryCodes,
  getPrimaryBenchEntryCodesObject,
  getPrimartyBenchTrackCodesObject,
  getPrimaryBenchTrackCodesArchiveObject,
} from 'Redux/selectors/benchCodes'

import Component from './Component'

const actions = {
  onEntryCodeCreate: createEntryCode,
  onTrackCodeCreate: createTrackingCode,
  onUpdateLockSettings: updateLockSettings,
  onUpdateLockBench: updateLockBench,
}

const selector = createStructuredSelector({
  benchId: getPrimaryBenchId,
  entryCodes: getPrimaryBenchEntryCodes,
  primaryBench: getPrimaryBench,
  user: getUser,
  benchEvents: getPrimaryBenchEvents,
  deliveries: getPrimartyBenchTrackCodes,
  entryCodesObject: getPrimaryBenchEntryCodesObject,
  trackCodes: getPrimartyBenchTrackCodesObject,
  trackCodeArchive: getPrimaryBenchTrackCodesArchiveObject,
})

export default connect(
  selector,
  actions,
)(Component)
