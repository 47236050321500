import React from 'react'

import PT from 'prop-types'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Radio,
  Typography,
} from '@material-ui/core'

import { get } from 'lodash'

import i18n from 'I18N'

import { options } from './config'

const ChangeLockSettingsModal = ({ isOpen, bench, onClose, onChange }) => {
  const handleChange = event => {
    onChange(event.target.value)
  }

  const handleCancel = () => {
    onClose()
  }

  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {i18n.t('lockSettingsScreen.title')}
      </DialogTitle>
      <DialogContent>
        {options.map((opt, index) => {
          const isActive =
            get(bench, 'preferences.lockSetting', 'locked') === opt.status

          return (
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignContent="flex-start"
              style={
                index !== options.length - 1
                  ? { marginBottom: '8px' }
                  : undefined
              }
            >
              <Grid item style={{ marginRight: '4px' }}>
                <Radio
                  checked={isActive}
                  onChange={handleChange}
                  value={opt.status}
                  name={opt.status}
                />
              </Grid>
              <Grid item xs>
                <Typography variant="subtitle2">{opt.title}</Typography>
                <Typography color="textSecondary" variant="body2">
                  {opt.text}
                </Typography>
              </Grid>
            </Grid>
          )
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          {i18n.t('lockSettingsScreen.cancelBtnLabel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ChangeLockSettingsModal.propTypes = {
  isOpen: PT.bool.isRequired,
  bench: PT.object,
  onClose: PT.func.isRequired,
  onChange: PT.func.isRequired,
}

export { ChangeLockSettingsModal }
