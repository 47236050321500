import _ from 'lodash'

import { createAsyncAction } from 'Helpers/redux'

import PairService from 'Services/pair/PairService'
import CodesService from 'Services/codes/BenchCodesService'
import BenchService from 'Services/bench/BenchService'
import EventsService from 'Services/events/EventsService'
import PackageService from 'Services/packages/PackageService'

import benchEventTypes from 'Constants/benchEventTypes'
import benchCodeTypes from 'Constants/benchCodeTypes'

import { firebaseCall } from './api'

export const UPDATE_BENCH_FIELDS = createAsyncAction('bench/UPDATE_FIELDS')
export const updateBenchFields = (benchId, update, callback) => {
  return firebaseCall({
    types: UPDATE_BENCH_FIELDS,
    func: BenchService.updateBenchFields,
    params: [benchId, update],
    callback,
  })
}

export const PAIR_BENCH = createAsyncAction('bench/PAIR')
export const pairBench = (pairingCode, callback) => {
  return firebaseCall({
    types: PAIR_BENCH,
    func: PairService.pairBench,
    params: [pairingCode],
    callback,
    meta: {
      withActivityLoader: true,
    },
  })
}

export const SET_BENCH_OWNER_CODE = createAsyncAction(
  'bench/SET_BENCH_OWNER_CODE',
)
export const setBenchOwnerCode = (benchId, benchOwnerCode, meta, callback) => {
  return firebaseCall({
    types: SET_BENCH_OWNER_CODE,
    func: CodesService.setOwnerCode,
    params: [benchId, benchOwnerCode],
    callback,
    meta: {
      ...meta,
      withActivityLoader: true,
    },
  })
}

export const BENCH_STATUS_CHANGED = createAsyncAction('bench/STATUS_CHANGED')
export const subcribeBenchStatusChange = benchId => dispatch => {
  BenchService.getBenchStateSourceForBenchId(benchId).subscribe(
    benchSnap => {
      const benchFieldsToStore = _.pick(benchSnap.val(), [
        'state',
        'preferences',
        'name',
        'lastLoginTime',
      ])

      dispatch({
        type: BENCH_STATUS_CHANGED.SUCCESS,
        payload: {
          data: {
            ...benchFieldsToStore,
            benchId: benchSnap.key,
          },
        },
      })
    },
    error => {
      dispatch({
        type: BENCH_STATUS_CHANGED.FAILURE,
        payload: {
          data: error,
        },
      })
    },
  )
}

export const UNSUBSCRIBE_BENCH_UPDATE = 'bench/UNSUBSCRIE_UPDATE'
export const unsubscribeBenchUpdates = (benchId, userId) => {
  BenchService.benchStateUpdateUnsubscribe(benchId)
  BenchService.benchOwnerCodeUnsubscribe(benchId, userId)

  return {
    type: UNSUBSCRIBE_BENCH_UPDATE,
    payload: {
      data: benchId,
    },
  }
}

export const BENCH_OWNER_CODE_CHANGE = createAsyncAction(
  'bench/OWNER_CODE_CHANGE',
)
export const benchOnwerCodeSubscribe = (benchId, userId) => dispatch => {
  BenchService.benchOwnerCode(benchId, userId).subscribe(
    nextVal => {
      dispatch({
        type: BENCH_OWNER_CODE_CHANGE.SUCCESS,
        payload: {
          data: nextVal,
        },
      })
    },
    error => {
      dispatch({
        type: BENCH_OWNER_CODE_CHANGE.FAILURE,
        payload: {
          data: error,
        },
      })
    },
  )
}

export const BENCH_OWNERS_CODE_STATUS = createAsyncAction(
  'bench/BENCH_OWNERS_CODE_STATUS',
)
export const benchOnwersCodeStatusSubscribe = benchId => dispatch => {
  BenchService.benchOwnersCodeStatus(benchId).subscribe(
    nextVal => {
      dispatch({
        type: BENCH_OWNERS_CODE_STATUS.SUCCESS,
        payload: {
          data: nextVal,
        },
      })
    },
    error => {
      dispatch({
        type: BENCH_OWNERS_CODE_STATUS.FAILURE,
        payload: {
          data: error,
        },
      })
    },
  )
}

export const BENCH_ENTRY_CODE_CHANGE = createAsyncAction(
  'bench/BENCH_ENTRY_CODE_CHANGE',
)
export const benchEntryCodeSubscribe = benchId => dispatch => {
  BenchService.benchEntryCode(benchId).subscribe(
    nextVal => {
      dispatch({
        type: BENCH_ENTRY_CODE_CHANGE.SUCCESS,
        payload: {
          data: nextVal,
        },
      })
    },
    error => {
      dispatch({
        type: BENCH_ENTRY_CODE_CHANGE.FAILURE,
        payload: {
          data: error,
        },
      })
    },
  )
}

export const BENCH_TRACK_CODE_CHANGE = createAsyncAction(
  'bench/BENCH_TRACK_CODE_CHANGE',
)
export const benchTrackCodeSubscribe = benchId => dispatch => {
  BenchService.benchTrackCode(benchId).subscribe(
    nextVal => {
      dispatch({
        type: BENCH_TRACK_CODE_CHANGE.SUCCESS,
        payload: {
          data: nextVal,
        },
      })
    },
    error => {
      dispatch({
        type: BENCH_TRACK_CODE_CHANGE.FAILURE,
        payload: {
          data: error,
        },
      })
    },
  )
}

export const CLEAR_PHOTO = 'bench/CLEAR_PHOTO'
export const clearPhoto = benchId => {
  return {
    type: CLEAR_PHOTO,
    payload: {
      benchId,
    },
  }
}

export const SET_IS_WAIT_FOR_PHOTO = 'bench/SET_IS_WAIT_FOR_PHOTO'
export const setIwaitForPhoto = nextState => {
  return {
    type: SET_IS_WAIT_FOR_PHOTO,
    payload: {
      data: nextState,
    },
  }
}

export const ADD_BENCH_EVENT = 'bench/ADD_EVENT'
export const REQUEST_PHOTO_SUCCESS = 'bench/REQUEST_PHOTO_SUCCESS'

export const subscribeBenchEvents = (benchId, minDateTimesamp) => (
  dispatch,
  getState,
) => {
  const observers = EventsService.getEventsUpdatesForBench(
    benchId,
    minDateTimesamp,
  )

  observers.eventAdded.subscribe(
    async snap => {
      let newEvent = snap.val()

      if (!newEvent) return

      newEvent.id = snap.key

      if (newEvent.type === benchEventTypes.UNLOCKED) {
        if (
          newEvent.codeType === benchCodeTypes.PACKAGE &&
          newEvent.packageId
        ) {
          try {
            const packageSnap = await PackageService.getPackageData(
              benchId,
              newEvent.packageId,
            )

            newEvent = {
              ...newEvent,
              package: packageSnap.val(),
            }
          } catch (err) {
            console.warn('package error', err)
          }
        } else if (
          newEvent.codeType === benchCodeTypes.ENTRY &&
          newEvent.entryCodeId
        ) {
          const entryCodeSnap = await CodesService.getEntryCodeData(
            benchId,
            newEvent.entryCodeId,
          )

          newEvent = {
            ...newEvent,
            entryCode: entryCodeSnap.val(),
          }
        }
      } else if (
        newEvent.type === benchEventTypes.PICTURE_TAKEN &&
        getState().bench.waitingForBenchPhoto
      ) {
        dispatch({
          type: REQUEST_PHOTO_SUCCESS,
          payload: {
            data: newEvent,
            benchId,
          },
        })
      }

      dispatch({
        type: ADD_BENCH_EVENT,
        payload: {
          data: newEvent,
          benchId,
        },
      })
    },
    error => console.warn('observers.eventAdded.subscribe error', error),
  )
}

export const FETCH_EVENTS_LIST = createAsyncAction(
  'bench_codes/FETCH_EVENTS_LIST',
)
export const fetchEventsList = (benchId, minDateTimesamp, callback) => {
  return firebaseCall({
    func: EventsService.getEventsListForBenchId,
    params: [benchId, minDateTimesamp],
    types: FETCH_EVENTS_LIST,
    callback,
  })
}

export const FETCH_ALL_EVENTS_LIST = createAsyncAction(
  'bench_codes/FETCH_ALL_EVENTS_LIST',
)
export const fetchAllEventsList = (benchId, minDateTimesamp, callback) => {
  return firebaseCall({
    func: EventsService.getEventsListForBenchId,
    params: [benchId, minDateTimesamp],
    types: FETCH_ALL_EVENTS_LIST,
    callback,
  })
}

export const UPDATE_LOCK_SETTINGS = createAsyncAction('bench/UPDATE_LOCK')
export const updateLockSettings = (benchID, status, callback) => {
  return firebaseCall({
    func: BenchService.updateBenchPreferences,
    params: [benchID, status],
    types: UPDATE_LOCK_SETTINGS,
    callback,
  })
}

export const UPDATE_LOCK_BENCH = createAsyncAction('bench/UPDATE_LOCK_BENCH')
export const updateLockBench = (requestType, benchId, userId, callback) => {
  return firebaseCall({
    func: BenchService.sendRequest,
    params: [requestType, benchId, userId],
    types: UPDATE_LOCK_BENCH,
    callback,
  })
}

export const DELETE_USER_EVENT = createAsyncAction(
  'bench_code/DELETE_USER_EVENT',
)
export const deleteUserEvent = (benchId, eventId, callback) => {
  return firebaseCall({
    func: EventsService.deleteUserEvent,
    params: [benchId, eventId],
    types: DELETE_USER_EVENT,
    meta: { benchId, eventId },
    callback,
  })
}
