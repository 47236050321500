import * as Yup from 'yup'
import i18n from 'I18N'

const errorMessages = i18n.t('reauthModal.errorMessages')

const validationSchema = Yup.object().shape({
  pass: Yup.string().required(errorMessages.pass.required),
})

export { validationSchema }
