import AuthService from 'Services/auth/AuthService'
import UserService from 'Services/user/UserService'

import { createAsyncAction } from 'Helpers/redux'

import { firebaseCall } from './api'

export const AUTH_CHANGE_SUBSCRIBE = 'auth/FIREBASE'
export const subscribeAuthChange = dispatch => {
  return new Promise(resolve => {
    AuthService.isLoggedIn.subscribe(user => {
      resolve()

      dispatch({
        type: AUTH_CHANGE_SUBSCRIBE,
        payload: {
          data: user,
        },
      })
    })
  })
}

export const REGISTER_WITH_EMAIL_AND_PASSWORD = createAsyncAction(
  'REGISTER_WITH_EMAIL_AND_PASSWORD',
)
export const registerWithEmailAndPassword = (data, callback) => {
  return firebaseCall({
    types: REGISTER_WITH_EMAIL_AND_PASSWORD,
    func: AuthService.createUserWithEmailAndPassword,
    params: [data.email, data.password],
    callback,
    meta: {
      withActivityLoader: true,
    },
  })
}

export const REGISTER_PRE_ORDER_USER = createAsyncAction(
  'REGISTER_PRE_ORDER_USER',
)
export const registerPreOrderUser = (data, callback) => {
  return firebaseCall({
    types: REGISTER_PRE_ORDER_USER,
    func: AuthService.createPreOrderUser,
    params: [data.email, data.password],
    callback,
    meta: {
      withActivityLoader: true,
    },
  })
}

export const SIGN_IN = createAsyncAction('auth/SIGN_IN')
export const signIn = (email, password, callback) => {
  return firebaseCall({
    types: SIGN_IN,
    func: AuthService.signInWithEmailAndPassword,
    params: [email, password],
    callback,
    meta: {
      withActivityLoader: true,
    },
  })
}

export const SIGN_OUT = createAsyncAction('auth/SIGN_OUT')
export const signOut = callback => {
  return firebaseCall({
    types: SIGN_OUT,
    func: AuthService.signOut,
    callback,
  })
}

export const SET_CURRENT_REGISTER_STEP = 'auth/SET_CURRENT_REGISTER_STEP'
export const setCurrentRegistrationStep = step => {
  return {
    type: SET_CURRENT_REGISTER_STEP,
    payload: {
      data: step,
    },
  }
}

export const SEND_RESET_PASSWORD_CODE = createAsyncAction(
  'auth/SEND_RESET_PASSWORD_CODE',
)
export const sendResetPasswordCode = (email, settings, callback) => {
  return firebaseCall({
    types: SEND_RESET_PASSWORD_CODE,
    func: AuthService.sendPasswordResetEmail,
    params: [email, settings],
    callback,
    meta: {
      withActivityLoader: true,
    },
  })
}

export const CONFIRM_RESET_PASSWORD = createAsyncAction(
  'auth/CONFIRM_RESET_PASSWORD',
)
export const confirmResetPassword = (confirmCode, newPassword, callback) => {
  return firebaseCall({
    types: CONFIRM_RESET_PASSWORD,
    func: AuthService.confirmResetPassword,
    params: [confirmCode, newPassword],
    callback,
    meta: {
      withActivityLoader: true,
    },
  })
}

export const SEND_SIGN_IN_LINK = createAsyncAction('auth/SEND_SIGN_IN_LINK')
export const sendSignInLink = (email, settings, callback) => {
  return firebaseCall({
    types: CONFIRM_RESET_PASSWORD,
    func: AuthService.sendSignInLinkToEmail,
    params: [email, settings],
    callback,
    meta: {
      withActivityLoader: true,
    },
  })
}

export const SIGN_IN_WITH_EMAIL = createAsyncAction('auth/SIGN_IN_WITH_EMAIL')
export const signInWithLink = (email, link, callback) => {
  return firebaseCall({
    types: SIGN_IN_WITH_EMAIL,
    func: AuthService.signInWithEmailLink,
    params: [email, link],
    callback,
    meta: {
      withActivityLoader: true,
    },
  })
}

export const FINALIZE_ACCOUNT = createAsyncAction('auth/FINALIZE_ACCOUNT')
export const finalizeAccount = (profileData, callback) => {
  return firebaseCall({
    types: FINALIZE_ACCOUNT,
    func: UserService.finalizeAccount,
    params: [profileData],
    callback,
    meta: {
      withActivityLoader: true,
    },
  })
}

export const BENCH_SETUP_COMPLETE = createAsyncAction(
  'auth/BENCH_SETUP_COMPLETE',
)
export const benchSetupComplete = callback => {
  return firebaseCall({
    types: BENCH_SETUP_COMPLETE,
    func: UserService.benchSetupComplete,
    params: [],
    callback,
  })
}
