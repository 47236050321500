import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { getPrimaryBenchId } from 'Redux/selectors/bench'
import {
  getPrimartyBenchTrackCodes,
  getPrimaryBenchTrackCodesArchive,
} from 'Redux/selectors/benchCodes'

import {
  createTrackingCode as createTrackCode,
  deleteTrackCode,
  updateTrackCode,
  deleteArchivedTrackCode,
} from 'Redux/actions/benchCodes'

import Component from './Component'

const actions = {
  onTrackCodeCreate: createTrackCode,
  onTrackCodeDelete: deleteTrackCode,
  onTrackCodeUpdate: updateTrackCode,
  onArchivedTrackCodeDelete: deleteArchivedTrackCode,
}

const selector = createStructuredSelector({
  trackCodes: getPrimartyBenchTrackCodes,
  trackCodesArchive: getPrimaryBenchTrackCodesArchive,
  benchId: getPrimaryBenchId,
})

export default connect(
  selector,
  actions,
)(Component)
