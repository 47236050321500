import * as Yup from 'yup'

import i18n from 'I18N'

const errorMessages = i18n.t('changePasswordScreen.validation.errorMessages')

export const validationSchema = Yup.object().shape({
  oldPass: Yup.string().required(errorMessages.oldPass.required),
  pass: Yup.string()
    .min(6, errorMessages.newPass.tooShort)
    .max(12, errorMessages.newPass.tooLong)
    .required(errorMessages.newPass.required),
  passConfirm: Yup.string()
    .oneOf([Yup.ref('pass'), null], errorMessages.passConfirm.mustMatch)
    .required(errorMessages.passConfirm.required),
})
