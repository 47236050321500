import React, { useState, useMemo } from 'react'
import { toast } from 'react-toastify'

import PT from 'prop-types'

import Icon from '@mdi/react'
import { mdiPencil } from '@mdi/js'

import {
  Typography,
  Box,
  Card,
  CardContent,
  Container,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Tooltip,
} from '@material-ui/core'

import { formatPhoneNumber } from 'react-phone-number-input'
import { isString, get, isFunction } from 'lodash'

import i18n from 'I18N'

import {
  EditValueModal,
  ChangePasswordModal,
  ChangeBenchOwnerCodeModal,
  ChangeLockSettingsModal,
  EditAddressModal,
  ReauthenticateModal,
  EditPhoneNumberModal,
} from './innerBlocks'

import { validationSchemas } from './config'

import { useStyles } from './styles'

// eslint-disable-next-line
const countryCode = process.env.NODE_ENV === 'development' ? '+38' : '+1'

const AccountSettings = ({
  user,
  bench,
  ownerCode,
  onEmailUpdate,
  onBenchFieldsUpdate,
  onUpdateProfile,
  onSetOwnerCode,
  onUpdateLockSettings,
  onUpdatePassword,
}) => {
  const classes = useStyles()

  const [fieldToEdit, setFieldToEdit] = useState(null)
  const [
    isEditLockSettingsModalOpen,
    setisEditLockSettingsModalOpen,
  ] = useState(false)
  const [isEditOwnerCodeModalOpen, setIsEditOwnerCodeModalOpen] = useState(
    false,
  )
  const [isEditPasswordModalOpen, setIsEditPasswordModalOpen] = useState(false)
  const [isEditAddressModalOpen, setIsEditAddressModalOpen] = useState(false)
  const [isEditPhoneNumberModalOpen, setIsEditPhoneNumberModalOpen] = useState(
    false,
  )
  const [reauthModalCallback, setReauthModalCallback] = useState(null)

  const isOnline = get(bench, `state.online`, false)

  const values = useMemo(() => {
    const initialValues = user
      ? {
          email: user.email || '',
          phoneNumber: isString(user.phoneNumber)
            ? user.phoneNumber.substr(countryCode.length)
            : '',
          firstName: user.firstName || '',
          lastName: user.lastName || '',
          address: user.address || '',
        }
      : {}

    if (bench) {
      initialValues.benchName = bench.name || ''
    }

    return initialValues
  }, [user, bench])

  const getLockTitle = () => {
    const fields = i18n.t('lockSettingsScreen.items')

    const statuses = {
      lockAfterFirst: {
        title: fields.lockAfterFirst.title,
      },
      unlocked: {
        title: fields.unlocked.title,
      },
      locked: {
        title: fields.locked.title,
      },
    }

    return statuses[get(bench, 'preferences.lockSetting', 'locked')].title
  }

  const handleBenchNameEditBtnClick = () => {
    setFieldToEdit('benchName')
  }

  const handleBenchOwnerCodeEditBtnClick = () => {
    setIsEditOwnerCodeModalOpen(true)
  }

  const handleLockSettingsEditBtnClick = () => {
    setisEditLockSettingsModalOpen(true)
  }

  const handleEmailEditBtnClick = () => {
    setFieldToEdit('email')
  }

  const handleEditPasswordBtnClick = () => {
    setIsEditPasswordModalOpen(true)
  }

  const handleFirstNameEditBtnClick = () => {
    setFieldToEdit('firstName')
  }

  const handleLastnameEditBtnClick = () => {
    setFieldToEdit('lastName')
  }

  const handlePhoneNumberEditBtnClick = () => {
    setIsEditPhoneNumberModalOpen('true')
  }

  const handleAddressEditBtnClick = () => {
    setIsEditAddressModalOpen(true)
  }

  const handleEditValueModalCancel = () => {
    setFieldToEdit(null)
  }

  const handleUpdateEmail = nextValue => {
    setFieldToEdit(null)

    const reauthCallback = password => {
      setReauthModalCallback(null)

      onEmailUpdate(nextValue, password, isSuccess => {
        if (!isSuccess) {
          handleUpdateEmail(nextValue)
        }
      })
    }

    setReauthModalCallback(() => reauthCallback)
  }

  const handleEditValueModalConfirm = nextValue => {
    const callback = isSuccess => {
      if (isSuccess) {
        setFieldToEdit(null)
      }
    }

    switch (fieldToEdit) {
      case 'benchName':
        onBenchFieldsUpdate(
          bench.benchId,
          {
            name: nextValue,
          },
          callback,
        )
        break
      case 'email':
        handleUpdateEmail(nextValue)
        break
      default:
        onUpdateProfile({ [fieldToEdit]: nextValue }, callback)
        break
    }
  }

  const handleReauthModalCancel = () => {
    setReauthModalCallback(null)
  }

  const handleEditPhoneNumberModalCancel = () => {
    setIsEditPhoneNumberModalOpen(false)
  }

  const handleEditPhoneNumberModalConfirm = nextValue => {
    onUpdateProfile(
      {
        phoneNumber: nextValue,
      },
      isSuccess => {
        if (isSuccess) {
          setIsEditPhoneNumberModalOpen(null)
        }
      },
    )
  }

  const handleOwnerCodeModalCancel = () => {
    setIsEditOwnerCodeModalOpen(false)
  }

  const handleOwnerCodeModalConfirm = nextValue => {
    onSetOwnerCode(bench.benchId, nextValue.new, null, isSuccess => {
      if (isSuccess) {
        toast.success('Code Successfully Changed')

        setIsEditOwnerCodeModalOpen(false)
      }
    })

    setIsEditOwnerCodeModalOpen(false)
  }

  const handleEditLockSettingsModalCancel = () => {
    setisEditLockSettingsModalOpen(false)
  }

  const handleEditLockSettingsModalConfirm = nextValue => {
    onUpdateLockSettings(
      bench.benchId,
      { lockSetting: nextValue },
      isSuccess => {
        if (isSuccess) {
          setisEditLockSettingsModalOpen(false)
        }
      },
    )
  }

  const handleEditPasswordModalCancel = () => {
    setIsEditPasswordModalOpen(false)
  }

  const handleEditPasswordModalConfirm = nextValues => {
    onUpdatePassword(nextValues.oldPass, nextValues.pass, isSuccess => {
      if (isSuccess) {
        setIsEditPasswordModalOpen(false)
      }
    })
  }

  const handleEditAddressModalCancel = () => {
    setIsEditAddressModalOpen(false)
  }

  const handleEditAddressModalConfirm = nextValues => {
    onUpdateProfile(nextValues, isSuccess => {
      if (isSuccess) {
        setIsEditAddressModalOpen(false)
      }
    })
  }

  return (
    <>
      <EditValueModal
        isOpen={fieldToEdit !== null}
        onClose={handleEditValueModalCancel}
        onConfirm={handleEditValueModalConfirm}
        title={
          fieldToEdit &&
          i18n.t(`accountSettingsScreen.editValueModal.types.${fieldToEdit}`)
            .title
        }
        validationSchema={validationSchemas[fieldToEdit]}
        inputLabel={
          fieldToEdit &&
          i18n.t(`accountSettingsScreen.editValueModal.types.${fieldToEdit}`)
            .inputLabel
        }
        currentValue={values[fieldToEdit]}
      />
      <ChangePasswordModal
        isOpen={isEditPasswordModalOpen}
        onClose={handleEditPasswordModalCancel}
        onConfirm={handleEditPasswordModalConfirm}
      />
      <ChangeBenchOwnerCodeModal
        isOpen={isEditOwnerCodeModalOpen}
        ownerCode={ownerCode}
        onClose={handleOwnerCodeModalCancel}
        onConfirm={handleOwnerCodeModalConfirm}
      />
      <ChangeLockSettingsModal
        isOpen={isEditLockSettingsModalOpen}
        onClose={handleEditLockSettingsModalCancel}
        onChange={handleEditLockSettingsModalConfirm}
        bench={bench}
      />
      <EditAddressModal
        isOpen={isEditAddressModalOpen}
        user={user}
        onClose={handleEditAddressModalCancel}
        onConfirm={handleEditAddressModalConfirm}
      />
      <EditPhoneNumberModal
        isOpen={isEditPhoneNumberModalOpen}
        user={user}
        onClose={handleEditPhoneNumberModalCancel}
        onConfirm={handleEditPhoneNumberModalConfirm}
      />
      <ReauthenticateModal
        isOpen={isFunction(reauthModalCallback)}
        onClose={handleReauthModalCancel}
        onConfirm={reauthModalCallback}
      />

      <Container>
        <Box display="flex" alignItems="center" className={classes.topBar}>
          <Typography variant="h5">
            {i18n.t('accountSettingsScreen.header.title')}
          </Typography>
        </Box>

        <Box className={classes.sectionHeader}>
          {i18n.t('accountSettingsScreen.sections.bench.sectionName')}
        </Box>

        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <List className={classes.list} disablePadding="true">
              <ListItem alignItems="flex-start" button>
                <ListItemText
                  primary={i18n.t(
                    'accountSettingsScreen.sections.bench.benchName.label',
                  )}
                  secondary={
                    <>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textSecondary"
                      >
                        {values.benchName}
                      </Typography>
                    </>
                  }
                />
                <ListItemSecondaryAction>
                  <Button
                    size="small"
                    className={classes.button}
                    startIcon={
                      <Icon
                        path={mdiPencil}
                        size="16px"
                        className={classes.icon}
                      />
                    }
                    onClick={handleBenchNameEditBtnClick}
                  >
                    {i18n.t('accountSettingsScreen.editBtnLabel')}
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </CardContent>
        </Card>

        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <List className={classes.list} disablePadding="true">
              <ListItem alignItems="flex-start" button>
                <ListItemText
                  primary={i18n.t(
                    // eslint-disable-next-line max-len
                    'accountSettingsScreen.sections.bench.changeBenchOwnerCodeLinkLabel',
                  )}
                  secondary={
                    ownerCode.pendingUpdate ? (
                      <Typography
                        className={classes.pendingText}
                        component="p"
                        variant="body2"
                      >
                        {i18n.t('accountSettingsScreen.sections.bench.sync')}
                      </Typography>
                    ) : (
                      undefined
                    )
                  }
                />
                <ListItemSecondaryAction>
                  <Button
                    size="small"
                    className={classes.button}
                    startIcon={
                      <Icon
                        path={mdiPencil}
                        size="16px"
                        className={classes.icon}
                      />
                    }
                    onClick={handleBenchOwnerCodeEditBtnClick}
                  >
                    {i18n.t('accountSettingsScreen.editBtnLabel')}
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </CardContent>
        </Card>

        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <List className={classes.list} disablePadding="true">
              <Tooltip
                title={
                  isOnline
                    ? ''
                    : i18n.t(
                        // eslint-disable-next-line max-len
                        'accountSettingsScreen.sections.bench.changeLockSettingsLinkTooltipBenchOffline',
                      )
                }
              >
                <ListItem alignItems="flex-start" button>
                  <ListItemText
                    primary={i18n.t(
                      // eslint-disable-next-line max-len
                      'accountSettingsScreen.sections.bench.changeLockSettingsLinkLabel',
                    )}
                    secondary={
                      <>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.inline}
                          color="textSecondary"
                        >
                          {getLockTitle()}
                        </Typography>
                      </>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Button
                      disabled={!isOnline}
                      size="small"
                      className={classes.button}
                      startIcon={
                        <Icon
                          path={mdiPencil}
                          size="16px"
                          className={classes.icon}
                        />
                      }
                      onClick={handleLockSettingsEditBtnClick}
                    >
                      {i18n.t('accountSettingsScreen.editBtnLabel')}
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
              </Tooltip>
            </List>
          </CardContent>
        </Card>

        <Box className={classes.sectionHeader}>
          {i18n.t('accountSettingsScreen.sections.personal.sectionName')}
        </Box>

        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <List className={classes.list} disablePadding="true">
              <ListItem alignItems="flex-start" button>
                <ListItemText
                  primary={i18n.t(
                    'accountSettingsScreen.sections.personal.emailField.label',
                  )}
                  secondary={
                    <>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textSecondary"
                      >
                        {values.email}
                      </Typography>
                    </>
                  }
                />
                <ListItemSecondaryAction>
                  <Button
                    size="small"
                    className={classes.button}
                    startIcon={
                      <Icon
                        path={mdiPencil}
                        size="16px"
                        className={classes.icon}
                      />
                    }
                    onClick={handleEmailEditBtnClick}
                  >
                    {i18n.t('accountSettingsScreen.editBtnLabel')}
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </CardContent>
        </Card>

        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <List className={classes.list} disablePadding="true">
              <ListItem alignItems="flex-start" button>
                <ListItemText
                  primary={i18n.t(
                    'accountSettingsScreen.sections.personal.passwordLinkLabel',
                  )}
                />
                <ListItemSecondaryAction>
                  <Button
                    size="small"
                    className={classes.button}
                    startIcon={
                      <Icon
                        path={mdiPencil}
                        size="16px"
                        className={classes.icon}
                      />
                    }
                    onClick={handleEditPasswordBtnClick}
                  >
                    {i18n.t('accountSettingsScreen.editBtnLabel')}
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </CardContent>
        </Card>

        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <List className={classes.list} disablePadding="true">
              <ListItem alignItems="flex-start" button>
                <ListItemText
                  primary={`${i18n.t(
                    'accountSettingsScreen.sections.personal.firstNameField.label',
                  )}*`}
                  secondary={
                    <>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textSecondary"
                      >
                        {values.firstName}
                      </Typography>
                    </>
                  }
                />
                <ListItemSecondaryAction>
                  <Button
                    size="small"
                    className={classes.button}
                    startIcon={
                      <Icon
                        path={mdiPencil}
                        size="16px"
                        className={classes.icon}
                      />
                    }
                    onClick={handleFirstNameEditBtnClick}
                  >
                    {i18n.t('accountSettingsScreen.editBtnLabel')}
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </CardContent>
        </Card>

        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <List className={classes.list} disablePadding="true">
              <ListItem alignItems="flex-start" button>
                <ListItemText
                  primary={`${i18n.t(
                    'accountSettingsScreen.sections.personal.lastNameField.label',
                  )}*`}
                  secondary={
                    <>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textSecondary"
                      >
                        {values.lastName}
                      </Typography>
                    </>
                  }
                />
                <ListItemSecondaryAction>
                  <Button
                    size="small"
                    className={classes.button}
                    startIcon={
                      <Icon
                        path={mdiPencil}
                        size="16px"
                        className={classes.icon}
                      />
                    }
                    onClick={handleLastnameEditBtnClick}
                  >
                    {i18n.t('accountSettingsScreen.editBtnLabel')}
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </CardContent>
        </Card>

        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <List className={classes.list} disablePadding="true">
              <ListItem alignItems="flex-start" button>
                <ListItemText
                  primary={i18n.t(
                    'accountSettingsScreen.sections.personal.mobileField.label',
                  )}
                  secondary={
                    <>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textSecondary"
                      >
                        +1 {formatPhoneNumber(user.phoneNumber)}
                      </Typography>
                    </>
                  }
                />
                <ListItemSecondaryAction>
                  <Button
                    size="small"
                    className={classes.button}
                    startIcon={
                      <Icon
                        path={mdiPencil}
                        size="16px"
                        className={classes.icon}
                      />
                    }
                    onClick={handlePhoneNumberEditBtnClick}
                  >
                    {i18n.t('accountSettingsScreen.editBtnLabel')}
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </CardContent>
        </Card>

        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <List className={classes.list} disablePadding="true">
              <ListItem alignItems="flex-start" button>
                <ListItemText
                  primary={`${i18n.t(
                    // eslint-disable-next-line max-len
                    'accountSettingsScreen.sections.personal.addressField.inputPlaceholder',
                  )}*`}
                  secondary={
                    <>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textSecondary"
                      >
                        {values.address}
                      </Typography>
                    </>
                  }
                />
                <ListItemSecondaryAction>
                  <Button
                    size="small"
                    className={classes.button}
                    startIcon={
                      <Icon
                        path={mdiPencil}
                        size="16px"
                        className={classes.icon}
                      />
                    }
                    onClick={handleAddressEditBtnClick}
                  >
                    {i18n.t('accountSettingsScreen.editBtnLabel')}
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Container>
    </>
  )
}

AccountSettings.propTypes = {
  user: PT.object,
  bench: PT.object,
  ownerCode: PT.string,
  onEmailUpdate: PT.func,
  onBenchFieldsUpdate: PT.func,
  onUpdateProfile: PT.func,
  onSetOwnerCode: PT.func,
  onUpdateLockSettings: PT.func,
}

export default AccountSettings
