import React from 'react'

import { Box, Typography } from '@material-ui/core'

import i18n from 'I18N'

const TermsOfServices = () => {
  return (
    <Box
      style={{
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
      }}
    >
      <Typography variant="h4" style={{ margin: 'auto' }}>
        {i18n.t('termsOfService.title')}
      </Typography>
      <Typography variant="h6">{i18n.t('termsOfService.subTitle1')}</Typography>
      <Typography>{i18n.t('termsOfService.p1')}</Typography>
      <Typography>{i18n.t('termsOfService.p2')}</Typography>
      <Typography variant="h6">{i18n.t('termsOfService.subTitle2')}</Typography>
      <Typography>{i18n.t('termsOfService.p3')}</Typography>
      <Typography variant="h6">{i18n.t('termsOfService.subTitle3')}</Typography>
      <Typography>{i18n.t('termsOfService.p4')}</Typography>
      <Typography variant="h6">{i18n.t('termsOfService.subTitle4')}</Typography>
      <Typography>{i18n.t('termsOfService.p5')}</Typography>
      <Typography>{i18n.t('termsOfService.p6')}</Typography>
      <Typography>{i18n.t('termsOfService.p7')}</Typography>
      <Typography>{i18n.t('termsOfService.p8')}</Typography>
      <Typography>{i18n.t('termsOfService.p9')}</Typography>
      <Typography variant="h6">{i18n.t('termsOfService.subTitle5')}</Typography>
      <Typography mb>{i18n.t('termsOfService.p10')}</Typography>
      <Typography mb>{i18n.t('termsOfService.p11')}</Typography>
      <Typography mb>{i18n.t('termsOfService.p12')}</Typography>
      <Typography mb>{i18n.t('termsOfService.p13')}</Typography>
      <Typography mb>{i18n.t('termsOfService.p14')}</Typography>
      <Typography mb>{i18n.t('termsOfService.p15')}</Typography>
      <Typography mb>{i18n.t('termsOfService.p16')}</Typography>
      <Typography mb>{i18n.t('termsOfService.p17')}</Typography>
      <Typography mb>{i18n.t('termsOfService.p18')}</Typography>
      <Typography mb>{i18n.t('termsOfService.p19')}</Typography>
      <Typography variant="h6">{i18n.t('termsOfService.subTitle6')}</Typography>
      <Typography>{i18n.t('termsOfService.p20')}</Typography>
      <Typography variant="h6">{i18n.t('termsOfService.subTitle7')}</Typography>
      <Typography>{i18n.t('termsOfService.p21')}</Typography>
      <Typography>{i18n.t('termsOfService.p22')}</Typography>
      <Typography mb>{i18n.t('termsOfService.p23')}</Typography>
      <Typography mb>{i18n.t('termsOfService.p24')}</Typography>
      <Typography mb>{i18n.t('termsOfService.p25')}</Typography>
      <Typography mb>{i18n.t('termsOfService.p26')}</Typography>
      <Typography>{i18n.t('termsOfService.p27')}</Typography>
      <Typography>{i18n.t('termsOfService.p28')}</Typography>
      <Typography variant="h6">{i18n.t('termsOfService.subTitle8')}</Typography>
      <Typography>{i18n.t('termsOfService.p29')}</Typography>
      <Typography variant="h6">{i18n.t('termsOfService.subTitle9')}</Typography>
      <Typography>{i18n.t('termsOfService.p30')}</Typography>
      <Typography>{i18n.t('termsOfService.p31')}</Typography>
      <Typography>{i18n.t('termsOfService.p32')}</Typography>
      <Typography>{i18n.t('termsOfService.p33')}</Typography>
      <Typography variant="h6">
        {i18n.t('termsOfService.subTitle10')}
      </Typography>
      <Typography>{i18n.t('termsOfService.p34')}</Typography>
      <Typography>{i18n.t('termsOfService.p35')}</Typography>
      <Typography>{i18n.t('termsOfService.p36')}</Typography>
      <Typography variant="h6">
        {i18n.t('termsOfService.subTitle11')}
      </Typography>
      <Typography>{i18n.t('termsOfService.p37')}</Typography>
      <Typography variant="h6">
        {i18n.t('termsOfService.subTitle12')}
      </Typography>
      <Typography>{i18n.t('termsOfService.p38')}</Typography>
      <Typography variant="h6">
        {i18n.t('termsOfService.subTitle13')}
      </Typography>
      <Typography>{i18n.t('termsOfService.p39')}</Typography>
      <Typography>{i18n.t('termsOfService.p40')}</Typography>
      <Typography variant="h6">
        {i18n.t('termsOfService.subTitle14')}
      </Typography>
      <Typography>{i18n.t('termsOfService.p41')}</Typography>
      <Typography variant="h6">
        {i18n.t('termsOfService.subTitle15')}
      </Typography>
      <Typography>{i18n.t('termsOfService.p42')}</Typography>
      <Typography>{i18n.t('termsOfService.p43')}</Typography>
      <Typography variant="h6">
        {i18n.t('termsOfService.subTitle16')}
      </Typography>
      <Typography>{i18n.t('termsOfService.p44')}</Typography>
      <Typography variant="h6">
        {i18n.t('termsOfService.subTitle17')}
      </Typography>
      <Typography>{i18n.t('termsOfService.p45')}</Typography>
      <Typography>{i18n.t('termsOfService.p46')}</Typography>
    </Box>
  )
}

export default TermsOfServices
