import React, { useState, useEffect } from 'react'
import PT from 'prop-types'
import { toast } from 'react-toastify'

import SwipeableViews from 'react-swipeable-views'
import { Box, Stepper, Step, StepLabel } from '@material-ui/core'

import { get } from 'lodash'

import i18n from 'I18N'

import PreorderStatus from 'Constants/PreorderStatus'

import { StepOne, StepTwo, StepThree } from './innerBlocks'

const PreOrder = ({
  onCustomerCreate,
  onAddPreorderToDb,
  onAddCreditCard,
  onUpdateUserProfile,
  onFetchUsersPreorders,
  user,
  preorderConfig,
  history,
  usersPreorder,
  helcimUser,
  onPreorderUpdate,
}) => {
  const [customerCode, setCustomerCode] = useState(null)
  const [orderStep, setOrderStep] = useState(0)
  const [isRequest, setIsRequest] = useState(false)

  useEffect(() => {
    const nextCustomerCode = get(helcimUser, 'customerCode._text')

    setCustomerCode(nextCustomerCode)
  }, [helcimUser])

  const handleCreateCustomer = (customerData, profileData) => {
    setIsRequest(true)

    const newCustomerData = customerData

    if (customerCode) {
      newCustomerData.customerCode = customerCode
    }

    onUpdateUserProfile(profileData)
    onCustomerCreate(newCustomerData, data => {
      if (data) {
        const newUserCustomerCode = data.message.customer.customerCode._text

        setCustomerCode(newUserCustomerCode)
        setOrderStep(1)
      }

      setIsRequest(false)
    })
  }

  const handleAddPreorderToDb = orderDetails => {
    setIsRequest(true)

    const callback = data => {
      if (data) {
        setOrderStep(2)
      }

      setIsRequest(false)
    }

    if (
      usersPreorder &&
      usersPreorder.orderStatus === PreorderStatus.pendingCreditCard
    ) {
      onPreorderUpdate(usersPreorder.preorderId, orderDetails)
    } else {
      onAddPreorderToDb(user.uid, customerCode, orderDetails, callback)
    }
  }

  const handleAddCreditCard = creditCardData => {
    setIsRequest(true)

    onAddCreditCard(
      {
        ...creditCardData,
        default: 1,
        customerCode,
      },
      data => {
        if (data) {
          if (data.message.response._text === '0') {
            toast.error(data.message.responseMessage._text)
          } else {
            onPreorderUpdate(usersPreorder.preorderId, {
              orderStatus: PreorderStatus.pending,
            })
            onFetchUsersPreorders()
            history.replace('/')
          }
        }

        setIsRequest(false)
      },
    )
  }

  return (
    <Box style={{ minHeight: '100vh', padding: '64px' }}>
      <Stepper activeStep={orderStep} alternativeLabel>
        {i18n.t('preOrderScreen.stepperLabels').map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <SwipeableViews axis="x" index={orderStep} onChangeIndex={() => {}}>
        <StepOne
          isRequest={isRequest}
          onSubmit={handleCreateCustomer}
          user={user}
          helcimUser={helcimUser}
        />
        <StepTwo
          existingPreorder={usersPreorder}
          isRequest={isRequest}
          preorderConfig={preorderConfig}
          onSubmit={handleAddPreorderToDb}
        />
        <StepThree
          isRequest={isRequest}
          preorderConfig={preorderConfig}
          onSubmit={handleAddCreditCard}
        />
      </SwipeableViews>
    </Box>
  )
}

PreOrder.propTypes = {
  preorderConfig: PT.object,
  user: PT.object,
  onAddCreditCard: PT.func,
  onAddPreorderToDb: PT.func,
  onCustomerCreate: PT.func,
  onUpdateUserProfile: PT.func,
}

export default PreOrder
