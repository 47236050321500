/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react'

const mediaPlayerStyle = {
  width: '100%',
  maxWidth: '480px',
  maxHeight: '360px',
}

const MediaPlayer = ({ url, ...otherProps }) => {
  if (url.match(/(.mp4)/i)) {
    return (
      <video controls style={{ ...mediaPlayerStyle }} {...otherProps}>
        <source src={url} />
      </video>
    )
  }

  return (
    <img alt="" src={url} style={{ ...mediaPlayerStyle }} {...otherProps} />
  )
}

export { MediaPlayer }
