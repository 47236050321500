import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { signOut } from 'Redux/actions/auth'

import Component from './Component'

const actions = {
  onSignOut: signOut,
}

const selector = createStructuredSelector({})

export default connect(
  selector,
  actions,
)(Component)
