import { takeLatest } from 'redux-saga/effects'

// action types
import { ADD_ENTRY_CODE_TO_LIST } from 'Redux/actions/benchCodes'

// sagas
import onAddEntryCodeForNewUser from './onAddEntryCodeForNewUser'

function* packagesSaga() {
  yield takeLatest(ADD_ENTRY_CODE_TO_LIST.SUCCESS, onAddEntryCodeForNewUser)
}

export default packagesSaga
