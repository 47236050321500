import authSaga from './auth'
// import registrationSaga from './registration'
import settingsSaga from './settings'
import packagesSaga from './packages'
import entryCodesSaga from './entryCodes'
import preOrdersSaga from './preOrder'

export default ({ run }) => {
  run(authSaga)
  // run(registrationSaga)
  run(settingsSaga)
  run(packagesSaga)
  run(entryCodesSaga)
  run(preOrdersSaga)
}
