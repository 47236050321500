import _ from 'lodash'

import { createAsyncAction } from 'Helpers/redux'

import packageStatus from 'Constants/packageStatus'

import BenchCodesService from 'Services/codes/BenchCodesService'
import PackagesService from 'Services/packages/PackageService'

import { firebaseCall } from './api'

// entry codes
export const ADD_ENTRY_CODE_TO_LIST = createAsyncAction(
  'bench_codes/ADD_ENTRY_CODE_TO_LIST',
)
export const REMOVE_ENTRY_CODE_FROM_LIST = createAsyncAction(
  'bench_code/REMOVE_ENTRY_CODE_FROM_LIST',
)

export const subscribeEntryCodeListUpdate = (
  benchId,
  minDateTimesamp,
) => dispatch => {
  const onEntryCodeListUpdate = BenchCodesService.getEntryCodesListUpdatesForBenchId(
    benchId,
    minDateTimesamp,
  )

  onEntryCodeListUpdate.onCodeAdd.subscribe(
    code => {
      dispatch({
        type: ADD_ENTRY_CODE_TO_LIST.SUCCESS,
        payload: {
          data: {
            benchId,
            code: _.assign(code.val(), { id: code.key }),
          },
        },
      })
    },
    error => {
      dispatch({
        type: ADD_ENTRY_CODE_TO_LIST.FAILURE,
        payload: {
          data: error,
        },
      })
    },
  )

  onEntryCodeListUpdate.onCodeRemove.subscribe(
    code => {
      dispatch({
        type: REMOVE_ENTRY_CODE_FROM_LIST.SUCCESS,
        payload: {
          data: {
            benchId,
            removeCode: _.assign(code.val(), { id: code.key }),
          },
        },
      })
    },
    error => {
      dispatch({
        type: REMOVE_ENTRY_CODE_FROM_LIST.FAILURE,
        payload: {
          data: error,
        },
      })
    },
  )
}

export const CREATE_OFFLINE_ENTRY_CODE = 'bench_code/CREATE_OFFLINE_ENTRY_CODE'

export const CREATE_ENTRY_CODE = createAsyncAction(
  'bench_code/CREATE_ENTRY_CODE',
)
export const createEntryCode = (
  benchId,
  entryCode,
  name,
  expiration,
  callback,
) => {
  return firebaseCall({
    types: CREATE_ENTRY_CODE,
    func: BenchCodesService.addEntryCode,
    params: [benchId, entryCode, name, expiration],
    callback,
    meta: {
      withActivityLoader: false,
    },
  })
}

export const DELETE_ENTRY_CODE = createAsyncAction(
  'bench_codes/DELETE_ENTRY_CODE',
)
export const deleteEntryCode = (benchId, entryCodeId, callback) => {
  return firebaseCall({
    func: BenchCodesService.deleteEntryCode,
    params: [benchId, entryCodeId],
    types: DELETE_ENTRY_CODE,
    callback,
    meta: {
      withActivityLoader: false,
    },
  })
}

// tracking codes
export const ADD_TRACK_CODE_TO_LIST = createAsyncAction(
  'bench_codes/ADD_TRACK_CODE_TO_LIST',
)
export const REMOVE_TRACK_CODE_FROM_LIST = createAsyncAction(
  'bench_code/REMOVE_TRACK_CODE_FROM_LIST',
)
export const TRACK_CODE_UPDATED = 'TRACK_CODE_UPDATED'

export const subscribePackageUpdates = (benchId, packageId) => dispatch => {
  PackagesService.subscribePackageUpdates(benchId, packageId).subscribe(
    snap => {
      if (snap && snap.key && snap.val()) {
        const nextCode = snap.val()

        dispatch({
          type: TRACK_CODE_UPDATED,
          payload: {
            benchId,
            packageId,
            nextVal: _.assign(nextCode, { packageId }),
          },
        })
      }
    },
  )
}

export const subscribeTrackCodeListUpdate = (
  benchId,
  minDateTimesamp,
) => dispatch => {
  const onTrackCodeListUpdate = PackagesService.getTrackCodesListUpdatesForBenchId(
    benchId,
    minDateTimesamp,
  )

  onTrackCodeListUpdate.onCodeAdd.subscribe(
    code => {
      if (code) {
        dispatch(subscribePackageUpdates(benchId, code.key))
      }
    },
    error => {
      dispatch({
        type: ADD_TRACK_CODE_TO_LIST.FAILURE,
        payload: {
          data: error,
        },
      })
    },
  )

  onTrackCodeListUpdate.onCodeRemove.subscribe(
    code => {
      PackagesService.unsubscribePackageUpdates(benchId, code.key)

      dispatch({
        type: REMOVE_TRACK_CODE_FROM_LIST.SUCCESS,
        payload: {
          data: {
            benchId,
            removeCode: _.assign(code.val(), { packageId: code.key }),
          },
        },
      })
    },
    error => {
      dispatch({
        type: REMOVE_TRACK_CODE_FROM_LIST.FAILURE,
        payload: {
          data: error,
        },
      })
    },
  )
}

export const CREATE_TRACK_CODE = createAsyncAction(
  'bench_codes/CREATE_TRACK_CODE',
)
export const createTrackingCode = (
  benchId,
  code,
  notes,
  callback,
) => dispatch => {
  dispatch(
    firebaseCall({
      func: PackagesService.addPendingTrackingCode,
      params: [benchId, code, notes],
      types: CREATE_TRACK_CODE,
      callback,
      meta: {
        withActivityLoader: true,
      },
    }),
  )
}

export const DELETE_ARCHIVED_TRACK_CODE = createAsyncAction(
  'bench_codes/DELETE_ARCHIVED_TRACK_CODE',
)
export const deleteArchivedTrackCode = (benchId, packageId, callback) => {
  return firebaseCall({
    func: PackagesService.deleteArchivedPackage,
    params: [benchId, packageId],
    types: DELETE_ARCHIVED_TRACK_CODE,
    callback,
    meta: {
      withActivityLoader: false,
    },
  })
}

export const DELETE_TRACK_CODE = createAsyncAction(
  'bench_codes/DELETE_TRACK_CODE',
)
export const deleteTrackCode = (benchId, packageId, status, callback) => {
  return firebaseCall({
    func: PackagesService.deletePackage,
    params: [benchId, packageId, status],
    types: DELETE_TRACK_CODE,
    callback,
    meta: {
      withActivityLoader: false,
      benchId,
      packageId,
    },
  })
}

export const UPDATE_TRACKING_CODE = createAsyncAction(
  'bench_codes/UPDATE_TRACKING_CODE',
)
export const updateTrackCode = (benchId, trackCodeId, update, callback) => {
  return firebaseCall({
    func: PackagesService.updatePackage,
    params: [benchId, trackCodeId, update],
    types: UPDATE_TRACKING_CODE,
    callback,
    meta: {
      withActivityLoader: true,
    },
  })
}

// tracking codes archive
export const ADD_TRACK_CODE_TO_ARCHIVE_LIST = createAsyncAction(
  'bench_codes/ADD_TRACK_CODE_TO_ARCHIVE_LIST',
)
export const REMOVE_TRACK_CODE_FROM_ARCHIVE_LIST = createAsyncAction(
  'bench_code/REMOVE_TRACK_CODE_FROM_ARCHIVE_LIST',
)
export const UPDATE_TRACK_CODE_TO_ARCHIVE_LIST = createAsyncAction(
  'bench_codes/UPDATE_TRACK_CODE_TO_ARCHIVE_LIST',
)

export const subscribeTrackCodeArchiveListUpdate = (
  benchId,
  minDateTimesamp,
) => dispatch => {
  const onArchiveUpdate = PackagesService.getTrackCodesArchiveListUpdatesForBenchId(
    benchId,
    minDateTimesamp,
  )

  onArchiveUpdate.onCodeAdd.subscribe(
    newCode => {
      if (newCode) {
        if (newCode.status === packageStatus.deleted) {
          return
        }

        dispatch({
          type: ADD_TRACK_CODE_TO_ARCHIVE_LIST.SUCCESS,
          payload: {
            data: {
              benchId,
              code: newCode,
            },
          },
        })
      }
    },
    error => {
      dispatch({
        type: ADD_TRACK_CODE_TO_ARCHIVE_LIST.FAILURE,
        payload: {
          data: error,
        },
      })
    },
  )

  onArchiveUpdate.onCodeRemove.subscribe(
    removeCode => {
      dispatch({
        type: REMOVE_TRACK_CODE_FROM_ARCHIVE_LIST.SUCCESS,
        payload: {
          data: {
            benchId,
            removeCode,
          },
        },
      })
    },
    error => {
      dispatch({
        type: REMOVE_TRACK_CODE_FROM_ARCHIVE_LIST.FAILURE,
        payload: {
          data: error,
        },
      })
    },
  )
}

export const FETCH_PACKAGES_LIST = createAsyncAction(
  'bench_codes/FETCH_PACKAGES_LIST',
)
export const fetchPackagesList = (benchId, minDateTimesamp, callback) => {
  return firebaseCall({
    func: PackagesService.getPackagesListForBenchId,
    params: [benchId, minDateTimesamp],
    types: FETCH_PACKAGES_LIST,
    callback,
  })
}

export const FETCH_PACKAGES_ARCHIVE_LIST = createAsyncAction(
  'bench_codes/FETCH_PACKAGES_ARCHIVE_LIST',
)
export const fetchPackagesArchiveList = (
  benchId,
  minDateTimesamp,
  callback,
) => {
  return firebaseCall({
    func: PackagesService.getPackagesArchiveListForBenchId,
    params: [benchId, minDateTimesamp],
    types: FETCH_PACKAGES_ARCHIVE_LIST,
    callback,
  })
}

export const FETCH_ENTRY_CODES_LIST = createAsyncAction(
  'bench_codes/FETCH_ENTRY_CODES_LIST',
)
export const fetchEntryCodesList = (benchId, minDateTimesamp, callback) => {
  return firebaseCall({
    func: BenchCodesService.getEntryCodesListForBenchId,
    params: [benchId, minDateTimesamp],
    types: FETCH_ENTRY_CODES_LIST,
    callback,
  })
}

export const SET_PACKAGES_ARCHIVE_LIST = 'SET_PACKAGES_ARCHIVE_LIST'
export const setPackagesArchiveList = (benchId, list) => ({
  type: SET_PACKAGES_ARCHIVE_LIST,
  payload: {
    data: {
      benchId,
      list,
    },
  },
})
