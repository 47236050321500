import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  title: {
    marginTop: 8,
    marginBottom: 16,
    color: theme.palette.primary.main,
  },
  button: {
    marginTop: 8,
  },
  logo: {
    width: 40,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}))
