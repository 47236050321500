import React from 'react'
import { Formik } from 'formik'

import PT from 'prop-types'

import Button from '@material-ui/core/Button'

import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import i18n from 'I18N'

import { validationSchema } from './config'

const AddEntryCodeModal = ({ isOpen, isRequest, onClose, onConfirm }) => {
  const handleConfirm = data => {
    onConfirm(data)
  }

  const handleCancel = () => {
    onClose()
  }

  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {i18n.t('entryCodesScreen.addModal.fields.header')}
      </DialogTitle>
      <Formik
        initialValues={{
          name: '',
          code: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleConfirm}
      >
        {({
          values = {},
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => {
          return (
            <>
              <DialogContent>
                <TextField
                  autoFocus
                  error={errors.name && touched.name}
                  fullWidth
                  helperText={errors.name}
                  margin="dense"
                  id="name"
                  label={i18n.t('entryCodesScreen.addModal.fields.input.name')}
                  type="text"
                  variant="outlined"
                  value={values.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <TextField
                  error={errors.code && touched.code}
                  fullWidth
                  helperText={errors.code}
                  margin="dense"
                  id="code"
                  label={i18n.t('entryCodesScreen.addModal.fields.input.code')}
                  type="text"
                  variant="outlined"
                  value={values.code}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCancel} color="primary">
                  {i18n.t('entryCodesScreen.addModal.fields.buttons.cancel')}
                </Button>
                <Button
                  color="secondary"
                  disabled={isRequest}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  {i18n.t('entryCodesScreen.addModal.fields.buttons.add')}
                </Button>
              </DialogActions>
            </>
          )
        }}
      </Formik>
    </Dialog>
  )
}

AddEntryCodeModal.propTypes = {
  isOpen: PT.bool.isRequired,
  isRequest: PT.bool,
  onClose: PT.func.isRequired,
  onConfirm: PT.func.isRequired,
}

export { AddEntryCodeModal }
