import React from 'react'

import PT from 'prop-types'

import Typography from '@material-ui/core/Typography'

import Card from '@material-ui/core/Card'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import Button from '@material-ui/core/Button'
import Icon from '@mdi/react'

import { mdiChevronRight, mdiPlus } from '@mdi/js'

import Grid from '@material-ui/core/Grid'

import Toolbar from '@material-ui/core/Toolbar'

import { take, isArray } from 'lodash'

import i18n from 'I18N'

import { Link as RouterLink } from 'react-router-dom'

import { entryCodes as entryCodesRoute } from 'Constants/routes'

import { useStyles } from './styles'

const EntryCodeBlock = ({ item, classes }) => {
  return (
    <List className={classes.list} key={item.id}>
      <ListItem alignItems="flex-start">
        <ListItemText
          primary={i18n.t('entryCodesScreen.entryCodeBlock.nameField', {
            name: item.name,
          })}
          secondary={
            <>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textSecondary"
              >
                {i18n.t('entryCodesScreen.entryCodeBlock.codeField', {
                  code: item.code,
                })}
              </Typography>
            </>
          }
        />
      </ListItem>
    </List>
  )
}

const EntryCodesList = ({ entryCodes, onAddEntryCodeClick }) => {
  const classes = useStyles()

  return (
    <Grid item lg={4} xs={12}>
      <Card className={classes.root}>
        <Toolbar
          disableGutters
          className={classes.tableActionBar}
          display="flex"
          alignItems="center"
        >
          <Typography className={classes.title} variant="h6">
            {i18n.t('homeScreen.labels.entryCodes.header')}
          </Typography>
        </Toolbar>

        <>
          {isArray(entryCodes) && entryCodes.length > 0 ? (
            take(entryCodes, 3).map(item => (
              <EntryCodeBlock key={item.id} item={item} classes={classes} />
            ))
          ) : (
            <ListItem alignItems="flex-start">
              <Typography variant="subtitle1">
                {i18n.t('entryCodesScreen.emptyBlock.text')}
                <Typography variant="body2">
                  {i18n.t('entryCodesScreen.emptyBlock.subText')}
                </Typography>
              </Typography>
            </ListItem>
          )}
        </>

        <Toolbar
          disableGutters
          className={classes.tableFooterBar}
          display="flex"
          alignItems="center"
        >
          <Button
            size="small"
            endIcon={<Icon path={mdiPlus} size="24px" />}
            onClick={onAddEntryCodeClick}
          >
            {i18n.t('homeScreen.labels.entryCodes.addEntryCodeBtn')}
          </Button>
          <RouterLink
            to={entryCodesRoute}
            style={{ color: '#000', textDecoration: 'none' }}
          >
            <Button
              size="small"
              endIcon={<Icon path={mdiChevronRight} size="24px" />}
            >
              {i18n.t('homeScreen.labels.entryCodes.viewAllBtn')}
            </Button>
          </RouterLink>
        </Toolbar>
      </Card>
    </Grid>
  )
}

EntryCodesList.propTypes = {
  entryCodes: PT.array,
  onAddEntryCodeClick: PT.func,
}

export { EntryCodesList }
