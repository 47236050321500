import * as Yup from 'yup'
import moment from 'moment'

import i18n from 'I18N'

const errorMessages = i18n.t('preOrderScreen.validation.errorMessages')

export const currentYear = +moment().format('YY')

export const validationSchema = Yup.object().shape({
  cardHolderName: Yup.string().required(errorMessages.requiredField),
  cardNumber: Yup.string().required(errorMessages.requiredField),
  cardExpiryMonth: Yup.number()
    .required(errorMessages.requiredField)
    .min(1)
    .max(12),
  cardExpiryYear: Yup.number()
    .required(errorMessages.requiredField)
    .min(currentYear)
    .max(99),
  cardCVV: Yup.string().required(errorMessages.requiredField),
})

export const monthOptions = new Array(12)
  .fill(0)
  .map((item, index) => index + 1)
export const yearOptions = new Array(20)
  .fill(0)
  .map((item, index) => currentYear + index)
