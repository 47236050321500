import React, { useMemo } from 'react'
import PT from 'prop-types'

import { Link as RouterLink } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'

import Card from '@material-ui/core/Card'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'

import Button from '@material-ui/core/Button'
import Icon from '@mdi/react'
import IconButton from '@material-ui/core/IconButton'
import {
  mdiChevronRight,
  mdiLockOpen,
  mdiBarcode,
  mdiLockAlert,
  mdiDialpad,
  mdiLock,
  mdiAccountLock,
  mdiCellphoneLock,
  mdiHelpBox,
  mdiCamera,
} from '@mdi/js'

import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'

import Toolbar from '@material-ui/core/Toolbar'

import { isArray } from 'lodash'
import moment from 'moment'

import i18n from 'I18N'

import { activities as activitiesRoute } from 'Constants/routes'
import EventType from 'Constants/benchEventTypes'
import BenchCodeType from 'Constants/benchCodeTypes'

import { useStyles } from './styles'

const EventBlock = ({
  event,
  classes,
  trackCodes,
  trackCodeArchive,
  entryCodes,
}) => {
  const getPackageName = id => {
    const findTrackCode = trackCodes[id]
    const findTrackCodeArch = trackCodeArchive[id]

    let descriptionValue
    const arr = findTrackCodeArch || findTrackCode

    if (!arr) {
      descriptionValue = {
        name: null,
        type: 'package_delivered_unknown',
      }
    } else if (arr.notes) {
      descriptionValue = {
        name: arr.notes,
        type: 'package_delivered_notes',
      }
    } else if (arr.carrier && arr.carrier !== 'unknown') {
      descriptionValue = {
        name: arr.carrier,
        type: 'package_delivered_carrier',
      }
    } else {
      descriptionValue = {
        name: null,
        type: 'package_delivered_unknown',
      }
    }

    return descriptionValue
  }

  const getEntryName = id => {
    const findEntryCode = entryCodes[id]

    const eventName = { name: '' }
    if (findEntryCode && findEntryCode.name) {
      const { name } = findEntryCode
      eventName.name = name
    }
    return eventName
  }

  const [
    eventTitle,
    eventDescription,
    eventIcon,
    eventItemName,
    time,
    eventAvatarClass,
  ] = useMemo(() => {
    let title = ''
    let description = ''
    let icon = mdiHelpBox
    let itemName = null

    let eventTime = null
    let avatarClass = classes.avatarGreen

    switch (event.type) {
      case EventType.LOCK_FAILED:
        title = 'lock_failed'
        description = 'lock_failed'
        icon = mdiLockAlert
        eventTime = event.time

        break
      case EventType.INVALID_CODE_ENTRY:
        title = 'invalid_code'
        description = 'invalid_code'
        icon = mdiDialpad
        eventTime = event.time
        avatarClass = classes.avatarRed

        break
      case EventType.PICTURE_TAKEN:
        title = 'picture_taken'
        description = 'picture_taken'
        icon = mdiCamera
        eventTime = event.time

        break
      case EventType.LOCKED:
        title = 'locked'
        description = 'locked'
        icon = mdiLock
        eventTime = event.time

        break
      case EventType.MOBILE_LOCK:
        title = 'mobile_lock'
        description = 'mobile_lock'
        icon = mdiAccountLock
        eventTime = event.time

        break
      case EventType.MOBILE_UNLOCK:
        title = 'mobile_unlock'
        description = 'unlockedByMobile'
        icon = mdiCellphoneLock
        eventTime = event.time

        break
      case EventType.UNLOCKED:
        switch (event.codeType) {
          case BenchCodeType.PACKAGE: {
            const { type, name } = getPackageName(event.packageId)

            title = type
            description = 'package_delivered'
            icon = mdiBarcode
            eventTime = event.time
            itemName = name

            break
          }
          case BenchCodeType.ENTRY: {
            const { name } = getEntryName(event.entryCodeId)

            title = 'unlockedEntry'
            description = 'unlockedEntry'
            icon = mdiAccountLock
            eventTime = event.time
            itemName = name

            break
          }
          case BenchCodeType.OWNER:
            title = 'owner'
            description = 'owner'
            icon = mdiAccountLock
            eventTime = event.time

            break
          default:
            break
        }
        break
      case EventType.UNKNOWN_DELIVERY:
        title = 'unknown'
        description = 'unknown'
        icon = mdiHelpBox
        eventTime = event.time
        avatarClass = classes.avatarYellow

        break
      case EventType.LID_OPEN:
        title = 'lid_open'
        description = 'lid_open'
        icon = mdiLockOpen
        eventTime = event.time

        break
      default:
        console.warn('UNKNOWN EVENT TYPE', event.type)
        break
    }

    return [title, description, icon, itemName, eventTime, avatarClass]
  }, [])

  const title = `${eventItemName ? `${eventItemName} ` : ''}${i18n.t(
    `activityScreen.type.${eventTitle}`,
  )}`
  const description = i18n.t(`activityScreen.description.${eventDescription}`)

  const eventTime = moment(time).format('h:mm a')

  return (
    <ListItem alignItems="flex-start" button key={event.id}>
      <ListItemAvatar>
        <>
          <Avatar className={eventAvatarClass}>
            <Icon path={eventIcon} color="white" size="12px" />
          </Avatar>
        </>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <>
            <Typography
              component="span"
              variant="body2"
              className={classes.inline}
              color="textSecondary"
            >
              {description}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {eventTime}
            </Typography>
          </>
        }
      />
      <ListItemSecondaryAction>
        <IconButton edge="end" aria-label="comments">
          <Icon path={mdiChevronRight} size="24px" />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

EventBlock.propTypes = {
  event: PT.object,
}

const EventsList = ({ events, trackCodes, trackCodeArchive, entryCodes }) => {
  const classes = useStyles()

  return (
    <Grid item lg={4} xs={12}>
      <Card className={classes.root}>
        <Toolbar
          disableGutters
          className={classes.tableActionBar}
          display="flex"
          alignItems="center"
        >
          <Typography className={classes.title} variant="h6">
            {i18n.t('homeScreen.labels.events.header')}
          </Typography>
        </Toolbar>
        <List className={classes.list}>
          {isArray(events) && events.length > 0 ? (
            events.map(event => (
              <EventBlock
                classes={classes}
                event={event}
                key={event.id}
                trackCodes={trackCodes}
                trackCodeArchive={trackCodeArchive}
                entryCodes={entryCodes}
              />
            ))
          ) : (
            <ListItem alignItems="flex-start">
              <Typography variant="subtitle1">
                {i18n.t('activityScreen.emptyBlock.text')}
              </Typography>
            </ListItem>
          )}
        </List>

        <Toolbar
          disableGutters
          className={classes.tableFooterBar}
          display="flex"
          alignItems="center"
        >
          <RouterLink
            to={activitiesRoute}
            style={{ color: '#000', textDecoration: 'none' }}
          >
            <Button
              size="small"
              endIcon={<Icon path={mdiChevronRight} size="24px" />}
            >
              {i18n.t('homeScreen.labels.events.viewAllBtn')}
            </Button>
          </RouterLink>
        </Toolbar>
      </Card>
    </Grid>
  )
}

EventsList.propTypes = {
  events: PT.arrayOf(PT.object),
}

export { EventsList }
