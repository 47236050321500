import React from 'react'
import { Formik } from 'formik'
import PhoneNumberInput from 'react-phone-number-input/input'

import PT from 'prop-types'
import { get } from 'lodash'

import {
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@material-ui/core'

import i18n from 'I18N'

const EditPhoneNumberModal = ({ isOpen, onClose, onConfirm, user }) => {
  const currentValue = get(user, 'phoneNumber', '')

  const handleConfirm = data => {
    onConfirm(data.phoneNumber)
  }

  const handleCancel = () => {
    onClose()
  }

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={isOpen}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {i18n.t(`accountSettingsScreen.editValueModal.types.phoneNumber.title`)}
      </DialogTitle>
      <Formik
        initialValues={{
          phoneNumber: currentValue || '',
        }}
        onSubmit={handleConfirm}
      >
        {({
          values = {},
          errors,
          touched,
          setValues,
          handleBlur,
          handleSubmit,
        }) => {
          const handleInputKeyDown = e => {
            if (e.key === 'Enter') {
              handleSubmit()
            }
          }

          const renderInput = React.forwardRef((inputProps, ref) => (
            <TextField
              autoFocus
              error={errors.phoneNumber && touched.phoneNumber}
              fullWidth
              helperText={errors.phoneNumber}
              margin="dense"
              id="phoneNumber"
              name="phoneNumber"
              label={i18n.t(
                `accountSettingsScreen.editValueModal.types.phoneNumber.inputLabel`,
              )}
              type="text"
              inputRef={ref}
              variant="outlined"
              onBlur={handleBlur}
              inputProps={{ maxlength: 14, ...inputProps }}
              onKeyDown={handleInputKeyDown}
            />
          ))

          return (
            <>
              <DialogContent
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Typography style={{ marginRight: '8px', marginTop: '8px' }}>
                  +1
                </Typography>
                <PhoneNumberInput
                  country="US"
                  inputComponent={renderInput}
                  value={values.phoneNumber}
                  onChange={nextVal => setValues({ phoneNumber: nextVal })}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCancel} color="primary">
                  {i18n.t(
                    'accountSettingsScreen.editValueModal.cancelBtnLabel',
                  )}
                </Button>
                <Button
                  onClick={handleSubmit}
                  color="secondary"
                  variant="contained"
                >
                  {i18n.t('accountSettingsScreen.editValueModal.saveBtnLabel')}
                </Button>
              </DialogActions>
            </>
          )
        }}
      </Formik>
    </Dialog>
  )
}

EditPhoneNumberModal.defaultProps = {
  isOpen: false,
  user: {},
}

EditPhoneNumberModal.propTypes = {
  isOpen: PT.bool,
  user: PT.object,
  onClose: PT.func,
  onConfirm: PT.func,
}

export { EditPhoneNumberModal }
