import { takeLatest } from 'redux-saga/effects'

// action types
import {
  UPDATE_NAME,
  UPDATE_EMAIL,
  UPDATE_PASSWORD,
  APPLY_PHONE_VERIFICATION_CODE,
  UPDATE_ADDRESS,
} from 'Redux/actions/user'
import { UPDATE_BENCH_FIELDS } from 'Redux/actions/bench'

import onSettingUpdateSuccess from './onSettingUpdateSuccess'

function* rootSaga() {
  yield takeLatest(UPDATE_NAME.SUCCESS, onSettingUpdateSuccess)
  yield takeLatest(UPDATE_EMAIL.SUCCESS, onSettingUpdateSuccess)
  yield takeLatest(UPDATE_PASSWORD.SUCCESS, onSettingUpdateSuccess)
  yield takeLatest(UPDATE_BENCH_FIELDS.SUCCESS, onSettingUpdateSuccess)
  yield takeLatest(UPDATE_ADDRESS.SUCCESS, onSettingUpdateSuccess)
  yield takeLatest(
    APPLY_PHONE_VERIFICATION_CODE.SUCCESS,
    onSettingUpdateSuccess,
  )
}

export default rootSaga
