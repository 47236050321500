import * as Yup from 'yup'

import i18n from 'I18N'

const errorMessages = i18n.t('preOrderScreen.validation.errorMessages')

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().required(errorMessages.requiredField),
  lastName: Yup.string().required(errorMessages.requiredField),
  businessName: Yup.string(),
  email: Yup.string()
    .required(errorMessages.requiredField)
    .email(errorMessages.validEmail),
  billing_street1: Yup.string().required(errorMessages.requiredField),
  billing_street2: Yup.string(),
  billing_city: Yup.string().required(errorMessages.requiredField),
  billing_province: Yup.string().required(errorMessages.requiredField),
  billing_postalCode: Yup.string().required(errorMessages.requiredField),
  copyBillingInfo: Yup.boolean().default(false),
  shipping_firstName: Yup.string().when('copyBillingInfo', {
    is: false,
    then: Yup.string().required(errorMessages.requiredField),
    otherwise: Yup.string(),
  }),
  shipping_lastName: Yup.string().when('copyBillingInfo', {
    is: false,
    then: Yup.string().required(errorMessages.requiredField),
    otherwise: Yup.string(),
  }),
  shipping_businessName: Yup.string(),
  shipping_email: Yup.string().when('copyBillingInfo', {
    is: false,
    then: Yup.string().email(errorMessages.validEmail),
    otherwise: Yup.string(),
  }),
  shipping_street1: Yup.string().when('copyBillingInfo', {
    is: false,
    then: Yup.string().required(errorMessages.requiredField),
    otherwise: Yup.string(),
  }),
  shipping_street2: Yup.string(),
  shipping_city: Yup.string().when('copyBillingInfo', {
    is: false,
    then: Yup.string().required(errorMessages.requiredField),
    otherwise: Yup.string(),
  }),
  shipping_province: Yup.string().when('copyBillingInfo', {
    is: false,
    then: Yup.string().required(errorMessages.requiredField),
    otherwise: Yup.string(),
  }),
  shipping_postalCode: Yup.string().when('copyBillingInfo', {
    is: false,
    then: Yup.string().required(errorMessages.requiredField),
    otherwise: Yup.string(),
  }),
})
