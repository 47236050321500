import * as Yup from 'yup'
import i18n from 'I18N'

export const validationSchema = Yup.object().shape({
  address: Yup.string().required(
    i18n.t('finalizeAccount.validation.errorMessages.required'),
  ),
  address2: Yup.string(),
  city: Yup.string().required(
    i18n.t('finalizeAccount.validation.errorMessages.required'),
  ),
  state: Yup.string().required(
    i18n.t('finalizeAccount.validation.errorMessages.required'),
  ),
  zip: Yup.string().required(
    i18n.t('finalizeAccount.validation.errorMessages.required'),
  ),
})
