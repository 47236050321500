import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import {
  updateUserPhoneNumber,
  updateUserEmail,
  applyPhoneVerificationCode,
  checkUserPassword,
  updateUserAddress,
  updateUserProfile,
  updateUserPassword,
} from 'Redux/actions/user'
import {
  updateBenchFields,
  setBenchOwnerCode,
  updateLockSettings,
} from 'Redux/actions/bench'

import { getUser } from 'Redux/selectors/user'
import {
  getPrimaryBench,
  getPrimaryBenchOwnerCode,
} from 'Redux/selectors/bench'

import Component from './Component'

const actions = {
  onEmailUpdate: updateUserEmail,
  onPhoneNumberUpdate: updateUserPhoneNumber,
  onPhoneNumberVerificationCodeApply: applyPhoneVerificationCode,
  onBenchFieldsUpdate: updateBenchFields,
  onCheckPassword: checkUserPassword,
  onAddressUpdate: updateUserAddress,
  onUpdateProfile: updateUserProfile,
  onSetOwnerCode: setBenchOwnerCode,
  onUpdateLockSettings: updateLockSettings,
  onUpdatePassword: updateUserPassword,
}

const selector = createStructuredSelector({
  user: getUser,
  bench: getPrimaryBench,
  ownerCode: getPrimaryBenchOwnerCode,
})

export default connect(
  selector,
  actions,
)(Component)
