import { put } from 'redux-saga/effects'
import _ from 'lodash'

import EventsService from 'Services/events/EventsService'

import { setPackagesArchiveList } from 'Redux/actions/benchCodes'

function* onFetchPackagesArchiveListSucess(action) {
  const payload = _.get(action, 'payload.data', {})

  const { benchId, list = [] } = payload

  const promises = list.map(async item => {
    const deliveredEvent =
      (await EventsService.getEventData(benchId, item.deliveredEventId)) || null

    return Object.assign(item, { deliveredEvent })
  })

  const processedList = yield Promise.all(promises)

  yield put(setPackagesArchiveList(benchId, processedList))
}

export default onFetchPackagesArchiveListSucess
