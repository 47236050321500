import { makeStyles } from '@material-ui/core/styles'
import { green, red, yellow } from '@material-ui/core/colors'

export const useStyles = makeStyles(theme => ({
  avatarGreen: {
    backgroundColor: green[500],
    width: 28,
    height: 28,
  },
  avatarYellow: {
    backgroundColor: yellow[700],
    width: 28,
    height: 28,
  },
  avatarRed: {
    backgroundColor: red[500],
    width: 28,
    height: 28,
  },
  card: {
    marginBottom: 8,
  },
  cardContent: {
    padding: '0px !important',
  },
  button: {
    margin: '8px 0',
    padding: '4px 16px',
  },
  icon: {
    marginRight: 4,
  },
  topBar: {
    height: 80,
  },
  locked: {
    width: 28,
    height: 28,
    backgroundColor: '#26A842',
  },
  sectionHeader: {
    fontSize: 18,
    color: '#363B46',
    borderBottom: '1px solid #D6D6D6',
    margin: '32px 0 16px',
    paddingBottom: 8,
  },
  cardListActionContainer: {
    [theme.breakpoints.down('sm')]: {
      top: 0,
      right: 0,
      position: 'relative',
      transform: 'inherit',
      padding: '0 16px',
      display: 'flex',
      justifyContent: 'space-around',
    },
  },
}))
