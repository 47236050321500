export default {
  fedex: {
    title: 'FedEx',
  },
  usps: {
    title: 'USPS',
  },
  amazon: {
    title: 'Amazon',
  },
  ups: {
    title: 'UPS',
  },
  dhl: {
    title: 'DHL',
  },
}
