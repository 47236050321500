import React from 'react'

import PT from 'prop-types'

import Grid from '@material-ui/core/Grid'
import { Typography, Button } from '@material-ui/core'

import { mdiAccountQuestion } from '@mdi/js'
import Icon from '@mdi/react'

import i18n from 'I18N'

const SetupYourAccount = ({ onSignOut }) => {
  const handleSignOutBtnPress = () => {
    onSignOut()
  }

  return (
    <Grid
      container
      display="flex"
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '100vh', backgroundColor: '#fff', color: 'navy' }}
    >
      <Grid item xs={8} md={4} lg={3} xl={2}>
        <Icon path={mdiAccountQuestion} size="80px" color="navy" />
      </Grid>
      <Grid item xs={8} md={4} lg={3} xl={2}>
        <Typography variant="h5">{i18n.t('setupYourAccount.title')}</Typography>
      </Grid>
      <Grid item xs={8} md={4} lg={3} xl={2}>
        <Button onClick={handleSignOutBtnPress} color="primary">
          <Typography variant="h6">
            {i18n.t('setupYourAccount.signOutBtnLabel')}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  )
}

SetupYourAccount.propTypes = {
  onSignOut: PT.func,
}

export default SetupYourAccount
