import React from 'react'
import { Formik } from 'formik'

import { Link as RouterLink } from 'react-router-dom'

import {
  Typography,
  TextField,
  Button,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
} from '@material-ui/core'

import { SubdirectoryArrowRight as SubdirectoryArrowRightIcon } from '@material-ui/icons'

import PreOrderSignUpHeroImage from 'Assets/preOrderSignUpHero.png'

import i18n from 'I18N'

import {
  privacyPolicy as privacyPolicyLink,
  termsOfServices as termsOfServicesLink,
  home as homeLink,
} from 'Constants/routes'

import { validationSchema } from './config'

import { useStyles } from './styles'

function LoginPage({ onSignUp }) {
  const classes = useStyles()

  const handleSignupBtnClick = values => {
    onSignUp({ email: values.email, password: values.password })
  }

  return (
    <Grid
      container
      display="flex"
      spacing={0}
      direction="row"
      alignItems="center"
      justify="space-around"
      style={{
        minHeight: '100vh',
      }}
    >
      <Grid md={8}>
        <img
          alt=""
          style={{
            width: '100%',
          }}
          src={PreOrderSignUpHeroImage}
        />
      </Grid>
      <Grid item xs={8} md={4} lg={3} xl={2}>
        <img alt="" src="logo.svg" className={classes.logo} />

        {i18n.t('preOrderSignUpScreen.headerText').map(line => (
          <Typography
            align="center"
            variant="subtitle1"
            className={classes.title}
          >
            {line}
          </Typography>
        ))}
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSignupBtnClick}
        >
          {({
            values = {},
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => {
            const handleInputKeyDown = e => {
              if (e.key === 'Enter') {
                handleSubmit()
              }
            }

            return (
              <>
                <TextField
                  autoFocus
                  error={errors.email && touched.email}
                  fullWidth
                  margin="dense"
                  id="email"
                  helperText={errors.email}
                  name="email"
                  label={i18n.t('preOrderSignUpScreen.fields.email.label')}
                  required
                  type="text"
                  value={values.email}
                  variant="outlined"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onKeyDown={handleInputKeyDown}
                />
                <TextField
                  error={errors.password && touched.password}
                  fullWidth
                  margin="dense"
                  id="password"
                  helperText={errors.password}
                  name="password"
                  label={i18n.t('preOrderSignUpScreen.fields.pass.label')}
                  required
                  type="password"
                  value={values.password}
                  variant="outlined"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onKeyDown={handleInputKeyDown}
                />
                <List>
                  {i18n
                    .t('createAccountScreen.passRequirments')
                    .map(reqText => (
                      <ListItem
                        key={reqText}
                        style={{
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      >
                        <ListItemIcon
                          style={{
                            minWidth: 0,
                            marginRight: '8px',
                          }}
                        >
                          <SubdirectoryArrowRightIcon
                            style={{ fontSize: '0.9rem' }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          disableTypography
                          style={{ fontSize: '0.8rem' }}
                          primary={reqText}
                        />
                      </ListItem>
                    ))}
                </List>

                <Button
                  fullWidth
                  color="secondary"
                  variant="contained"
                  className={classes.button}
                  onClick={handleSubmit}
                >
                  {i18n.t('preOrderSignUpScreen.submitBtnLabel')}
                </Button>
                <RouterLink style={{ textAlign: 'center' }} to={homeLink}>
                  <Typography style={{ marginTop: '4px' }}>
                    {i18n.t('preOrderSignUpScreen.loginBtnLabel')}
                  </Typography>
                </RouterLink>

                <Box style={{ marginTop: '16px', textAlign: 'center' }}>
                  <Typography variant="subtitle1">
                    {i18n.t('createAccountScreen.footerText')}
                  </Typography>
                  <RouterLink to={termsOfServicesLink} target="_blank">
                    <Typography>
                      {i18n.t('createAccountScreen.termsLink')}
                    </Typography>
                  </RouterLink>
                  <RouterLink to={privacyPolicyLink} target="_blank">
                    <Typography>
                      {i18n.t('createAccountScreen.privacyLink')}
                    </Typography>
                  </RouterLink>
                </Box>
              </>
            )
          }}
        </Formik>
      </Grid>
    </Grid>
  )
}

export default LoginPage
