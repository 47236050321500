import _ from 'lodash'

// eslint-disable-next-line
import { store } from 'Redux'

import { subscribePackageUpdates } from 'Redux/actions/benchCodes'

function* onFetchPackagesListSucess(action) {
  const payload = _.get(action, 'payload.data', {})

  const { benchId, list = [] } = payload

  list.forEach(item => {
    store.dispatch(subscribePackageUpdates(benchId, item.packageId))
  })

  yield true
}

export default onFetchPackagesListSucess
