import React from 'react'
import { Formik } from 'formik'

import PT from 'prop-types'

import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
} from '@material-ui/core'

import i18n from 'I18N'

import { validationSchema } from './config'

const EditAddressModal = ({ isOpen, user, onClose, onConfirm }) => {
  const handleConfirm = data => {
    onConfirm(data)
  }

  const handleCancel = () => {
    onClose()
  }

  const { address, address2, city, state, zip } = user

  const initialValues = {
    address: address || '',
    address2: address2 || '',
    city: city || '',
    state: state || '',
    zip: zip || '',
  }

  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {i18n.t('changeAddressScreen.header')}
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleConfirm}
      >
        {({
          values = {},
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => {
          const handleInputKeyDown = e => {
            if (e.key === 'Enter') {
              handleSubmit()
            }
          }

          const usaStates = i18n.t('common.usaStates')

          return (
            <>
              <DialogContent>
                <TextField
                  error={errors.address && touched.address}
                  fullWidth
                  helperText={errors.address}
                  margin="dense"
                  id="address"
                  label={i18n.t('changeAddressScreen.fields.address.label')}
                  type="text"
                  variant="outlined"
                  value={values.address}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onKeyDown={handleInputKeyDown}
                />
                <TextField
                  error={errors.address2 && touched.address2}
                  fullWidth
                  helperText={errors.address2}
                  margin="dense"
                  id="address2"
                  label={i18n.t('changeAddressScreen.fields.address2.label')}
                  type="text"
                  variant="outlined"
                  value={values.address2}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onKeyDown={handleInputKeyDown}
                />
                <TextField
                  error={errors.city && touched.city}
                  fullWidth
                  helperText={errors.city}
                  margin="dense"
                  id="city"
                  label={i18n.t('changeAddressScreen.fields.city.label')}
                  type="text"
                  variant="outlined"
                  value={values.city}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onKeyDown={handleInputKeyDown}
                />
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignContent="flex-start"
                >
                  <Grid
                    item
                    xs
                    style={{
                      marginRight: '8px',
                    }}
                  >
                    <TextField
                      error={errors.state && touched.state}
                      fullWidth
                      helperText={errors.state}
                      margin="dense"
                      id="state"
                      label={i18n.t('changeAddressScreen.fields.state.label')}
                      select
                      variant="outlined"
                      value={values.state}
                      onBlur={handleBlur}
                      onChange={({ target }) =>
                        setFieldValue('state', target.value)
                      }
                      onKeyDown={handleInputKeyDown}
                    >
                      {usaStates.map(usState => (
                        <MenuItem value={usState.abbreviation}>
                          {usState.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs>
                    <TextField
                      error={errors.zip && touched.zip}
                      fullWidth
                      helperText={errors.zip}
                      margin="dense"
                      id="zip"
                      label={i18n.t('changeAddressScreen.fields.zip.label')}
                      type="text"
                      variant="outlined"
                      value={values.zip}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onKeyDown={handleInputKeyDown}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCancel} color="primary">
                  {i18n.t('changeAddressScreen.skipBtnLabel')}
                </Button>
                <Button
                  onClick={handleSubmit}
                  color="secondary"
                  variant="contained"
                >
                  {i18n.t('changeAddressScreen.saveBtnLabel')}
                </Button>
              </DialogActions>
            </>
          )
        }}
      </Formik>
    </Dialog>
  )
}

EditAddressModal.propTypes = {
  isOpen: PT.bool.isRequired,
  user: PT.object,
  onClose: PT.func.isRequired,
  onConfirm: PT.func.isRequired,
}

export { EditAddressModal }
