import moment from 'moment'
import _ from 'lodash'

// eslint-disable-next-line
import { store } from 'Redux'
import {
  subscribeEntryCodeListUpdate,
  fetchEntryCodesList,
} from 'Redux/actions/benchCodes'

function* onAddEntryCodeForNewUser(action) {
  const payload = _.get(action, 'payload.data', {})
  const { benchId } = payload
  const nowTimestamp = moment().valueOf()
  const checkStore = store.getState().benchCodes.entryCodes[benchId]

  if (!checkStore || (checkStore && !checkStore.length)) {
    store.dispatch(subscribeEntryCodeListUpdate(benchId, nowTimestamp))
    store.dispatch(fetchEntryCodesList(benchId))
  }

  yield true
}

export default onAddEntryCodeForNewUser
