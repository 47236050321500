import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { getUser } from 'Redux/selectors/user'
import { getPreorder, getHelcimUser } from 'Redux/selectors/preOrder'

import {
  updatePreorderDetails,
  addHelcimCreditCard,
  fetchHelcimUser,
  cancelPreorder,
} from 'Redux/actions/preOrder'

import Component from './Component'

const actions = {
  onUpdatePreorderDetails: updatePreorderDetails,
  onUpdateCreditCard: addHelcimCreditCard,
  onFetchHelcimUser: fetchHelcimUser,
  onCancelPreorder: cancelPreorder,
}

const selector = createStructuredSelector({
  user: getUser,
  preOrder: getPreorder,
  helcimUser: getHelcimUser,
})

export default connect(
  selector,
  actions,
)(Component)
