import * as Yup from 'yup'
import i18n from 'I18N'

const errorMessages = i18n.t('accountSettingsScreen.validation.errorMessages')

const validationSchemas = {
  benchName: Yup.object().shape({
    nextValue: Yup.string().max(50, errorMessages.benchNameMaxLength),
  }),
  email: Yup.object().shape({
    nextValue: Yup.string()
      .email(errorMessages.validEmail)
      .required(errorMessages.requireEmail),
  }),
  phoneNumber: Yup.object().shape({
    nextValue: Yup.string().matches(/[0-9]{10}/, errorMessages.validMobile),
  }),
  firstName: Yup.object().shape({
    nextValue: Yup.string(),
  }),
  lastName: Yup.object().shape({
    nextValue: Yup.string(),
  }),
}

export { errorMessages, validationSchemas }
