import React from 'react'
import { Formik } from 'formik'

import PT from 'prop-types'

import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

import i18n from 'I18N'

import { validationSchema } from './config'

const ChangeBenchOwnerCodeModal = ({
  isOpen,
  ownerCode,
  onClose,
  onConfirm,
}) => {
  const handleConfirm = data => {
    onConfirm(data)
  }

  const handleCancel = () => {
    onClose()
  }

  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {i18n.t('changeBenchOwnerScreen.header.title')}
      </DialogTitle>
      <Formik
        initialValues={{
          current: ownerCode.code,
          new: '',
          confirm: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleConfirm}
      >
        {({
          values = {},
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => {
          const handleInputKeyDown = e => {
            if (e.key === 'Enter') {
              handleSubmit()
            }
          }

          return (
            <>
              <DialogContent>
                <>
                  {i18n
                    .t('changeBenchOwnerScreen.header.description')
                    .map((text, index) => (
                      <DialogContentText
                        key={index}
                        style={index === 0 ? { marginBottom: '4px' } : {}}
                      >
                        {text}
                      </DialogContentText>
                    ))}
                </>
                <TextField
                  contentEditable={false}
                  fullWidth
                  margin="dense"
                  label={i18n.t('changeBenchOwnerScreen.fields.currentCode')}
                  type="text"
                  variant="outlined"
                  value={values.current}
                />
                <TextField
                  error={errors.new && touched.new}
                  fullWidth
                  helperText={errors.new}
                  margin="dense"
                  id="new"
                  label={i18n.t('changeBenchOwnerScreen.fields.newCode')}
                  type="text"
                  variant="outlined"
                  value={values.new}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onKeyDown={handleInputKeyDown}
                />
                <TextField
                  error={errors.confirm && touched.confirm}
                  fullWidth
                  helperText={errors.confirm}
                  margin="dense"
                  id="confirm"
                  label={i18n.t('changeBenchOwnerScreen.fields.confirmCode')}
                  type="text"
                  variant="outlined"
                  value={values.confirm}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onKeyDown={handleInputKeyDown}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCancel} color="primary">
                  {i18n.t('changeBenchOwnerScreen.buttons.cancel')}
                </Button>
                <Button
                  onClick={handleSubmit}
                  color="secondary"
                  variant="contained"
                >
                  {i18n.t('changeBenchOwnerScreen.buttons.submit')}
                </Button>
              </DialogActions>
            </>
          )
        }}
      </Formik>
    </Dialog>
  )
}

ChangeBenchOwnerCodeModal.propTypes = {
  ownerCode: PT.object,
  isOpen: PT.bool.isRequired,
  onClose: PT.func.isRequired,
  onConfirm: PT.func.isRequired,
}

export { ChangeBenchOwnerCodeModal }
