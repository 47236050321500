import { put } from 'redux-saga/effects'

// import { setupRoot } from 'Navigation'

// import {
//   userAccountSetupState,
//   getCurrentRegisterStepRoute,
// } from 'Config/registrationFlow'

// import UserService from 'Services/user/UserService'

import { subscribeUserChanged } from 'Redux/actions/user'
import subscribeBenchesUpdate from './subscribeBenchesUpdate'

function* onAuthStateChange(action) {
  try {
    const isAuth = !!(action.payload.data && action.payload.data.uid)

    // let currentRegisterStep = null

    if (isAuth) {
      yield put(subscribeUserChanged())

      const { uid } = action.payload.data
      // const setupState = yield UserService.getUserSetupState(uid)

      // if (setupState !== userAccountSetupState.completed) {
      //   isAuth = false
      //   currentRegisterStep = getCurrentRegisterStepRoute(setupState)
      // }

      // subscribe on user's benches list updates
      subscribeBenchesUpdate(uid)
    }

    // setupRoot(isAuth, currentRegisterStep)

    yield true
  } catch (err) {
    console.error(err)
  }
}

export default onAuthStateChange
