import * as Yup from 'yup'
import i18n from 'I18N'

const errorMessages = i18n.t('entryCodesScreen.addModal.errorMessages')

const validationSchema = Yup.object().shape({
  name: Yup.string().required(errorMessages.name.required),
  code: Yup.string()
    .min(5, errorMessages.code.tooShort)
    .max(12, errorMessages.code.tooLong)
    .matches(/^(.*[0-9a-z])$/i, errorMessages.code.alphaNumeric)
    .required(errorMessages.code.required),
  expDate: Yup.date(),
})

export { validationSchema }
