import React from 'react'

import PT from 'prop-types'

import moment from 'moment'
import { isArray, isString } from 'lodash'

import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  DialogTitle,
  Typography,
} from '@material-ui/core'

import i18n from 'I18N'

import { MediaPlayer } from '../MediaPlayer'

const EventDetailsModal = ({ isOpen, event, onClose }) => {
  if (!event) return null

  const { title, description, mediaUrls, eventTime } = event

  return (
    <Dialog aria-labelledby="form-dialog-title" open={isOpen} onClose={onClose}>
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {description && <DialogContentText>{description}</DialogContentText>}
        {isArray(mediaUrls) && isString(mediaUrls[0]) && (
          <Box display="flex" alignItems="center" justifyContent="center">
            <MediaPlayer url={mediaUrls[0]} />
          </Box>
        )}
        <Typography variant="body2" color="textSecondary">
          {i18n.t('trackCodesScreen.details.statuses.taken', {
            date: moment(eventTime).format('M/D/YY'),
            time: moment(eventTime).format('h:mm A'),
          })}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {i18n.t('activityScreen.details.closeBtnLabel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

EventDetailsModal.propTypes = {
  isOpen: PT.bool.isRequired,
  title: PT.string,
  description: PT.string,
  onClose: PT.func.isRequired,
}

export { EventDetailsModal }
