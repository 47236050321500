import firebase from 'firebase/app'
import 'firebase/database'

import { orderBy, last } from 'lodash'

import PreorderStatus from 'Constants/PreorderStatus'

class PreOrderService {
  /**
   * Add preorder to DB
   */
  addPreorderData = async (userId, customerCode, orderDetails) => {
    const newPreOrder = {
      userId,
      helcimCustomerCode: customerCode,
      orderDetails,
      createdAt: firebase.database.ServerValue.TIMESTAMP,
      updatedAt: firebase.database.ServerValue.TIMESTAMP,
      orderStatus: PreorderStatus.pendingCreditCard,
    }

    const ref = firebase.database().ref(`preOrders`)
    await ref.push(newPreOrder)

    const newPreorder = await this.findUsersPreorder(userId)

    return newPreorder
  }

  /**
   * Update preorder
   */
  updatePreorderData = async (preorderId, orderUpdate) => {
    const newPreOrder = {
      ...orderUpdate,
      updatedAt: firebase.database.ServerValue.TIMESTAMP,
    }

    const ref = firebase.database().ref(`preOrders/${preorderId}`)
    await ref.update(newPreOrder)

    return newPreOrder
  }

  /**
   * Update preorder details
   */
  updatePreorderDetails = async (preorderId, nextOrderDetails) => {
    const nextPackage = await this.updatePreorderData(preorderId, {
      orderDetails: nextOrderDetails,
    })

    return nextPackage
  }

  /**
   * Call cancelPreorder firebase function
   */
  cancelPreorder = async (preorderId, helcimCustomerCode, userId) => {
    const cancelPreorderFunc = firebase
      .functions()
      .httpsCallable('cancelPreorder')

    const funcResult = await cancelPreorderFunc({
      preorderId,
      helcimCustomerCode,
      userId,
    })

    return funcResult.data
  }

  /**
   * Find user's active prorder
   */
  findUsersPreorder = userId => {
    return new Promise((resolve, reject) => {
      const ref = firebase
        .database()
        .ref('preOrders')
        .orderByChild('userId')
        .equalTo(userId)

      ref.once(
        'value',
        snap => {
          if (snap && snap.val()) {
            const prorders = Object.entries(snap.val() || {}).map(
              ([preorderId, preorder]) => ({
                ...preorder,
                preorderId,
              }),
            )

            const ordered = orderBy(prorders, 'createdAt')

            resolve(last(ordered))
          } else {
            resolve(null)
          }
        },
        error => {
          reject(error)
        },
      )
    })
  }

  /**
   * Get preorder config from DB
   */
  fetchPreordersConfig = () => {
    return new Promise((resolve, reject) => {
      const ref = firebase.database().ref('preOrdersConfig')

      ref.once(
        'value',
        snap => {
          if (snap) {
            resolve(snap.val())
          } else {
            resolve(null)
          }
        },
        error => reject(error),
      )
    })
  }
}

export default new PreOrderService()
