import moment from 'moment'

// eslint-disable-next-line
import { store } from 'Redux'

import BenchService from 'Services/bench/BenchService'

import {
  subcribeBenchStatusChange,
  unsubscribeBenchUpdates,
  benchOnwerCodeSubscribe,
  subscribeBenchEvents,
  fetchEventsList,
  fetchAllEventsList,
  benchEntryCodeSubscribe,
  benchTrackCodeSubscribe,
  benchOnwersCodeStatusSubscribe,
} from 'Redux/actions/bench'

import {
  subscribeEntryCodeListUpdate,
  subscribeTrackCodeListUpdate,
  subscribeTrackCodeArchiveListUpdate,
  fetchPackagesList,
  fetchPackagesArchiveList,
  fetchEntryCodesList,
} from 'Redux/actions/benchCodes'

function subscribe(userID) {
  try {
    const onUserBenchListUpdate = BenchService.onUserBenchListUpdate(userID)

    onUserBenchListUpdate.onBenchAdd.subscribe(benchID => {
      const nowTimestamp = moment().valueOf()
    
      const startOfDay = moment()
        .startOf('day')
        .valueOf()

      // bench
      store.dispatch(subcribeBenchStatusChange(benchID))
      store.dispatch(benchOnwerCodeSubscribe(benchID, userID))
      store.dispatch(benchEntryCodeSubscribe(benchID))
      store.dispatch(benchTrackCodeSubscribe(benchID))
      store.dispatch(benchOnwersCodeStatusSubscribe(benchID))

      // entry codes
      store.dispatch(subscribeEntryCodeListUpdate(benchID, nowTimestamp))
      store.dispatch(fetchEntryCodesList(benchID))

      // packages
      store.dispatch(subscribeTrackCodeListUpdate(benchID, nowTimestamp))
      store.dispatch(fetchPackagesList(benchID))
      setTimeout(() => {
        store.dispatch(fetchPackagesList(benchID))
      }, 3000)
      setTimeout(() => {
        store.dispatch(fetchPackagesList(benchID))
      }, 9000)

      // packages archive
      store.dispatch(subscribeTrackCodeArchiveListUpdate(benchID, nowTimestamp))
      store.dispatch(fetchPackagesArchiveList(benchID))

      // events
      store.dispatch(subscribeBenchEvents(benchID, startOfDay))
      store.dispatch(fetchEventsList(benchID, startOfDay))
      store.dispatch(fetchAllEventsList(benchID))
    })

    onUserBenchListUpdate.onBenchRemove.subscribe(benchID => {
      store.dispatch(unsubscribeBenchUpdates(benchID, userID))
    })
  } catch (err) {
    console.error(err)
  }
}

export default subscribe
