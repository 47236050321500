import { createReducer } from 'Helpers/redux'
import updateOrPush from 'Helpers/updateOrPush'

import packageStatus from 'Constants/packageStatus'

import { UNSUBSCRIBE_BENCH_UPDATE } from 'Redux/actions/bench'

import {
  ADD_ENTRY_CODE_TO_LIST,
  REMOVE_ENTRY_CODE_FROM_LIST,
  ADD_TRACK_CODE_TO_LIST,
  REMOVE_TRACK_CODE_FROM_LIST,
  TRACK_CODE_UPDATED,
  // CREATE_ENTRY_CODE,
  ADD_TRACK_CODE_TO_ARCHIVE_LIST,
  REMOVE_TRACK_CODE_FROM_ARCHIVE_LIST,
  UPDATE_TRACK_CODE_TO_ARCHIVE_LIST,
  FETCH_PACKAGES_LIST,
  FETCH_ENTRY_CODES_LIST,
  CREATE_OFFLINE_ENTRY_CODE,
  DELETE_ARCHIVED_TRACK_CODE,
  DELETE_TRACK_CODE,
  SET_PACKAGES_ARCHIVE_LIST,
} from 'Redux/actions/benchCodes'

import { REMOVE_OFFLINE_ENTRY_CODES } from 'Redux/actions/common'

const initialState = {
  entryCodes: {},
  trackCodes: {},
  trackCodesArchive: {},
  offlineEntryCodes: [],
}

const handlers = {
  [FETCH_PACKAGES_LIST.SUCCESS]: (state, { payload }) => {
    const { benchId, list } = payload.data

    return {
      ...state,
      trackCodes: {
        ...state.trackCodes,
        [benchId]: list,
      },
    }
  },
  [SET_PACKAGES_ARCHIVE_LIST]: (state, { payload }) => {
    const { benchId, list } = payload.data

    const filteredList = (list || []).filter(
      item => item.status !== packageStatus.deleted,
    )

    return {
      ...state,
      trackCodesArchive: {
        ...state.trackCodesArchive,
        [benchId]: filteredList,
      },
    }
  },
  [FETCH_ENTRY_CODES_LIST.SUCCESS]: (state, { payload }) => {
    const { benchId, list } = payload.data

    return {
      ...state,
      entryCodes: {
        ...state.entryCodes,
        [benchId]: list,
      },
    }
  },
  [ADD_ENTRY_CODE_TO_LIST.SUCCESS]: (state, { payload }) => {
    const { benchId, code: newCode } = payload.data

    return {
      ...state,
      entryCodes: {
        ...state.entryCodes,
        [benchId]: updateOrPush(
          state.entryCodes[benchId] || [],
          newCode,
          item => item.code === newCode.code,
        ),
      },
    }
  },
  [CREATE_OFFLINE_ENTRY_CODE]: (state, { payload }) => {
    const { benchId, newEntryCode } = payload
    return {
      ...state,
      entryCodes: {
        ...state.entryCodes,
        [benchId]: updateOrPush(
          state.entryCodes[benchId] || [],
          newEntryCode,
          item => item.code === newEntryCode.code,
        ),
      },
      offlineEntryCodes: [...state.offlineEntryCodes, newEntryCode],
    }
  },
  [REMOVE_OFFLINE_ENTRY_CODES]: state => {
    return {
      ...state,
      offlineEntryCodes: [],
    }
  },
  [REMOVE_ENTRY_CODE_FROM_LIST.SUCCESS]: (state, { payload }) => {
    const { benchId, removeCode } = payload.data

    return {
      ...state,
      entryCodes: {
        ...state.entryCodes,
        [benchId]: (state.entryCodes[benchId] || []).filter(
          item => item.code !== removeCode.code,
        ),
      },
    }
  },
  [ADD_TRACK_CODE_TO_LIST.SUCCESS]: (state, { payload }) => {
    const { benchId, code: newCode } = payload.data

    return {
      ...state,
      trackCodes: {
        ...state.trackCodes,
        [benchId]: updateOrPush(
          state.trackCodes[benchId] || [],
          newCode,
          item => item.trackingCode === newCode.trackingCode,
        ),
      },
    }
  },
  [REMOVE_TRACK_CODE_FROM_LIST.SUCCESS]: (state, { payload }) => {
    const { benchId, removeCode } = payload.data

    return {
      ...state,
      trackCodes: {
        ...state.trackCodes,
        [benchId]: (state.trackCodes[benchId] || []).filter(
          item => item.trackingCode !== removeCode.trackingCode,
        ),
      },
    }
  },
  [DELETE_TRACK_CODE.SUCCESS]: (state, { meta }) => {
    const { benchId, packageId } = meta

    return {
      ...state,
      trackCodes: {
        ...state.trackCodes,
        [benchId]: (state.trackCodes[benchId] || []).filter(
          item => item.packageId !== packageId,
        ),
      },
    }
  },
  [UNSUBSCRIBE_BENCH_UPDATE]: (state, { payload }) => {
    return {
      ...state,
      entryCodes: {
        ...state.entryCodes,
        [payload.data]: undefined,
      },
    }
  },
  [TRACK_CODE_UPDATED]: (state, { payload }) => {
    const { benchId, nextVal } = payload

    return {
      ...state,
      trackCodes: {
        ...state.trackCodes,
        [benchId]: updateOrPush(
          state.trackCodes[benchId] || [],
          nextVal,
          item => item.packageId === nextVal.packageId,
        ),
      },
    }
  },
  [ADD_TRACK_CODE_TO_ARCHIVE_LIST.SUCCESS]: (state, { payload }) => {
    const { benchId, code: newCode } = payload.data

    return {
      ...state,
      trackCodesArchive: {
        ...state.trackCodesArchive,
        [benchId]: updateOrPush(
          state.trackCodesArchive[benchId] || [],
          newCode,
          item => item.packageId === newCode.packageId,
        ),
      },
    }
  },
  [UPDATE_TRACK_CODE_TO_ARCHIVE_LIST.SUCCESS]: (state, { payload }) => {
    const { benchId, nextVal } = payload

    return {
      ...state,
      trackCodesArchive: {
        ...state.trackCodesArchive,
        [benchId]: updateOrPush(
          state.trackCodesArchive[benchId] || [],
          nextVal,
          item => item.trackingCode === nextVal.trackingCode,
        ),
      },
    }
  },
  [REMOVE_TRACK_CODE_FROM_ARCHIVE_LIST.SUCCESS]: (state, { payload }) => {
    const { benchId, removeCode } = payload.data

    return {
      ...state,
      trackCodesArchive: {
        ...state.trackCodesArchive,
        [benchId]: (state.trackCodesArchive[benchId] || []).filter(
          item => item.trackingCode !== removeCode.trackingCode,
        ),
      },
    }
  },
  [DELETE_ARCHIVED_TRACK_CODE.SUCCESS]: (state, { payload }) => {
    const { benchId, packageId } = payload.data

    return {
      ...state,
      trackCodesArchive: {
        ...state.trackCodesArchive,
        [benchId]: (state.trackCodesArchive[benchId] || []).filter(
          item => item.packageId !== packageId,
        ),
      },
    }
  },
}

export default createReducer(initialState, handlers)
