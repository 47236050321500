import React, { useState, useMemo, useRef, useEffect } from 'react'

import PT from 'prop-types'

import { toast } from 'react-toastify'

import Animate from 'animate.css-react'
import 'animate.css/animate.css'

import Typography from '@material-ui/core/Typography'

import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Container from '@material-ui/core/Container'

import Icon from '@mdi/react'
import {
  mdiLockOpen,
  mdiWifiStrength2,
  mdiWifiStrength4,
  mdiWifiStrength3,
  mdiWifiStrengthOff,
  mdiLock,
} from '@mdi/js'

import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'

import { get, capitalize } from 'lodash'
import moment from 'moment'

import BenchLockSetting from 'Constants/BenchLockSetting'

import i18n from 'I18N'

import {
  AddEntryCodeModal,
  AddTrackCodeModal,
  ConfirmModal,
} from 'Components/Blocks'

import { UpcomingDeliveries, EventsList, EntryCodesList } from './innerBlocks'

import { useStyles } from './styles'

function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

let changeLockStateEventRef = null

const Dashboard = ({
  benchId,
  entryCodes,
  primaryBench,
  user,
  benchEvents,
  deliveries,
  onEntryCodeCreate,
  entryCodesObject,
  trackCodes,
  trackCodeArchive,
  onTrackCodeCreate,
  onUpdateLockSettings,
  onUpdateLockBench,
}) => {
  const [isAddEntryCodeModalOpen, onSetAddEntryCodeModalOpen] = useState(false)
  const [isAddTrackCodeModalOpen, onSetAddTrackCodeModalOpen] = useState(false)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [isWaitForBenchResponse, setWaitForBenchResponse] = useState(false)
  const [
    waitForBenchResponseTimeout,
    setWaitForBenchResponseTimeout,
  ] = useState(false)
  const prevPrimaryBench = usePrevious(primaryBench)

  const classes = useStyles()

  const wifiLevel = get(primaryBench, 'state.wifiLevel', -81)
  const isOnline = get(primaryBench, `state.online`, false)
  const isLocked = get(primaryBench, `state.locked`, true)

  const greetingString = useMemo(() => {
    const num = moment().hour()

    let str = ''

    switch (true) {
      case num >= 5 && num < 12:
        str = i18n.t('homeScreen.greeting.morning')
        break
      case num >= 12 && num < 17:
        str = i18n.t('homeScreen.greeting.afternoon')
        break
      case num >= 17 && num < 21:
        str = i18n.t('homeScreen.greeting.evening')
        break
      case num >= 21 || num < 5:
        str = i18n.t('homeScreen.greeting.night')
        break
      default:
        str = ''
        break
    }

    if (user.firstName) {
      str += ', '
      str += capitalize(user.firstName)
    }

    str += '!'

    return str
  }, [user])

  const [wifiIconPath, wifiAvatarClass] = useMemo(() => {
    if (!isOnline) {
      return [mdiWifiStrengthOff, classes.redAvatar]
    }

    switch (true) {
      case wifiLevel >= -64 && wifiLevel <= 0:
        return [mdiWifiStrength4, classes.greenAvatar]
      case wifiLevel >= -69 && wifiLevel <= -65:
        return [mdiWifiStrength3, classes.greenAvatar]
      case wifiLevel >= -80 && wifiLevel <= -70:
        return [mdiWifiStrength2, classes.yellowAvatar]
      default:
        return [mdiWifiStrengthOff, classes.redAvatar]
    }
  }, [
    wifiLevel,
    isOnline,
    classes.greenAvatar,
    classes.yellowAvatar,
    classes.redAvatar,
  ])

  const handleBenchResponded = () => {
    clearTimeout(waitForBenchResponseTimeout)

    setWaitForBenchResponse(false)

    changeLockStateEventRef = null
  }

  useEffect(() => {
    if (isWaitForBenchResponse && changeLockStateEventRef !== null) {
      const prevIsLocked = get(prevPrimaryBench, `state.locked`, true)

      if (prevIsLocked !== isLocked) {
        handleBenchResponded()
      }
    }
  })

  const handleBenchNotResponding = () => {
    changeLockStateEventRef.set(null)

    toast.error(i18n.t('homeScreen.benchNotRespondingError'))

    setWaitForBenchResponse(false)
    changeLockStateEventRef = null
  }

  const handleToogleConfirmModal = () => {
    setIsConfirmModalOpen(!isConfirmModalOpen)
  }

  const handleCloseDisable = (isSuccess, nextChangeLockStateEventRef) => {
    if (!isSuccess) {
      console.error('er', nextChangeLockStateEventRef)
      return
    }

    changeLockStateEventRef = nextChangeLockStateEventRef

    setWaitForBenchResponseTimeout(setTimeout(handleBenchNotResponding, 5000))
  }

  const handleConfirmModalSubmit = () => {
    setWaitForBenchResponse(true)
    setIsConfirmModalOpen(false)

    onUpdateLockBench('lock', benchId, user.uid, handleCloseDisable)
    onUpdateLockSettings(benchId, { lockSetting: 'locked' })
  }

  const handleChangeLockStatePress = () => {
    if (!isOnline) {
      toast.error(i18n.t('homeScreen.benchOfflineWarning'))

      return
    }

    const status = get(primaryBench, 'preferences.lockSetting', 'locked')

    if (!isLocked && status !== BenchLockSetting.alwaysLocked) {
      handleToogleConfirmModal()

      return
    }

    setWaitForBenchResponse(true)

    if (isLocked) {
      onUpdateLockBench('unlock', benchId, user.uid, handleCloseDisable)
    } else {
      onUpdateLockBench('lock', benchId, user.uid, handleCloseDisable)
    }
  }

  const handleAddEntryCode = ({ code, name }) => {
    onEntryCodeCreate(
      benchId,
      code,
      name,
      // addExpirationDate ? moment(expDate).format() : null,
      null,
      isSuccess => {
        if (isSuccess) {
          onSetAddEntryCodeModalOpen(false)
        }
      },
    )
  }

  const handleCloseAddEntryCodeModal = () => {
    onSetAddEntryCodeModalOpen(false)
  }

  const handleAddEntryCodeBtnClick = () => {
    onSetAddEntryCodeModalOpen(true)
  }

  const handleAddTrackCodeBtnClick = () => {
    onSetAddTrackCodeModalOpen(true)
  }

  const handleCloseAddTrackCodeModal = () => {
    onSetAddTrackCodeModalOpen(false)
  }

  const handleAddTrackCode = ({ trackCode, name }) => {
    onTrackCodeCreate(benchId, trackCode, name, isSuccess => {
      if (isSuccess) {
        onSetAddTrackCodeModalOpen(false)
      }
    })
  }

  return (
    <>
      <Container>
        <AddEntryCodeModal
          isOpen={isAddEntryCodeModalOpen}
          onClose={handleCloseAddEntryCodeModal}
          onConfirm={handleAddEntryCode}
        />
        <AddTrackCodeModal
          isOpen={isAddTrackCodeModalOpen}
          onClose={handleCloseAddTrackCodeModal}
          onConfirm={handleAddTrackCode}
        />
        <ConfirmModal
          isRequest={isWaitForBenchResponse}
          isOpen={isConfirmModalOpen}
          title={i18n.t('homeScreen.confirmModal.title')}
          description={i18n.t('homeScreen.confirmModal.description')}
          confirmBtnText={i18n.t('homeScreen.confirmModal.submitBtnText')}
          cancelBtnText={i18n.t('homeScreen.confirmModal.cancelBtnText')}
          onClose={handleToogleConfirmModal}
          onConfirm={handleConfirmModalSubmit}
        />

        <Box display="flex" alignItems="center" className={classes.topBar}>
          <Typography variant="h5">{i18n.t('homeScreen.pageTitle')}</Typography>
        </Box>

        <Typography variant="h2">{greetingString}</Typography>
        <Typography variant="h2">
          {i18n.t(
            `homeScreen.labels.benchOnlineStatus.${
              isOnline ? 'online' : 'offline'
            }`,
          )}
        </Typography>

        <Grid container spacing={3} className={classes.searchBar}>
          <Grid item sm={6} xs={12}>
            <Card>
              <CardContent className={classes.cardContent}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <div>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {i18n.t('homeScreen.labels.benchLockedState.title')}
                    </Typography>
                    <Typography variant="h5" component="p">
                      {i18n.t(
                        `homeScreen.labels.benchLockedState.${
                          isLocked ? 'locked' : 'unlocked'
                        }`,
                      )}
                    </Typography>
                  </div>
                  {isWaitForBenchResponse ? (
                    <Animate animate appear="flash" durationAppear={5000}>
                      <Avatar className={classes.grayAvatar}>
                        <Icon
                          path={isLocked ? mdiLock : mdiLockOpen}
                          color="white"
                          size="24px"
                        />
                      </Avatar>
                    </Animate>
                  ) : (
                    <Avatar
                      className={classes.greenAvatar}
                      onClick={handleChangeLockStatePress}
                    >
                      <Icon
                        path={isLocked ? mdiLock : mdiLockOpen}
                        color="white"
                        size="24px"
                      />
                    </Avatar>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item sm={6} xs={12}>
            <Card>
              <CardContent className={classes.cardContent}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <div>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {i18n.t('homeScreen.labels.wifiState.title')}
                    </Typography>
                    <Typography variant="h5" component="p">
                      {i18n.t(
                        `homeScreen.labels.wifiState.${
                          isOnline ? 'connected' : 'notConnected'
                        }`,
                      )}
                    </Typography>
                  </div>

                  <Avatar className={wifiAvatarClass}>
                    <Icon path={wifiIconPath} color="white" size="24px" />
                  </Avatar>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <UpcomingDeliveries
            deliveries={deliveries}
            onAddTrackCodeBtnClick={handleAddTrackCodeBtnClick}
          />

          <EventsList
            events={benchEvents}
            entryCodes={entryCodesObject}
            trackCodes={trackCodes}
            trackCodeArchive={trackCodeArchive}
          />

          <EntryCodesList
            entryCodes={entryCodes}
            onAddEntryCodeClick={handleAddEntryCodeBtnClick}
          />
        </Grid>

        <Grid container spacing={3} />
      </Container>
    </>
  )
}

Dashboard.propTypes = {
  benchId: PT.string,
  entryCodes: PT.array,
  primaryBench: PT.object,
  user: PT.object,
  benchEvents: PT.array,
  deliveries: PT.array,
  onEntryCodeCreate: PT.func,
  onTrackCodeCreate: PT.func,
}

export default Dashboard
