import * as Yup from 'yup'
import i18n from 'I18N'

const errorMessages = i18n.t(
  'trackCodesScreen.trackTab.updateModal.errorMessages',
)

export const validationSchema = Yup.object().shape({
  name: Yup.string(),
  trackCode: Yup.string().required(errorMessages.number.required),
})
