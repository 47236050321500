import React, { useMemo } from 'react'

import { Formik } from 'formik'

import PT from 'prop-types'

import {
  Box,
  Button,
  TextField,
  Grid,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

import { omit, pick, get, words } from 'lodash'

import i18n from 'I18N'

import { validationSchema } from './config'
import { useStyles } from './styles'

const usaStates = i18n.t('common.usaStates')

const StepOne = ({ onSubmit, isRequest, user, helcimUser }) => {
  const classes = useStyles()

  const handleConfirm = values => {
    const customerInfo = omit(values, ['copyBillingInfo'])
    const profileData = pick(values, ['firstName', 'lastName'])

    const contactName = [values.firstName, values.lastName].join(' ')

    customerInfo.contactName = contactName
    customerInfo.billing_contactName = contactName
    customerInfo.billing_businessName = values.businessName
    customerInfo.billing_email = values.email

    if (values.copyBillingInfo) {
      customerInfo.shipping_contactName = contactName
      customerInfo.shipping_businessName = values.businessName
      customerInfo.shipping_email = values.email
      customerInfo.shipping_street1 = values.billing_street1
      customerInfo.shipping_street2 = values.billing_street2
      customerInfo.shipping_city = values.billing_city
      customerInfo.shipping_province = values.billing_province
      customerInfo.shipping_postalCode = values.billing_postalCode
    } else {
      customerInfo.shipping_contactName = [
        values.shipping_firstName,
        values.shipping_lastName,
      ].join(' ')
    }

    customerInfo.billing_country = 'United States of America'
    customerInfo.shipping_country = 'United States of America'

    onSubmit(customerInfo, profileData)
    window.scrollTo(0, 0)
  }

  const initialValues = useMemo(() => {
    const contactNameParts = words(get(helcimUser, 'contactName._text', ''))
    const shippingNameParts = words(
      get(helcimUser, 'shippingAddress.contactName._text', ''),
    )

    return {
      firstName: contactNameParts[0],
      lastName: contactNameParts[1],
      businessName: get(helcimUser, 'businessName._text', ''),
      email: get(helcimUser, 'email._text', user.email),
      billing_street1: get(helcimUser, 'billingAddress.street1._text', ''),
      billing_street2: get(helcimUser, 'billingAddress.street2._text', ''),
      billing_city: get(helcimUser, 'billingAddress.city._text', ''),
      billing_province: get(helcimUser, 'billingAddress.province._text', ''),
      billing_postalCode: get(
        helcimUser,
        'billingAddress.postalCode._text',
        '',
      ),
      shipping_firstName: shippingNameParts[0],
      shipping_lastName: shippingNameParts[1],
      shipping_businessName: get(
        helcimUser,
        'shippingAddress.businessName._text',
        '',
      ),
      shipping_email: get(helcimUser, 'shippingAddress.email._text', ''),
      shipping_street1: get(helcimUser, 'shippingAddress.street1._text', ''),
      shipping_street2: get(helcimUser, 'shippingAddress.street2._text', ''),
      shipping_city: get(helcimUser, 'shippingAddress.city._text', ''),
      shipping_province: get(helcimUser, 'shippingAddress.province._text', ''),
      shipping_postalCode: get(
        helcimUser,
        'shippingAddress.postalCode._text',
        '',
      ),
      copyBillingInfo: false,
    }
  }, [helcimUser, user])

  return (
    <Grid
      container
      display="flex"
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
    >
      <Grid item xs={9} md={5} lg={4} xl={3}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={handleConfirm}
        >
          {({
            values = {},
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => {
            const handleInputKeyDown = e => {
              if (e.key === 'Enter') {
                handleSubmit()
              }
            }

            return (
              <>
                {/* PERSONAL INFO */}
                <>
                  <Box className={classes.sectionHeader}>
                    {i18n.t('preOrderScreen.sections.personalInfo.title')}
                  </Box>
                  <TextField
                    error={errors.firstName}
                    fullWidth
                    helperText={errors.firstName}
                    margin="dense"
                    id="firstName"
                    label={i18n.t(
                      'preOrderScreen.sections.personalInfo.firstName.label',
                    )}
                    required
                    type="text"
                    variant="outlined"
                    value={values.firstName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onKeyDown={handleInputKeyDown}
                  />
                  <TextField
                    error={errors.lastName}
                    fullWidth
                    helperText={errors.lastName}
                    margin="dense"
                    id="lastName"
                    label={i18n.t(
                      'preOrderScreen.sections.personalInfo.lastName.label',
                    )}
                    required
                    type="text"
                    variant="outlined"
                    value={values.lastName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onKeyDown={handleInputKeyDown}
                  />
                  <TextField
                    error={errors.businessName}
                    fullWidth
                    helperText={errors.businessName}
                    margin="dense"
                    id="businessName"
                    label={i18n.t(
                      'preOrderScreen.sections.personalInfo.businessName.label',
                    )}
                    type="text"
                    variant="outlined"
                    value={values.businessName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onKeyDown={handleInputKeyDown}
                  />
                  <TextField
                    error={errors.email}
                    fullWidth
                    helperText={errors.email}
                    margin="dense"
                    id="email"
                    label={i18n.t(
                      'preOrderScreen.sections.personalInfo.email.label',
                    )}
                    required
                    type="text"
                    variant="outlined"
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onKeyDown={handleInputKeyDown}
                  />
                </>
                {/* BILLING INFO */}
                <>
                  <Box className={classes.sectionHeader}>
                    {i18n.t('preOrderScreen.sections.billingInfo.title')}
                  </Box>
                  <TextField
                    error={errors.billing_street1}
                    fullWidth
                    helperText={errors.billing_street1}
                    margin="dense"
                    id="billing_street1"
                    label={i18n.t(
                      'preOrderScreen.sections.billingInfo.address1.label',
                    )}
                    required
                    type="text"
                    variant="outlined"
                    value={values.billing_street1}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onKeyDown={handleInputKeyDown}
                  />
                  <TextField
                    error={errors.billing_street2}
                    fullWidth
                    helperText={errors.billing_street2}
                    margin="dense"
                    id="billing_street2"
                    label={i18n.t(
                      'preOrderScreen.sections.billingInfo.address2.label',
                    )}
                    type="text"
                    variant="outlined"
                    value={values.billing_street2}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onKeyDown={handleInputKeyDown}
                  />
                  <TextField
                    error={errors.billing_city}
                    fullWidth
                    helperText={errors.billing_city}
                    margin="dense"
                    id="billing_city"
                    label={i18n.t(
                      'preOrderScreen.sections.billingInfo.city.label',
                    )}
                    required
                    type="text"
                    variant="outlined"
                    value={values.billing_city}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onKeyDown={handleInputKeyDown}
                  />
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignContent="flex-start"
                  >
                    <Grid
                      item
                      xs
                      style={{
                        marginRight: '8px',
                      }}
                    >
                      <Autocomplete
                        blurOnSelect
                        id="billing_province"
                        options={usaStates}
                        getOptionLabel={option => option.name}
                        getOptionSelected={(option, { value }) => {
                          return option.name === value
                        }}
                        renderInput={params => {
                          const nextParams = {
                            ...params,
                            inputProps: {
                              ...(params.inputProps || {}),
                              value:
                                (params.inputProps || {}).value ||
                                values.billing_province,
                            },
                          }

                          return (
                            <TextField
                              error={errors.billing_province}
                              fullWidth
                              helperText={errors.billing_province}
                              margin="dense"
                              label={i18n.t(
                                'preOrderScreen.sections.billingInfo.state.label',
                              )}
                              required
                              variant="outlined"
                              {...nextParams}
                            />
                          )
                        }}
                        onChange={(event, val) =>
                          setFieldValue('billing_province', val ? val.name : '')
                        }
                      />
                    </Grid>
                    <Grid item xs>
                      <TextField
                        error={errors.billing_postalCode}
                        fullWidth
                        helperText={errors.billing_postalCode}
                        margin="dense"
                        id="billing_postalCode"
                        label={i18n.t(
                          'preOrderScreen.sections.billingInfo.zipCode.label',
                        )}
                        required
                        type="text"
                        variant="outlined"
                        value={values.billing_postalCode}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onKeyDown={handleInputKeyDown}
                      />
                    </Grid>
                  </Grid>
                </>
                {/* SHIPPING INFO */}
                <>
                  <Box className={classes.sectionHeader}>
                    {i18n.t('preOrderScreen.sections.shippingInfo.title')}
                  </Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.copyBillingInfo}
                        id="copyBillingInfo"
                        onChange={() =>
                          setFieldValue(
                            'copyBillingInfo',
                            !values.copyBillingInfo,
                          )
                        }
                      />
                    }
                    label={i18n.t(
                      'preOrderScreen.sections.shippingInfo.copyBillingCheckbox.label',
                    )}
                  />
                  {!values.copyBillingInfo && (
                    <>
                      <TextField
                        error={errors.shipping_firstName}
                        fullWidth
                        helperText={errors.shipping_firstName}
                        margin="dense"
                        id="shipping_firstName"
                        label={i18n.t(
                          'preOrderScreen.sections.shippingInfo.firstName.label',
                        )}
                        required
                        type="text"
                        variant="outlined"
                        value={values.shipping_firstName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onKeyDown={handleInputKeyDown}
                      />
                      <TextField
                        error={errors.shipping_lastName}
                        fullWidth
                        helperText={errors.shipping_lastName}
                        margin="dense"
                        id="shipping_lastName"
                        label={i18n.t(
                          'preOrderScreen.sections.shippingInfo.lastName.label',
                        )}
                        required
                        type="text"
                        variant="outlined"
                        value={values.shipping_lastName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onKeyDown={handleInputKeyDown}
                      />
                      <TextField
                        error={errors.shipping_businessName}
                        fullWidth
                        helperText={errors.shipping_businessName}
                        margin="dense"
                        id="shipping_businessName"
                        label={i18n.t(
                          'preOrderScreen.sections.personalInfo.businessName.label',
                        )}
                        required
                        type="text"
                        variant="outlined"
                        value={values.shipping_businessName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onKeyDown={handleInputKeyDown}
                      />
                      <TextField
                        error={errors.shipping_email}
                        fullWidth
                        helperText={errors.shipping_email}
                        margin="dense"
                        id="shipping_email"
                        label={i18n.t(
                          'preOrderScreen.sections.shippingInfo.email.label',
                        )}
                        type="text"
                        variant="outlined"
                        value={values.shipping_email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onKeyDown={handleInputKeyDown}
                      />
                      <TextField
                        error={errors.shipping_street1}
                        fullWidth
                        helperText={errors.shipping_street1}
                        margin="dense"
                        id="shipping_street1"
                        label={i18n.t(
                          'preOrderScreen.sections.shippingInfo.address1.label',
                        )}
                        required
                        type="text"
                        variant="outlined"
                        value={values.shipping_street1}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onKeyDown={handleInputKeyDown}
                      />
                      <TextField
                        error={errors.shipping_street2}
                        fullWidth
                        helperText={errors.shipping_street2}
                        margin="dense"
                        id="shipping_street2"
                        label={i18n.t(
                          'preOrderScreen.sections.shippingInfo.address2.label',
                        )}
                        type="text"
                        variant="outlined"
                        value={values.shipping_street2}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onKeyDown={handleInputKeyDown}
                      />
                      <TextField
                        error={errors.shipping_city}
                        fullWidth
                        helperText={errors.shipping_city}
                        margin="dense"
                        id="shipping_city"
                        label={i18n.t(
                          'preOrderScreen.sections.shippingInfo.city.label',
                        )}
                        required
                        type="text"
                        variant="outlined"
                        value={values.shipping_city}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onKeyDown={handleInputKeyDown}
                      />
                      <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignContent="flex-start"
                      >
                        <Grid
                          item
                          xs
                          style={{
                            marginRight: '8px',
                          }}
                        >
                          <Autocomplete
                            blurOnSelect
                            id="shipping_province"
                            options={usaStates}
                            getOptionLabel={option => option.name}
                            getOptionSelected={(option, { value }) => {
                              return option.name === value
                            }}
                            renderInput={params => {
                              const nextParams = {
                                ...params,
                                inputProps: {
                                  ...(params.inputProps || {}),
                                  value:
                                    (params.inputProps || {}).value ||
                                    values.shipping_province,
                                },
                              }

                              return (
                                <TextField
                                  error={errors.shipping_province}
                                  fullWidth
                                  helperText={errors.shipping_province}
                                  margin="dense"
                                  id="shipping_province"
                                  label={i18n.t(
                                    'preOrderScreen.sections.billingInfo.state.label',
                                  )}
                                  required
                                  variant="outlined"
                                  onBlur={handleBlur}
                                  {...nextParams}
                                  style={{}}
                                />
                              )
                            }}
                            onChange={(event, val) =>
                              setFieldValue(
                                'shipping_province',
                                val ? val.name : '',
                              )
                            }
                          />
                        </Grid>
                        <Grid item xs>
                          <TextField
                            error={errors.shipping_postalCode}
                            fullWidth
                            helperText={errors.shipping_postalCode}
                            margin="dense"
                            id="shipping_postalCode"
                            label={i18n.t(
                              'preOrderScreen.sections.shippingInfo.zipCode.label',
                            )}
                            required
                            type="text"
                            variant="outlined"
                            value={values.shipping_postalCode}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            onKeyDown={handleInputKeyDown}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  marginTop="16px"
                >
                  <Button
                    color="secondary"
                    disabled={isRequest}
                    variant="contained"
                    onClick={handleSubmit}
                  >
                    {i18n.t('preOrderScreen.submitBtnLabel')}
                  </Button>
                </Box>
              </>
            )
          }}
        </Formik>
      </Grid>
    </Grid>
  )
}

StepOne.propTypes = {
  isRequest: PT.bool,
  user: PT.object,
  onSubmit: PT.func,
}

export { StepOne }
