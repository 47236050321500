import React from 'react'
import PT from 'prop-types'

import { CopyToClipboard } from 'react-copy-to-clipboard'

import Button from '@material-ui/core/Button'
import Icon from '@mdi/react'
import { mdiEmail, mdiContentCopy } from '@mdi/js'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'

import { get } from 'lodash'

import i18n from 'I18N'

const ShareEntryCodeModal = ({ item, user, isOpen, onClose }) => {
  const usersName = get(user, 'firstName', null)
  const code = get(item, 'code')

  const shareSubject = `${usersName ||
    user.email} wants to share a Bench code with you.`
  const shareBody = `Use the code ${code} to access to Bench.`

  const shareEmailLink = `mailto:?&body=${shareBody}&subject=${shareSubject}`
  const copyToClipboardText = `${shareSubject}\n${shareBody}`

  const handleCodeCopied = () => {
    onClose()
  }

  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {i18n.t('entryCodesScreen.shareModal.header')}
      </DialogTitle>

      <List disablePadding="true">
        <ListItem button component="a" href={shareEmailLink}>
          <ListItemAvatar>
            <Avatar>
              <Icon path={mdiEmail} size="24px" color="white" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={i18n.t('entryCodesScreen.shareModal.emailLabel')}
          />
        </ListItem>

        <CopyToClipboard text={copyToClipboardText} onCopy={handleCodeCopied}>
          <ListItem button>
            <ListItemAvatar>
              <Avatar>
                <Icon path={mdiContentCopy} size="24px" color="white" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={i18n.t('entryCodesScreen.shareModal.clipboardLabel')}
            />
          </ListItem>
        </CopyToClipboard>
      </List>

      <DialogActions disableSpacing>
        <Button onClick={onClose} color="primary">
          {i18n.t('entryCodesScreen.shareModal.closeBtnLabel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ShareEntryCodeModal.propTypes = {
  item: PT.object,
  user: PT.object,
  isOpen: PT.func,
  onClose: PT.func,
}

export { ShareEntryCodeModal }
