import { put, select } from 'redux-saga/effects'
import i18n from 'I18N'

import { toast } from 'react-toastify'
import { get } from 'lodash'

import UserAccountSetupStep from 'Constants/userAccountSetupState'

import { getUser } from 'Redux/selectors/user'

function* onUpdateSuccess() {
  const user = yield select(getUser)

  const accountSetupStep = get(user || {}, 'setupState', null)

  if (accountSetupStep !== UserAccountSetupStep.preOrder) {
    yield put(
      toast.success(i18n.t('accountSettingsScreen.settingUpdateSuccess')),
    )
  }
}

export default onUpdateSuccess
